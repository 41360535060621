import {
    IonAlert,
    IonBackButton,
    IonButton,
    IonButtons,
    IonCard,
    IonCardContent,
    IonCardHeader,
    IonCardSubtitle,
    IonCol,
    IonContent,
    IonDatetime,
    IonGrid,
    IonHeader,
    IonInput,
    IonItem,
    IonLabel,
    IonList,
    IonLoading,
    IonMenuButton,
    IonModal,
    IonPage,
    IonRow,
    IonSelect,
    IonSelectOption,
    IonTextarea,
    IonTitle,
    IonToolbar,
    isPlatform,
} from '@ionic/react';
import axios from 'axios';
import React, { useEffect, useState} from 'react';
import { useHistory, useParams } from 'react-router-dom';
import { useTranslation } from "react-i18next";
import { useAuth } from '../../../../components/AuthContext';
import ThemeToggle from '../../../../components/ThemeToggle';
import LanguageToggle from '../../../../components/LanguageToggle';
import SwitchBranch from '../../../../components/SwitchBranch';

interface AdmnClass {
    masterID: string,
    name: string,
    isActive: boolean
}

interface ParamTypes {
    mode?: string
}

const NewInquiry: React.FC = () => {
    const {authInfo} = useAuth()!;
    const history = useHistory();
    const {t} = useTranslation();
    const {mode} = useParams < ParamTypes > ();
    const [showLoading, setShowLoading] = useState < boolean > (false);
    const [iserror, setIserror] = useState < boolean > (false);
    const [message, setMessage] = useState < string > ("");
    const [ispass, setIspass] = useState < boolean > (false);
    const [pass, setPass] = useState < string > ("");
    const [instiName, setInstiName] = useState < string > ("");
    const [name, setName] = useState < string > ("");
    const [phone, setPhone] = useState < string > ("");
    const [email, setEmail] = useState < string > ("");
    const [type, setType] = useState < string > ("");
    const [source, setSource] = useState < string > ("");
    const [text, setText] = useState < string > ("");
    const [classes, setClasses] = useState < AdmnClass[] > ([]);
    const [status, setStatus] = useState < string > ("new");
    const [selectedDate, setSelectedDate] = useState < string | string[] | undefined > ();

    const api = axios.create({
      baseURL: process.env.REACT_APP_API_URL,
      timeout: parseInt(process.env.REACT_APP_API_TO!),
      headers: {
          'Authorization': 'Bearer ' + authInfo.token
        }
    });

    const formatDate = (value: string | string[] | undefined) => {
        if (typeof value === "string") {
            const valueDate = new Date(value);
            return valueDate.toLocaleDateString('en-GB') + " at " + valueDate.toLocaleTimeString('en-US');
        }
    };

    const validatePhone = (phone : string) => { 
        // eslint-disable-next-line no-control-regex
        const re = /^[0-9]{10}$/;
        return re.test(phone);
    };

    const validateEmail = (email : string) => { 
        // eslint-disable-next-line no-control-regex
        const re = /^((?:[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*|"(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21\x23-\x5b\x5d-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])*")@(?:(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?|\[(?:(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.){3}(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?|[a-z0-9-]*[a-z0-9]:(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21-\x5a\x53-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])+)\]))$/;
        return re.test(String(email).toLowerCase());
    };

    const handleInquiry = () => {

        if (!name || name === "") {
            setMessage(t("name_mandatory"));
            setIserror(true);
            return;
        }

        if (validatePhone(phone) === false) {
            setMessage(t("invalid_mobile"));
            setIserror(true);
            return;
        }

        if(email !== "")
        {
            if (validateEmail(email) === false) {
                setMessage(t("invalid_email"));
                setIserror(true);
                return;
            }
        }

        if (!source || source === "") {
            setMessage(t("inquiry_source_mandatory"));
            setIserror(true);
            return;
        }


        if (!type || type === "") {
            setMessage(t("inquiry_type_mandatory"));
            setIserror(true);
            return;
        }

        if ((status === "followup" || status === "meeting")) {
            if (!selectedDate || selectedDate === "") {
                setMessage(t("select_app_date"));
                setIserror(true);
                return;
            }
        }
  
        const addInq = () => {
            return new Promise((resolve, reject) => {
                api.post('/admissionInquiries/add', { 'instituteID': authInfo.user.instituteID, instiName, 'userID': 'Admin', name, phone, email, type, source, text, status, selectedDate, 'adminID': authInfo.user._id, 'adminName': authInfo.user.name }).then(res => {
                    return resolve(res.data.inquiry);             
                }).catch(err => reject(err));
            });
        }
  
        setShowLoading(true);
        addInq()
        .then((data: any) => {
          
          setPass(t("enquiry_with_status")+t(status)+t("enquiry_made_by")+name+t("added"));
          setIspass(true);
        })
        .catch((error) => {
            setMessage(error.response?.data.message || t("something_went_wrong"));
            setIserror(true);
        })
        .finally(() => setShowLoading(false));
        
    }

    useEffect(() => {

        const fetchData = async () => {
            setShowLoading(true);
            try {

                if (mode) {
                    setStatus(mode);
                }

                const result = await api.get(`/institutes/${authInfo.user.instituteID}`);
                setInstiName(result.data.name);
                if(result.data.admnClasses) {
                    setClasses(result.data.admnClasses);
                } else {
                    setClasses([]);
                }

            } catch (error: any) {
                setMessage(error.response?.data.message || t("something_went_wrong"));
                setIserror(true);
            } finally {
                setShowLoading(false);
            }
        };
     
        fetchData();

    }, [authInfo, mode]);

    return (
        <IonPage>

            <IonHeader>
                <IonToolbar className="white-shade">
                    <IonButtons slot="start">
                        <IonMenuButton color="secondary"/>
                        <IonBackButton color="secondary" defaultHref="/app/home"/>
                    </IonButtons>
                    <IonTitle size={isPlatform('mobile') ? "small" : undefined} color="secondary">{t("new_admn_inq")}</IonTitle>
                    {!isPlatform('mobile') && (
                    <IonButtons slot="end">
                        <ThemeToggle />
                        <LanguageToggle />
                    </IonButtons>
                    )}
                </IonToolbar>
            </IonHeader>

            <IonContent fullscreen={true} className="grey-shade">

                <IonHeader collapse="condense">
                    <IonToolbar>
                        <IonTitle size="large" color="secondary">{t("new_admn_inq")}</IonTitle>
                    </IonToolbar>
                </IonHeader>

                <IonLoading isOpen={showLoading}
                    cssClass="first-loading"
                    spinner="circular"
                    message={t("please_wait")}
                />

                <IonAlert isOpen={iserror}
                    cssClass="first-alert"
                    onDidDismiss={
                        () => setIserror(false)
                    }
                    header={t("error")}
                    message={message}
                    buttons={
                        [`${t("close")}`]
                    }
                />
                
                 <IonAlert isOpen={ispass}
                    cssClass="first-alert"
                    onDidDismiss={
                        () => history.goBack()
                    }
                    header={t("success")}
                    message={pass}
                    buttons={
                        [`${t("close")}`]
                    }
                />

                {(authInfo.user.role === "Super") && (
                    <SwitchBranch />
                )}

                <IonGrid className="mb-60">
                    <IonRow>
                        <IonCol offsetXl="3" offsetMd="2" offset="0" sizeXl="6" sizeMd="8" size="12">
                           
                            <IonRow>
                                <IonCol>
                                    <IonCard className="ion-no-margin">
                                        <IonCardHeader>
                                                <IonCardSubtitle className='info-subtitle'>{t("inquiry_info")}</IonCardSubtitle>
                                            </IonCardHeader>
                                        <IonCardContent className="ion-no-padding">
                                            <IonRow>
                                                <IonCol>
                                                    <IonList className="ion-no-padding">
                                                        <IonItem detail={false}
                                                            lines='inset'
                                                            button={false}
                                                            >
                                                             <IonLabel position="stacked" color="secondary">
                                                            {t("student_name")}*
                                                            </IonLabel>
                                                                <IonInput type="text"
                                                                    placeholder={t("student_name")}
                                                                    value={name}
                                                                    onIonInput={
                                                                        (e) => setName(e.detail.value!)
                                                                }></IonInput>
                                                        </IonItem>
                                                        <IonItem detail={false}
                                                            lines='inset'
                                                            button={false}
                                                            className='ion-margin-top'
                                                            >
                                                             <IonLabel position="stacked" color="secondary">
                                                            {t("phone")}*
                                                            </IonLabel>
                                                                <IonInput type="text"
                                                                    placeholder={t("phone")}
                                                                    value={phone}
                                                                    onIonInput={
                                                                        (e) => setPhone(e.detail.value!)
                                                                }></IonInput>
                                                        </IonItem>

                                                        <IonItem detail={false}
                                                            lines='inset'
                                                            button={false}
                                                            className='ion-margin-top'
                                                            >
                                                            <IonLabel position="stacked" color="secondary">
                                                                {t("email")}
                                                            </IonLabel>
                                                                <IonInput type="text"
                                                                    placeholder={t("email")}
                                                                    value={email}
                                                                    onIonInput={
                                                                        (e) => setEmail(e.detail.value!)
                                                                }></IonInput>
                                                        </IonItem>

                                                        <IonItem detail={false}
                                                            lines='none'
                                                            button={false}
                                                            >
                                                            <IonLabel position="stacked" color="secondary">
                                                                {t("current_status")}
                                                            </IonLabel>
                                                                <IonSelect value={status}
                                                                        cancelText={t("cancel")}
                                                                        okText={t("ok")}
                                                                        placeholder={t("current_status")}
                                                                        onIonChange={
                                                                            (e) => setStatus(e.detail.value)
                                                                    }>
                                                                    <IonSelectOption value="new">{t("new")}</IonSelectOption>
                                                                    <IonSelectOption value="unreachable">{t("unreachable")}</IonSelectOption>
                                                                    <IonSelectOption value="shared">{t("shared")}</IonSelectOption>
                                                                    <IonSelectOption value="followup">{t("followup")}</IonSelectOption>
                                                                    <IonSelectOption value="meeting">{t("meeting")}</IonSelectOption>
                                                                    <IonSelectOption value="converted">{t("converted")}</IonSelectOption>
                                                                    <IonSelectOption value="failed">{t("failed")}</IonSelectOption>
                                                                    <IonSelectOption value="disqualified">{t("disqualified")}</IonSelectOption>
                                                                    
                                                                    
                                                                </IonSelect>
                                                        </IonItem>
                                                        {(status === "followup" || status === "meeting") && (
                                                        <IonItem detail={false}
                                                            lines='full'
                                                            button={false}
                                                            id="open-date-input">
                                                            <IonLabel position="stacked" className="input-label" color="secondary">{status === "followup" ? t("followup") : t("meeting")} - {t("date")}</IonLabel>
                                                            <IonInput className="input-field" value={typeof selectedDate !== 'string' ? t("pick_date") : formatDate(selectedDate)} readonly={true} />
                                                                <IonModal trigger="open-date-input" showBackdrop={true} 
                                                                    initialBreakpoint={0.7} breakpoints={[0, 0.7, 0.9]} handle={true}>
                                                                    <IonContent className={isPlatform('ios') ? 'grey-shade' : 'white-shade'}>
                                                                        <IonToolbar>
                                                                            <IonTitle>{status === "followup" ? t("followup") : t("meeting")} - {t("pick_date")}</IonTitle>
                                                                            
                                                                        </IonToolbar>
                                                                        <IonDatetime
                                                                            cancelText={t("cancel")}
                                                                            doneText={t("ok")}
                                                                            value={selectedDate}
                                                                            presentation="date-time"
                                                                            onIonChange={ev => setSelectedDate(ev.detail.value!)}
                                                                            showDefaultButtons={true}
                                                                            size="cover"
                                                                        />
                                                                    </IonContent>
                                                                    
                                                                </IonModal>
                                                        </IonItem>
                                                        )}
                                    
                                                        <IonItem detail={false}
                                                            lines='inset'
                                                            button={false}
                                                            className='ion-margin-top'
                                                            >
                                                            <IonLabel position="stacked" color="secondary">
                                                            {t("type_of_inquiry")}</IonLabel>
                                                                <IonSelect value={type}
                                                                        cancelText={t("cancel")}
                                                                        okText={t("ok")}
                                                                        placeholder={t("type_of_inquiry")}
                                                                        onIonChange={
                                                                            (e) => setType(e.detail.value)
                                                                    }>
                                                                    <IonSelectOption value="general">{t("general")}</IonSelectOption>
                                                                    {classes.map((classy, i) => (
                                                                        (classy.isActive) &&
                                                                        (<IonSelectOption key={i} value={classy.name}>{classy.name}</IonSelectOption>)
                                                                    ))}
                                                                </IonSelect>
                                                        </IonItem>
                                                        <IonItem detail={false}
                                                            lines='inset'
                                                            button={false}
                                                            className='ion-margin-top'
                                                            >
                                                              <IonLabel position="stacked" color="secondary">
                                                                {t("inquiry_source")}
                                                            </IonLabel>
                                                                <IonSelect value={source}
                                                                        cancelText={t("cancel")}
                                                                        okText={t("ok")}
                                                                        placeholder={t("inquiry_source")}
                                                                        onIonChange={
                                                                            (e) => setSource(e.detail.value)
                                                                    }>
                                                                    <IonSelectOption value="google">{t("google")}</IonSelectOption>
                                                                    <IonSelectOption value="instagram">{t("instagram")}</IonSelectOption>
                                                                    <IonSelectOption value="facebook">{t("facebook")}</IonSelectOption>
                                                                    <IonSelectOption value="whatsapp">{t("whatsapp")}</IonSelectOption>
                                                                    <IonSelectOption value="youtube">{t("youtube")}</IonSelectOption>
                                                                    <IonSelectOption value="linkedin">{t("linkedin")}</IonSelectOption>
                                                                    <IonSelectOption value="justdial">{t("justdial")}</IonSelectOption>
                                                                    <IonSelectOption value="website">{t("website")}</IonSelectOption>
                                                                    <IonSelectOption value="walkin">{t("walkin")}</IonSelectOption>
                                                                    <IonSelectOption value="newspaper">{t("newspaper")}</IonSelectOption>
                                                                    <IonSelectOption value="magazine">{t("magazine")}</IonSelectOption>
                                                                    <IonSelectOption value="poster">{t("poster")}</IonSelectOption>
                                                                    <IonSelectOption value="reference">{t("reference")}</IonSelectOption>
                                                                    <IonSelectOption value="other">{t("other")}</IonSelectOption>
                                                                    
                                                                </IonSelect>
                                                        </IonItem>
                                                        
                                                        <IonItem detail={false}
                                                                    lines='full'
                                                                    button={false}>

                                                            <IonLabel position="floating" color="secondary">{t("inquiry_text")}</IonLabel>
                                                            <IonTextarea rows={4} value={text} onIonInput={(e) => setText(e.detail.value!)}></IonTextarea>
                                                                
                                                        </IonItem>
                                                    </IonList>
                                                </IonCol>
                                            </IonRow>
                                          
                                            <IonRow className='ion-margin-vertical'>
                                                <IonCol className='ion-text-center'>
                                                    <IonButton onClick={handleInquiry}
                                                        className="first-button"
                                                        fill="clear"
                                                        size="small"
                                                        >{t("submit")}</IonButton>

                                                </IonCol>
                                            </IonRow>
                                        </IonCardContent>
                                    </IonCard>
                                </IonCol>
                            </IonRow>
                        </IonCol>
                    </IonRow>
                    
                </IonGrid>
            </IonContent>
           
        </IonPage>
    );
};

export default NewInquiry;
