import {
    ActionSheetButton,
    createAnimation,
    IonActionSheet,
    IonAlert,
    IonBackButton,
    IonBadge,
    IonButton,
    IonButtons,
    IonCard,
    IonCardContent,
    IonCardSubtitle,
    IonCol,
    IonContent,
    IonGrid,
    IonHeader,
    IonIcon,
    IonInfiniteScroll,
    IonInfiniteScrollContent,
    IonInput,
    IonItem,
    IonLabel,
    IonLoading,
    IonMenuButton,
    IonModal,
    IonPage,
    IonRefresher,
    IonRefresherContent,
    IonRow,
    IonSelect,
    IonSelectOption,
    IonText,
    IonTitle,
    IonToolbar,
    isPlatform,
    RefresherEventDetail,
} from '@ionic/react';
import axios from 'axios';
import { add, addOutline, alertCircleOutline, chatbubblesSharp, chevronBack, chevronForward, close, closeOutline, constructSharp, gridOutline, trashOutline } from 'ionicons/icons';
import React, { useEffect, useState } from 'react';
import { Link, useHistory } from 'react-router-dom';
import { useTranslation } from "react-i18next";
import { Anchorme } from 'react-anchorme';
import { Swiper, SwiperSlide } from 'swiper/react';
import { Navigation } from 'swiper/modules';
import { useAuth } from '../../../../components/AuthContext';
import ThemeToggle from '../../../../components/ThemeToggle';
import LanguageToggle from '../../../../components/LanguageToggle';
import SwitchBranch from '../../../../components/SwitchBranch';

import 'swiper/css';
import "swiper/css/navigation";
import '@ionic/react/css/ionic-swiper.css';

interface Chat {
    _id: string,
    title: string,
    status: string,
    text: string,
    employee: Employee,
    messages: Message[],
    createdAt: Date,
    updatedAt: Date,
    taskInfo: TaskInfo
}

interface TaskInfo {
    startDate: Date,
    endDate: Date,
    priority: string,
    projectID: string,
    projectName: string
}

interface Employee {
    employeeID: string,
    name: string
}

interface Message {
    timestamp: Date,
    userInfo: UserInterface,
    message: Update
}

interface UserInterface {
    userID: string,
    userName: string,
    userRole: string,
    userPhoto: string
}
interface Update {
    update: string,
    filename: string
}
interface Total {
    status: string,
    totalCount: number
}

const allCategories = ["upcoming", "ongoing", "review", "completed", "reopened", "deffered"];

const TasksBoard: React.FC = () => {
    const {authInfo} = useAuth()!;
    const history = useHistory();
    const {t} = useTranslation();
    const [chats, setChats] = useState < Chat[] > ([]);
    const [showLoading, setShowLoading] = useState < boolean > (false);
    const [iserror, setIserror] = useState < boolean > (false);
    const [message, setMessage] = useState < string > ("");
    const [skip, setSkip] = useState < number > (1);
    const [current, setCurrent] = useState < string > ("");
    const [categories, setCategories] = useState < string[] > ([]);
    const [actionButtons, setActionButtons] = useState < ActionSheetButton[] > ([]);
    const [ispass, setIspass] = useState < boolean > (false);
    const [pass, setPass] = useState < string > ("");
    const [status, setStatus] = useState < string > ("");
    const [task, setTask] = useState < Chat > ();
    const [isOpen, setIsOpen] = useState < boolean > (false);
    const [showUpdate, setShowUpdate] = useState < boolean > (false);
    const [showActionSheet, setShowActionSheet] = useState < boolean > (false);
    const [refreshed, setRefreshed] = useState < number > (0);
    const [total, setTotal] = useState < Total[] > ([]);

    const api = axios.create({
        baseURL: process.env.REACT_APP_API_URL,
        timeout: parseInt(process.env.REACT_APP_API_TO!),
        headers: {
            'Authorization': 'Bearer ' + authInfo.token
        }
    });
   
    const enterAnimation = (baseEl: HTMLElement) => {
        const root = baseEl.shadowRoot;
    
        const backdropAnimation = createAnimation()
          .addElement(root?.querySelector('ion-backdrop')!)
          .fromTo('opacity', '0.01', 'var(--backdrop-opacity)');
    
        const wrapperAnimation = createAnimation()
          .addElement(root?.querySelector('.modal-wrapper')!)
          .keyframes([
            { offset: 0, opacity: '0', transform: 'translateX(100%)' },
            { offset: 1, opacity: '0.99', transform: 'translateX(0)' },
          ]);
    
        return createAnimation()
          .addElement(baseEl)
          .easing('ease-out')
          .duration(500)
          .addAnimation([backdropAnimation, wrapperAnimation]);
    };
    
    const leaveAnimation = (baseEl: HTMLElement) => {
        return enterAnimation(baseEl).direction('reverse');
    };

    const formatDate = (value: Date) => {
        const valueDate = new Date(value);
        return valueDate.toLocaleDateString('en-GB', { day: 'numeric', month: 'numeric', year: '2-digit' });
    };

    const doRefresh = (event: CustomEvent<RefresherEventDetail>) => {
        setRefreshed(Math.random());
        event.detail.complete();
    };

    const handleTask = (taskID: string) => {

        const fetchData = async () => {
            setShowLoading(true);
            try {
                const result = await api.get('/staffChats/' + taskID);
                setTask(result.data);
                setCurrent(taskID);
                setIsOpen(true);

            } catch (error: any) {
                setMessage(error.response?.data.message || t("something_went_wrong"));
                setIserror(true);
            } finally {
                setShowLoading(false);
            }
        };

        fetchData();

    }

    const handleSubmit = () => {

        if (!status || status === "") {
            setMessage(t("status_mandatory"));
            setIserror(true);
            return;
        }
      
        setShowLoading(true);
        api.put('/staffChats/'+current, { status, 'adminID': authInfo.user._id })
          .then(res => {

            if (res.data.chat)
                {
                    let theEnquiries = [...chats];
                    const index = theEnquiries.findIndex((a) => a._id === current);
                    if(index > -1)
                    {
                        let totally = [...total];
                        const addIndex = totally.findIndex((a) => a.status === status);
                        const subIndex = totally.findIndex((a) => a.status === theEnquiries[index].status);
    
                        if (addIndex > -1) {
                            totally[addIndex].totalCount = totally[addIndex].totalCount+1;
                        } else {
                            totally.push({status, totalCount: 1});
                        }
    
                        if (subIndex > -1)
                        {
                            totally[subIndex].totalCount = totally[subIndex].totalCount-1;
                        }
                        setTotal(totally);
    
                        theEnquiries.splice(index, 1);
                        theEnquiries.unshift(res.data.chat);
                    }
                    setChats(theEnquiries);
                }
            
          })
          .catch((error) => {
            setMessage(error.response?.data.message || t("something_went_wrong"));
            setIserror(true);
        })
        .finally(() => setShowLoading(false));
        
    }

    const generateActionButtons = (index: number) => {
        let cObjects: ActionSheetButton[] = [];
        for (let i=0; i < allCategories.length; i++)
        {
            if (!(categories.includes(allCategories[i])))
            {
                let cObject: ActionSheetButton;
                cObject = {
                    text: t(allCategories[i]),
                    icon: !isPlatform('ios') ? gridOutline : undefined,
                    handler: () => addCat(allCategories[i], index)
                }
                cObjects.push(cObject);
            }
        }

        cObjects.push({
            text: t('cancel'),
            icon: !isPlatform('ios') ? closeOutline : undefined,
            role: 'cancel'
        });

        setActionButtons(cObjects);
        setShowActionSheet(true);
    }


    const moveLeft = (index: number) => {

        if (index !== 0)
        {
            let comps = [...categories];
            let swapl = comps[index];
            let swapr = comps[index-1];
            comps[index] = swapr;
            comps[index-1] = swapl;
            setCategories(comps);
            updateUser(comps);
        }
    }

    const moveRight = (index: number) => {

        if (index !== (categories.length-1))
        {
            let comps = [...categories];
            let swapl = comps[index];
            let swapr = comps[index+1];
            comps[index] = swapr;
            comps[index+1] = swapl;
            setCategories(comps);
            updateUser(comps);
        }
    }

    const deleteCat = (index: number) => {
        if (categories.length > 1)
        {
            let comps = [...categories];
            comps.splice(index, 1); // 2nd parameter means remove one item only
            setCategories(comps);
            updateUser(comps);
        }
       
    }

    const addCat = (value: string, addIndex: number) => {
        let comps = [...categories];
        comps.splice(addIndex, 0, value); // 2nd parameter means remove no item, third means insert
        setCategories(comps);
        updateUser(comps);
    }

    const updateUser = (comps: string[]) => {

        api.put('/users/'+authInfo.user._id, { tasks: comps })
        .catch((error) => {
            setMessage(error.response?.data.message || t("something_went_wrong"));
            setIserror(true);
        });
      
    }

    const loadItems = async () => {

        let limit = skip + 1;

        try {
            const result = await api.post(`/staffChats/listBoard/${authInfo.user.instituteID}?skip=${limit}`, {categories});

            if(result.data.tasks && result.data.tasks.length)
            {
                setChats([...chats, ...result.data.tasks]); 
                setSkip(limit);
            }
                

        } catch (error: any) {
            setMessage(error.response?.data.message || t("something_went_wrong"));
            setIserror(true);
        }
        
    }

    useEffect(() => {

        const fetchUp = async () => {
            try {
                const query = await api.get('/users/' + authInfo.user._id);
                if ((query.data.settings !== undefined) && (query.data.settings.tasks !== undefined) && (query.data.settings.tasks.length > 0))
                {
                    setCategories(query.data.settings.tasks);
                } 
                else
                {
                    setCategories(allCategories);
                }

            } catch (error: any) {
                setMessage(error.response?.data.message || t("something_went_wrong"));
                setIserror(true);
            } 
                
        };

        fetchUp();

    }, [authInfo]);

    useEffect(() => {

        const fetchUp = async () => {
            setShowLoading(true);
            try {
                const result = await api.post(`/staffChats/listBoard/${authInfo.user.instituteID}`, {categories});
                setChats(result.data.tasks);
                setTotal(result.data.totalCounts);
                setSkip(1);

            } catch (error: any) {
                setMessage(error.response?.data.message || t("something_went_wrong"));
                setIserror(true);
            } finally {
                setShowLoading(false);
            }
            
        };

        if (categories.length > 0)
        {
            fetchUp();
        }
    }, [authInfo, categories, refreshed]);

    return (
        <IonPage>

            <IonHeader>
                <IonToolbar className="white-shade">
                    <IonButtons slot="start">
                        <IonMenuButton color="secondary"/>
                        <IonBackButton color="secondary" defaultHref={`/app/staff`} />
                    </IonButtons>
                    <IonTitle size={isPlatform('mobile') ? "small" : undefined} color="secondary">{t("tasks_board")}</IonTitle>
                    {!isPlatform('mobile') && (
                    <IonButtons slot="end">
                       <ThemeToggle />
                        <LanguageToggle />
                   </IonButtons>
                    )}
                </IonToolbar>
            </IonHeader>

            <IonContent fullscreen={true} className="grey-shade">

                <IonHeader collapse="condense">
                    <IonToolbar>
                        <IonTitle size="large" color="secondary">{t("tasks_board")}</IonTitle>
                    </IonToolbar>
                </IonHeader>

                <IonLoading isOpen={showLoading}
                    cssClass="first-loading"
                    spinner="circular"
                    message={t("please_wait")}
                />

                <IonAlert isOpen={iserror}
                    cssClass="first-alert"
                    onDidDismiss={
                        () => setIserror(false)
                    }
                    header={t("error")}
                    message={message}
                    buttons={
                        [`${t("close")}`]
                    }
                />

                <IonAlert isOpen={ispass}
                    cssClass="first-alert"
                    onDidDismiss={
                        () => {
                            setIspass(false);
                            setRefreshed(Math.random());
                        }
                    }
                    header={t("success")}
                    message={pass}
                    buttons={
                        [`${t("close")}`]
                    }
                />

                <IonRefresher slot="fixed" onIonRefresh={doRefresh}>
                    <IonRefresherContent pullingIcon={isPlatform('ios') ? "lines" : "circular"}></IonRefresherContent>
                </IonRefresher>

                {(authInfo.user.role === "Super") && (
                    <SwitchBranch />
                )}

                <IonActionSheet isOpen={showActionSheet}
                    onDidDismiss={
                        () => setShowActionSheet(false)
                    }
                    cssClass='swap-sheet'
                    header={t("add_board_column")}
                    buttons={actionButtons} 
                />

                <IonGrid className="ion-no-padding mb-60 fullscreen-board">
                    <IonRow className="fullscreen-board">
                        <IonCol>
                            <Swiper className="fullscreen-board"
                                modules={[Navigation]}
                                slidesPerView={1}
                                spaceBetween={0}
                                initialSlide={0}
                                navigation={{ nextEl: '.swiper-button-next', prevEl: '.swiper-button-prev' }}
                                loop={false}
                                breakpoints={{
                                    576: {
                                        slidesPerView: 2
                                    },
                                    992: {
                                        slidesPerView: 3
                                    },
                                    1400: {
                                        slidesPerView: 4
                                    }
                                }}>
                                { categories.map((cat, index) => {
                                    return (
                                        <SwiperSlide key={index} className='categorySlide'>
                                            <IonCardSubtitle className='categoryName'>
                                                <div className='title-icon-left'>
                                                    <IonIcon icon={chevronBack} onClick={() => moveLeft(index)} />
                                                    <IonIcon icon={add} onClick={() => generateActionButtons(index)}  />
                                                </div>
                                                {t(cat)} ({total.find((val) => val.status === cat)?.totalCount || 0})
                                                <div className='title-icon-right'>
                                                    <IonIcon icon={trashOutline} onClick={() => deleteCat(index)} />
                                                    <IonIcon icon={chevronForward} onClick={() => moveRight(index)} />
                                                </div>
                                            </IonCardSubtitle>
                                                <Link to={ `/app/tasks/add` } className='itemAdd'>
                                                    <IonIcon icon={ addOutline } />
                                                </Link>
                                                {chats.map((chat, i) => 
                                                    (chat.status === cat) && (
                                                    <IonRow key={chat._id} className='kanbanItem' >
                                                        <IonCol size="10" className='pointer' onClick={
                                                            () => handleTask(chat._id)
                                                        }>
                                                            <div className='itemList'>
                                                                <IonLabel>
                                                                    <div className='itemTitle'>
                                                                        <h3 className='text-capitalize'>{chat.employee.name}</h3>
                                                                    </div>
                                                                    <p className='itemLabels overflow-hide'>
                                                                        {formatDate(chat.taskInfo.startDate)} {t("to")} {formatDate(chat.taskInfo.endDate)}
                                                                        
                                                                        <span className="ml-05">
                                                                            <IonBadge className='ml-05' color={(chat.taskInfo.priority === "high") ? "danger" : (chat.taskInfo.priority === "medium") ? "warning" : "medium"}>
                                                                                {t(chat.taskInfo.priority)}
                                                                            </IonBadge>
                                                                        </span>
                                                                    </p>
                                                                    <p>{chat.title}</p>
                                                                </IonLabel>
                                                            </div>
                                                        </IonCol>
                                                        <IonCol size="2" className='ion-text-center' >
                                                            <IonIcon 
                                                                icon={constructSharp}
                                                                color="secondary"
                                                                className='table-icon mt-10'
                                                                onClick={
                                                                    () => history.push(`/app/task/${chat._id}/edit`)
                                                                }
                                                            />
                                                            <br/>
                                                            <IonIcon 
                                                                icon={chatbubblesSharp}
                                                                color="tertiary"
                                                                className='table-icon mt-10'
                                                                onClick={
                                                                    () => history.push(`/app/chat/${chat._id}`)
                                                                }
                                                            />
                                                            
                                                        </IonCol>
                                                    </IonRow>
                                                    )
                                                )}
                                                
                                                {(total.find((val) => val.status === cat)?.totalCount || 0) === 0 && (
                                                <IonRow className='kanbanItem' >
                                                     <IonCol>
                                                        <IonItem lines="none" className='item-white-shade'>
                                                            <IonIcon icon={alertCircleOutline}
                                                                slot="start"
                                                                color="danger" />
                                                            <IonLabel className="list-title"><h3>{t("no_tasks")}</h3></IonLabel>
            
                                                        </IonItem>
                                                     </IonCol>
                                                 </IonRow>
                                                )}
                                                
                                        </SwiperSlide>
                                    );
                                })}
                            </Swiper>
                            <div className="swiper-button-next"></div>
                            <div className="swiper-button-prev"></div>
                        </IonCol>
                    </IonRow>
                    {(chats.length > 0) &&
                    <IonRow>
                        <IonCol>
                            <IonInfiniteScroll
                                onIonInfinite={async (ev) => {
                                    await loadItems();
                                    ev.target.complete();
                                }}
                                >
                                <IonInfiniteScrollContent></IonInfiniteScrollContent>
                            </IonInfiniteScroll>
                        </IonCol>
                    </IonRow>
                    }

            <IonModal
                isOpen={isOpen}
                onIonModalDidDismiss={() => {
                    setIsOpen(false);
                    setShowUpdate(false);
                }}
                enterAnimation={enterAnimation}
                leaveAnimation={leaveAnimation}
            >
                <IonHeader>
                    <IonToolbar>
                        <IonTitle>{t("view_task")}</IonTitle>
                        <IonButtons slot="end">
                            <IonButton onClick={() => {
                                    setIsOpen(false);
                                    setShowUpdate(false);
                                }}><IonIcon icon={close} />
                            </IonButton>
                        </IonButtons>
                    </IonToolbar>
                </IonHeader>
                {task && (
                <IonContent className="grey-shade">
                    <IonGrid className="ion-no-padding">
                     <IonRow className='ion-margin-top'>
                        <IonCol>
                            <IonCard>
                                <IonCardContent className="ion-no-padding">
                                    <IonRow>
                                        <IonCol>
                                            <IonCard className="ion-no-margin">
                                                <IonCardContent className="ion-no-padding">
                                                    <IonRow>
                                                        <IonCol>
                                                            <IonItem detail={false}
                                                                lines='none'
                                                                button={false}
                                                                color="secondary">
                                                                    <IonRow>
                                                                        <IonCol size="8">
                                                                            <IonLabel position="stacked" className="input-label">{t("inquiry_status")}</IonLabel>
                                                                            <IonInput readonly={true} className='text-capitalize'>{t(task.status)}</IonInput>
                                                                        </IonCol>
                                                                        <IonCol size="4" >
                                                                            <IonLabel position="stacked" className="input-label">{t("updated_at")}</IonLabel>
                                                                            <IonInput readonly={true} className='text-capitalize'>{formatDate(task.updatedAt)}</IonInput>
                                                            
                                                                        </IonCol>
                                                                    </IonRow>
                                                            </IonItem>
                                                        </IonCol>
                                                    </IonRow>
                                                </IonCardContent>
                                            </IonCard>
                                        </IonCol>
                                    </IonRow>
                                   
                                </IonCardContent>
                            </IonCard>
                        </IonCol>
                    </IonRow>
                    {!showUpdate && (
                    <IonRow>
                        <IonCol className='ion-padding-horizontal ion-text-center'>
                            <IonButton onClick={() => setShowUpdate(true)}
                                color="dark"
                                expand='block'>{t("update_status")}
                            </IonButton>
                        </IonCol>
                    </IonRow>
                    )}
                    {showUpdate && (<>
                     <IonRow className="mt-30">
                        <IonCol>
                                <IonTitle className='ion-padding-bottom border-bottom-sec'>
                                {t("update_status")}
                                </IonTitle>
                        </IonCol>
                    </IonRow>
                    <IonRow>
                        <IonCol>
                            <IonCard>
                                <IonCardContent className="ion-no-padding">
                                   
                                    <IonRow>
                                        <IonCol>
                                            <IonCard className="ion-no-margin">
                                                <IonCardContent className="ion-no-margin ion-no-padding">
                                                    <IonRow>
                                                        <IonCol size="6">
                                                            <IonItem detail={false}
                                                                lines='none'
                                                                button={false}
                                                                >
                                                                <IonLabel position="stacked" color="secondary">
                                                                {t("select_status")}
                                                                </IonLabel>
                                                                    <IonSelect value={status}
                                                                            cancelText={t("cancel")}
                                                                            okText={t("ok")}
                                                                            placeholder={t("select_status")}
                                                                            onIonChange={
                                                                                (e) => setStatus(e.detail.value)
                                                                        }>
                                                                        <IonSelectOption value="upcoming">{t("upcoming")}</IonSelectOption>
                                                                        <IonSelectOption value="ongoing">{t("ongoing")}</IonSelectOption>
                                                                        <IonSelectOption value="review">{t("review")}</IonSelectOption>
                                                                        <IonSelectOption value="completed">{t("completed")}</IonSelectOption>
                                                                        <IonSelectOption value="deffered">{t("deffered")}</IonSelectOption>
                                                                        <IonSelectOption value="reopened">{t("reopened")}</IonSelectOption>

                                                                        
                                                                    </IonSelect>
                                                            </IonItem>
                                                        </IonCol>
                                                        <IonCol size="6" className='ion-text-center mt-15'>
                                                            <IonButton onClick={handleSubmit}
                                                                className="first-button"
                                                                fill="clear">{t("update_status")}</IonButton>
                                                        </IonCol>
                                                        
                                                    </IonRow>
                                                    
                                                </IonCardContent>
                                            </IonCard>
                                        </IonCol>
                                    </IonRow>
                                </IonCardContent>
                            </IonCard>
                        </IonCol>
                    </IonRow>
                    </>)}
                    <IonRow className="mt-30">
                        <IonCol>
                                <IonTitle className='ion-padding-bottom border-bottom-sec'>
                                {t("task_details")}
                                </IonTitle>
                        </IonCol>
                    </IonRow>
                    <IonRow>
                        <IonCol>
                            <IonCard>
                                <IonCardContent>
                                    <IonRow>
                                        <IonCol>
                                        <span className="profile-text">{task.title}</span>
                                        </IonCol>
                                    </IonRow>
                                    <IonRow>
                                        <IonCol size="4">
                                            <p>
                                                <span className='pt-05 text-capitalize'>{task.employee.name}</span>
                                                <span className='pt-05'> 
                                                <IonBadge color={(task.taskInfo.priority === "high") ? "danger" : (task.taskInfo.priority === "medium") ? "warning" : "medium"}>
                                                    {t(task.taskInfo.priority)}
                                                </IonBadge></span>
                                            </p>

                                        </IonCol>
                                        <IonCol size="7">
                                                <p>
                                                    <span className="pt-05">{task.taskInfo.projectName}</span>
                                                    <span className='pt-05'></span>{formatDate(task.taskInfo.startDate)} {t("to")} {formatDate(task.taskInfo.endDate)}
                                            
                                                </p>
                                        </IonCol>
                                        <IonCol size="1">
                                            
                                        <IonIcon 
                                                icon={constructSharp}
                                                color="secondary"
                                                className='table-icon mt-10'
                                                onClick={
                                                    () => history.push(`/app/task/${task._id}/edit`)
                                                }
                                            />
                                            <br/>
                                            <IonIcon 
                                                icon={chatbubblesSharp}
                                                color="tertiary"
                                                className='table-icon mt-10'
                                                onClick={
                                                    () => history.push(`/app/chat/${task._id}`)
                                                }
                                            />

                                            
                                        </IonCol>
                                    </IonRow>
                                    <IonRow className='ion-margin-top'>
                                        <IonCol>
                                            <p className='anchorme-text'>
                                                <Anchorme>{task.text}</Anchorme>
                                            </p>
                                        </IonCol>
                                    </IonRow>
                                </IonCardContent>
                            </IonCard>
                        </IonCol>
                    </IonRow>
                   
                    {(task.messages.length > 0) && (
                    <>
                    <IonRow className="mt-30">
                        <IonCol>
                                <IonTitle className='border-bottom-sec ion-padding-bottom'>
                                {t("timeline")}
                                </IonTitle>
                        </IonCol>
                    </IonRow>
                    <IonRow className="mt-10">
                        <IonCol>
                            <IonCard>
                                <IonCardContent className="ion-no-padding">
                                    <IonRow className="table-title ion-padding">
                                        <IonCol size="4" className='table-heading'>
                                            {t("status_update")}
                                        </IonCol>
                                        <IonCol sizeLg="4" size="0" className='table-heading ion-hide-lg-down'>
                                            {t("updated_by")}
                                        </IonCol>
                                        <IonCol sizeLg="4" size="8" className='table-heading ion-text-center'>
                                            {t("updated_at")}
                                        </IonCol>
                                        
                                    </IonRow>
                                    {(task.messages.slice(0).reverse().map((update, i) => 
                                    (update.message.update) && (
                                    <IonRow key={i} className='row-table-alt ion-padding-horizontal'>
                                        
                                            <IonCol size="4" className='table-field text-capitalize' >
                                                
                                                {t(update.message.update)}
                                                    
                                            </IonCol>
                                            
                                            <IonCol sizeLg="4" size="0" className='table-field ion-hide-lg-down text-capitalize'>
                                                
                                                {t(update.userInfo.userName)}
                                                    
                                                
                                            </IonCol>
                                            <IonCol sizeLg="4" size="8" className='table-field'>
                                                
                                                {formatDate(update.timestamp)}
                                                <IonText className='ion-hide-lg-up'> {t("marked_by")} {t(update.userInfo.userName)}</IonText>
                                            </IonCol>
                                    </IonRow>
                                    )
                                    ))}
                                    </IonCardContent>
                                </IonCard>
                            </IonCol>
                        </IonRow>
                    </>)}
                    </IonGrid>
                </IonContent>
                )}
                </IonModal>
            </IonGrid>
        </IonContent>
    </IonPage>
    );
};

export default TasksBoard;