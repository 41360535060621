import {
    IonAlert,
    IonBackButton,
    IonButtons,
    IonCard,
    IonCardContent,
    IonCol,
    IonContent,
    IonDatetime,
    IonGrid,
    IonHeader,
    IonIcon,
    IonInput,
    IonItem,
    IonLabel,
    IonLoading,
    IonMenuButton,
    IonPage,
    IonPopover,
    IonRow,
    IonSelect,
    IonSelectOption,
    IonTitle,
    IonToolbar,
    isPlatform,
} from '@ionic/react';
import axios from 'axios';
import { alertCircleOutline } from 'ionicons/icons';
import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { useTranslation } from "react-i18next";
import { useAuth } from '../../../components/AuthContext';
import ThemeToggle from '../../../components/ThemeToggle';
import LanguageToggle from '../../../components/LanguageToggle';
import SwitchBranch from '../../../components/SwitchBranch';

interface Employee {
    _id: string,
    name: string,
    attends: Attendance[]
}

interface Attendance {
    status: string,
    dated: Date
}

interface Department {
    _id: string,
    name: string
}

interface ParamTypes {
    role?: string
}

const AttendView: React.FC = () => {
    const {authInfo} = useAuth()!;
    const {t} = useTranslation();
    const {role} = useParams < ParamTypes > ();
    const [employees, setEmployees] = useState < Employee[] > ([]);
    const [departments, setDepartments] = useState < Department[] > ([]);
    const [department, setDepartment] = useState < string > ("");
    const [showLoading, setShowLoading] = useState < boolean > (false);
    const [iserror, setIserror] = useState < boolean > (false);
    const [message, setMessage] = useState < string > ("");
    const [selectedDate, setSelectedDate] = useState < string | string[] | undefined > ();
    const [days, setDays] = useState < number > (0);
    const [month, setMonth] = useState < number > (0);

    const api = axios.create({
        baseURL: process.env.REACT_APP_API_URL,
        timeout: parseInt(process.env.REACT_APP_API_TO!),
        headers: {
            'Authorization': 'Bearer ' + authInfo.token
        }
    });

    const formatDate = (value: string | string[] | undefined) => {
        if (typeof value === "string")
        {
            const monthNames = [t("jan"), t("feb"), t("mar"), t("apr"), t("may"), t("jun"),
            t("jul"), t("aug"), t("sep"), t("oct"), t("nov"), t("dec")
        ];

            const valueDate = new Date(value);
            return monthNames[valueDate.getMonth()] +' - '+ valueDate.getFullYear();
        }
    };

    const formatStatus = (employeeID: string, day: number) => {

        if (typeof selectedDate === "string")
        {
            const onDate = new Date(selectedDate);
            const d = new Date(onDate.getFullYear(), onDate.getMonth(), day);

            if (authInfo.institute.type === "school" && d.getDay() === 0)
            {
                return "S";
            }

            const attendances = employees.find(val => val._id === employeeID)!.attends;
            const attend = attendances.find(val=> new Date(val.dated).getDate() === day);
           
            if (attend)
            {
                if (attend.status === "present")
                {
                    return "P";
                }
                else if (attend.status === "absent")
                {
                    return "A";
                }
                else if (attend.status === "half")
                {
                    return "HD";
                }
                else if (attend.status === "late")
                {
                    return "L";
                }
                else if (attend.status === "leave")
                {
                    return "OL";
                }
                else if (attend.status === "holiday")
                {
                    return "H";
                }
            }
            else
            {
                return " ";
            }

        }
    };

    const handleDepartment = (depID: string) => {
        setDepartment(depID);
        const dated = new Date(Date.now()).toISOString();
        setSelectedDate(dated);

        const onDate = new Date(dated);
        const d = new Date(onDate.getFullYear(), onDate.getMonth()+1, 0);
        setDays(d.getDate());
        setMonth(onDate.getMonth()+1);

        const fetchUp = async () => {
            setShowLoading(true);
            try {
                const studs = await api.get(`/staffAttends/getMonthDepart/${depID}/${dated}`);
                setEmployees(studs.data);

            } catch (error: any) {
                setMessage(error.response?.data.message || t("something_went_wrong"));
                setIserror(true);
            } finally {
                setShowLoading(false);
            }
            
        };

        const fetchAll = async () => {
            setShowLoading(true);
            try {
                const studs = await api.get(`/staffAttends/getMonthInsti/${authInfo.user.instituteID}/${dated}`);
                setEmployees(studs.data);

            } catch (error: any) {
                setMessage(error.response?.data.message || t("something_went_wrong"));
                setIserror(true);
            } finally {
                setShowLoading(false);
            }
        };

        if (depID === 'all')
        {
            fetchAll();
        }
        else
        {
            fetchUp();
        }

    }

    const handleSubmit = (dated: string | string[]) => {

        if (authInfo.user.role === 'Admin')
        {
            if (!department || department === "") {
                setMessage(t("select_dep"));
                setIserror(true);
                return;
            }
        }
   
        if (!(typeof(dated) === "string")) {
            setMessage(t("select_month"));
            setIserror(true);
            return;
        }

        setSelectedDate(dated);
        const onDate = new Date(dated);

        const d = new Date(onDate.getFullYear(), onDate.getMonth()+1, 0);
        setDays(d.getDate());
        setMonth(onDate.getMonth()+1);

        const fetchUp = async () => {
            setShowLoading(true);
            try {
                const studs = await api.get(`/staffAttends/getMonthDepart/${department}/${dated}`);
                setEmployees(studs.data);

            } catch (error: any) {
                setMessage(error.response?.data.message || t("something_went_wrong"));
                setIserror(true);
            } finally {
                setShowLoading(false);
            }
            
        };

        const fetchAll = async () => {
            setShowLoading(true);
            try {
                const studs = await api.get(`/staffAttends/getMonthInsti/${authInfo.user.instituteID}/${dated}`);
                setEmployees(studs.data);

            } catch (error: any) {
                setMessage(error.response?.data.message || t("something_went_wrong"));
                setIserror(true);
            } finally {
                setShowLoading(false);
            }
        };

        const fetchTeacher = async () => {
            setShowLoading(true);
            try {
                const studs = await api.get(`/staffAttends/getMonthTeacher/${authInfo.user.instituteID}/${dated}`);
                setEmployees(studs.data);

            } catch (error: any) {
                setMessage(error.response?.data.message || t("something_went_wrong"));
                setIserror(true);
            } finally {
                setShowLoading(false);
            }
        };

        const fetchDriver = async () => {
            setShowLoading(true);
            try {
                const studs = await api.get(`/staffAttends/getMonthDriver/${authInfo.user.instituteID}/${dated}`);
                setEmployees(studs.data);

            } catch (error: any) {
                setMessage(error.response?.data.message || t("something_went_wrong"));
                setIserror(true);
            } finally {
                setShowLoading(false);
            }
        };

        if (authInfo.user.role === 'Admin')
        {
            if (department === 'all')
            {
                fetchAll();
            }
            else
            {
                fetchUp();
            }
        }
        else
        {
            if ((authInfo.user.role === 'Academic') || (authInfo.user.role === 'Employee' && role === "teachers"))
            {
                fetchTeacher();
            }
            if ((authInfo.user.role === 'Transporter') || (authInfo.user.role === 'Employee' && role === "drivers"))
            {
                fetchDriver();
            }
        }

    }

    useEffect(() => {

        const fetchUp = async () => {
            setShowLoading(true);
            try {
                if (authInfo.user.role === 'Super' || authInfo.user.role === 'Admin')
                {
                    const result = await api.get(`/departments/getAllInstitute/${authInfo.user.instituteID}`);
                    setDepartments(result.data);
                    setEmployees([]);
                }
                else
                {
                    const dated = new Date(Date.now()).toISOString();
                    setSelectedDate(dated);
            
                    const onDate = new Date(dated);
                    const d = new Date(onDate.getFullYear(), onDate.getMonth()+1, 0);
                    setDays(d.getDate());
                    setMonth(onDate.getMonth()+1);
            
                    if ((authInfo.user.role === 'Academic') || (authInfo.user.role === 'Employee' && role === "teachers"))
                    {
                        const studs = await api.get(`/staffAttends/getMonthTeacher/${authInfo.user.instituteID}/${dated}`);
                        setEmployees(studs.data);
                    }
                    if ((authInfo.user.role === 'Transporter') || (authInfo.user.role === 'Employee' && role === "drivers"))
                    {
                        const studs = await api.get(`/staffAttends/getMonthDriver/${authInfo.user.instituteID}/${dated}`);
                        setEmployees(studs.data);
                    }
                }

            } catch (error: any) {
                setMessage(error.response?.data.message || t("something_went_wrong"));
                setIserror(true);
            } finally {
                setShowLoading(false);
            }
            
        };

        fetchUp();

    }, [authInfo, role]);

    return (
        <IonPage>

            <IonHeader>
                <IonToolbar className="white-shade">
                    <IonButtons slot="start">
                        <IonMenuButton color="secondary"/>
                        <IonBackButton color="secondary" defaultHref={`/app/staff`} />
                    </IonButtons>
                    <IonTitle size={isPlatform('mobile') ? "small" : undefined} color="secondary">{t("view_attendance")}</IonTitle>
                    {!isPlatform('mobile') && (
                    <IonButtons slot="end">
                        <ThemeToggle />
                        <LanguageToggle />
                    </IonButtons>
                    )}
                </IonToolbar>
            </IonHeader>


            <IonContent fullscreen={true} className="grey-shade">

                <IonHeader collapse="condense">
                    <IonToolbar>
                        <IonTitle size="large" color="secondary">{t("view_attendance")}</IonTitle>
                    </IonToolbar>
                </IonHeader>

                <IonLoading isOpen={showLoading}
                    cssClass="first-loading"
                    spinner="circular"
                    message={t("please_wait")}
                />

                <IonAlert isOpen={iserror}
                    cssClass="first-alert"
                    onDidDismiss={
                        () => setIserror(false)
                    }
                    header={t("error")}
                    message={message}
                    buttons={
                        [`${t("close")}`]
                    }
                />
                
                {(authInfo.user.role === "Super") && (
                    <SwitchBranch />
                )}
                
                <IonGrid className="ion-no-padding max-xl">
                    <IonRow className="ion-margin-top">
                    {((authInfo.user.role === 'Super') || (authInfo.user.role === 'Admin')) && (
                         <IonCol size="6">
                            <IonCard>
                                <IonCardContent className="ion-no-padding">
                                    <IonItem>
                                        <IonLabel position="stacked" className="input-label" color="secondary">
                                        {t("select_department")}</IonLabel>
                                            <IonSelect value={department}
                                                    className="input-field"
                                                    cancelText={t("cancel")}
                                                    okText={t("ok")}
                                                    placeholder={t("select_department")}
                                                    onIonChange={
                                                        (e) => handleDepartment(e.detail.value)
                                                }>
                                                     <IonSelectOption value="all">{t("all")}</IonSelectOption>
                                                    {departments.map((dep) => (
                                                        (<IonSelectOption key={dep._id} value={`${dep._id}`}>{dep.name}</IonSelectOption>)
                                                    ))}
                                            </IonSelect>
                                        </IonItem>
                                </IonCardContent>
                            </IonCard>
                        </IonCol>
                    )}
                        <IonCol size="6">
                            <IonCard>

                                <IonCardContent className="ion-no-padding">
    
                                        <IonItem detail={false}
                                            lines='full'
                                            button={false}
                                            id="open-date-input">
                                            <IonLabel position="stacked" className="input-label" color="secondary">{t("month")}</IonLabel>
                                            <IonInput className="input-field" value={(!selectedDate || selectedDate === '') ? t("pick_month") : formatDate(selectedDate)} readonly={true} />
                                            <IonPopover trigger="open-date-input" showBackdrop={false} size='cover'>
                                                <IonDatetime
                                                    value={selectedDate}
                                                    presentation="month-year"
                                                    onIonChange={ev => handleSubmit(ev.detail.value!)}
                                                    showDefaultButtons={true}
                                                    size="cover"
                                                />
                                            </IonPopover>
                                        </IonItem>

                                </IonCardContent>
                            </IonCard>
                        </IonCol>
                       
                    </IonRow>
                </IonGrid>
                <IonGrid className="ion-no-padding mt-10 mb-60 overflow-x">
                    <div className="ion-table min-xxxl">

                        <IonRow className="table-title ion-padding">

                            <IonCol size="1" className='table-heading heading-fixed ion-padding-start'>
                                {t("name")}
                            </IonCol>
                            {Array(days).fill(1).map((el, j) =>
                                <IonCol key={j} size="0.35" className='table-heading pl-01'>
                                    {j+1+"/"+month}
                                </IonCol>
                            )}
                            
                        </IonRow>
  

                        {(employees.length > 0) ? (employees.map((employee, i) => {
                            return (
                                <IonRow key={employee._id} className={((i%2) === 0) ? 'row-table ion-padding-horizontal ion-padding-start' : 'row-table-alt ion-padding-horizontal ion-padding-start'}>
                                    
                                        <IonCol size="1" className={((i%2) === 0) ? 'table-field-no-margin ion-padding field-fixed text-capitalize' : 'table-field-no-margin ion-padding field-fixed-alt text-capitalize'} >
                                            {employee.name}
                                        </IonCol>
                                        {Array(days).fill(1).map((el, k) => {
                                            if(formatStatus(employee._id, k+1) === "P")
                                            {
                                                return (<IonCol key={k} size="0.35" className='table-field-no-margin border-left ion-padding text-bold success-field'>
                                                        P</IonCol>);
                                            }
                                            else if(formatStatus(employee._id, k+1) === "A")
                                            {
                                                return (<IonCol key={k} size="0.35" className='table-field-no-margin border-left ion-padding text-bold danger-field'>
                                                        A</IonCol>);
                                            }
                                            else if(formatStatus(employee._id, k+1) === "S")
                                            {
                                                return (<IonCol key={k} size="0.35" className='table-field-no-margin border-left ion-padding text-bold secondary-field'>
                                                S</IonCol>);
                                            }
                                            else if(formatStatus(employee._id, k+1) === "L")
                                            {
                                                return (<IonCol key={k} size="0.35" className='table-field-no-margin border-left ion-padding text-bold warning-field'>
                                                L</IonCol>);
                                            }
                                            else if(formatStatus(employee._id, k+1) === "HD")
                                            {
                                                return (<IonCol key={k} size="0.35" className='table-field-no-margin border-left ion-padding text-bold warning-field'>
                                                HD</IonCol>);
                                            }
                                            else if(formatStatus(employee._id, k+1) === "OL")
                                            {
                                                return (<IonCol key={k} size="0.35" className='table-field-no-margin border-left ion-padding text-bold danger-field'>
                                                OL</IonCol>);
                                            }
                                            else if(formatStatus(employee._id, k+1) === "H")
                                            {
                                                return (<IonCol key={k} size="0.35" className='table-field-no-margin border-left ion-padding text-bold secondary-field'>
                                                H</IonCol>);
                                            }
                                            else
                                            {
                                                return (<IonCol key={k} size="0.35" className='table-field-no-margin border-left ion-padding text-bold'>
                                                </IonCol>);
                                            }
                                            
                                        }
                                            
                                        )}
                                </IonRow>
                            )
                        })) : ( 
                        <IonRow>
                            <IonCol>
                                <IonCard>
                                    <IonCardContent>
                                        <IonItem lines="none">
                                            <IonIcon icon={alertCircleOutline}
                                                slot="start"
                                                color="danger" />
                                            <IonLabel className="list-title"><h3>{t("no_employees")}</h3></IonLabel>

                                        </IonItem>
                                    </IonCardContent>
                                </IonCard>
                            </IonCol>
                        </IonRow>
                        )
                        } 

                    </div>

                </IonGrid>

            </IonContent>
           
        </IonPage>
    );
};

export default AttendView;