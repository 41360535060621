import {
  IonTabs,
  IonTabBar,
  IonTabButton,
  IonRouterOutlet,
  IonLabel,
  IonIcon
} from "@ionic/react";

import React from 'react';
import { Redirect, Route, Switch } from "react-router";

import EmployeeHome from "../pages/Home/EmployeeHome";
import News from "../pages/Employee/News/News";
import NewsDetail from "../pages/Employee/News/NewsDetail";

import Academics from "../pages/Admin/Dashboard/Academics";
import Accounting from "../pages/Admin/Dashboard/Accounting";
import Transport from "../pages/Admin/Dashboard/Transport";
import Admission from "../pages/Admin/Dashboard/Admission";
import Inventory from "../pages/Admin/Dashboard/Inventory";
import Student from "../pages/Admin/Dashboard/Student";
import Marketing from "../pages/Admin/Dashboard/Marketing";
import ManageClasses from "../pages/Employee/Dashboard/ManageClasses";

import EmployeeDetail from "../pages/Admin/Staff/EmployeeDetail";
import BasicInfo from "../pages/Admin/Staff/BasicInfo";
import AccountInfo from "../pages/Admin/Staff/AccountInfo";
import EmployeeAttendance from "../pages/Admin/Staff/EmployeeAttendance";
import StaffAttend from "../pages/Admin/Staff/StaffAttend";
import AttendView from "../pages/Admin/Staff/AttendView";
import StaffLeaves from "../pages/Admin/Staff/StaffLeaves";
import StaffEntryExit from "../pages/Admin/Staff/StaffEntryExit";

import ManageTeachers from "../pages/Employee/Dashboard/ManageTeachers";
import Teachers from "../pages/Employee/Teacher/Teachers";
import AddTeacher from "../pages/Employee/Teacher/AddTeacher";
import TeacherStatus from "../pages/Employee/Teacher/TeacherStatus";
import TeacherLog from "../pages/Employee/Teacher/TeacherLog";

import ManageDrivers from "../pages/Employee/Dashboard/ManageDrivers";
import Drivers from "../pages/Employee/Driver/Drivers";
import AddDriver from "../pages/Employee/Driver/AddDriver";
import DriverStatus from "../pages/Employee/Driver/DriverStatus";
import DriverLog from "../pages/Employee/Driver/DriverLog";

import Classes from "../pages/Admin/Class/Classes";
import AddClass from "../pages/Admin/Class/AddClass";
import PromoteClass from "../pages/Admin/Class/PromoteClass";
import ClassDetail from "../pages/Admin/Class/ClassDetail";
import ClassIdCards from "../pages/Admin/Class/ClassIdCards";
import ClassSubjects from "../pages/Admin/Class/ClassSubjects";
import ClassTimeTables from "../pages/Admin/Class/ClassTimeTables";

import Attendance from "../pages/Employee/Dashboard/Attendance";
import TeacherPlanner from "../pages/Teacher/Academics/Planner";
import TeacherStudentSubmissions from "../pages/Teacher/Academics/StudentSubmissions";
import TeacherTopicSubmissions from "../pages/Teacher/Academics/TopicSubmissions";
import TeacherSubjects from "../pages/Teacher/Academics/Subjects";
import TeacherAttendStatus from "../pages/Teacher/Attendance/AttendStatus";
import TeacherStudents from "../pages/Teacher/Student/Students";
import TeacherAddStudent from "../pages/Teacher/Student/AddStudent";
import TeacherAbsentStudents from "../pages/Teacher/Student/AbsentStudents";

import Students from "../pages/Admin/Student/Students";
import AddStudent from "../pages/Admin/Student/AddStudent";
import StudentDetail from "../pages/Admin/Student/StudentDetail";
import StudentLocation from "../pages/Admin/Student/StudentLocation";
import StudentAttendance from "../pages/Admin/Student/StudentAttendance";
import StudentIncidents from "../pages/Admin/Student/StudentIncidents";
import StudentGuardians from "../pages/Admin/Student/StudentGuardians";
import StudentLedger from "../pages/Admin/Student/StudentLedger";
import StudentProfile from "../pages/Admin/Student/StudentProfile";
import ParentProfile from "../pages/Admin/Student/ParentProfile";
import WithdrawnStudents from "../pages/Admin/Student/WithdrawnStudents";
import GraduatedStudents from "../pages/Admin/Student/GraduatedStudents";
import InactiveStudents from "../pages/Admin/Student/InactiveStudents";
import AbsentStudents from "../pages/Admin/Student/AbsentStudents";

import EntryExit from "../pages/Admin/Attendance/EntryExit";
import StudentLog from "../pages/Admin/Attendance/StudentLog";
import InstituteLog from "../pages/Admin/Attendance/InstituteLog";
import Attend from "../pages/Admin/Attendance/Attend";
import AttendClass from "../pages/Admin/Attendance/AttendClass";
import AttendStatus from "../pages/Admin/Attendance/AttendStatus";
import LeaveApplications from "../pages/Admin/Attendance/LeaveApplications";

import ScanEntry from "../pages/Admin/Attendance/ScanEntry";
import ScanExit from "../pages/Admin/Attendance/ScanExit";
import ScanPresent from "../pages/Admin/Attendance/ScanPresent";

import Circulars from "../pages/Admin/Circular/Circulars";
import AddCircular from "../pages/Admin/Circular/AddCircular";
import CircularDetail from "../pages/Admin/Circular/CircularDetail";
import AddInquiry from "../pages/Admin/Inquiry/AddInquiry";
import PendingInquiries from "../pages/Admin/Inquiry/PendingInquiries";
import ProcessedInquiries from "../pages/Admin/Inquiry/ProcessedInquiries";
import StudentInquiries from "../pages/Admin/Inquiry/StudentInquiries";
import InquiryDetail from "../pages/Admin/Inquiry/InquiryDetail";
import ChatDetail from "../pages/Admin/Staff/Chat/ChatDetail";

import InstituteLocation from "../pages/Admin/School/InstituteLocation";
import Routes from "../pages/Admin/Route/Routes";
import AddRoute from "../pages/Admin/Route/AddRoute";
import RouteDetail from "../pages/Admin/Route/RouteDetail";
import RouteStudent from "../pages/Admin/Route/RouteStudent";
import CompletedTrips from "../pages/Admin/Trip/CompletedTrips";
import CompletedTrip from "../pages/Admin/Trip/CompletedTrip";
import OngoingTrips from "../pages/Admin/Trip/OngoingTrips";
import OngoingTrip from "../pages/Admin/Trip/OngoingTrip";
import TripsAlerts from "../pages/Admin/Trip/TripsAlerts";
import TripsReports from "../pages/Admin/Trip/TripsReports";
import Vehicles from "../pages/Admin/Vehicle/Vehicles";
import AddVehicle from "../pages/Admin/Vehicle/AddVehicle";
import VehicleDetail from "../pages/Admin/Vehicle/VehicleDetail";
import VehicleExpenses from "../pages/Admin/Vehicle/VehicleExpenses";

import Transactions from "../pages/Admin/Transaction/Transactions";
import Reports from "../pages/Admin/Transaction/Reports";

import AddDues from "../pages/Admin/Due/AddDues";
import AddManyDues from "../pages/Admin/Due/AddManyDues";
import ScheduledInvoices from "../pages/Admin/Invoice/ScheduledInvoices";
import UnclearedInvoices from "../pages/Admin/Invoice/UnclearedInvoices";
import ClearedInvoices from "../pages/Admin/Invoice/ClearedInvoices";
import StudentInvoices from "../pages/Admin/Invoice/StudentInvoices";
import InvoiceDetail from "../pages/Admin/Invoice/InvoiceDetail";

import ViewDues from "../pages/Admin/Due/ViewDues";
import DuesReports from "../pages/Admin/Due/DuesReports";
import CollectFee from "../pages/Admin/Due/CollectFee";
import GiveConcession from "../pages/Admin/Due/GiveConcession";
import ChargeLateFees from "../pages/Admin/Due/ChargeLateFees";
import CollectReceipts from "../pages/Admin/Due/CollectReceipts";
import CollectReports from "../pages/Admin/Due/CollectReports";
import Concessions from "../pages/Admin/Due/Concessions";
import ReceiptDetail from "../pages/Admin/Transaction/ReceiptDetail";

import TransportExpense from "../pages/Admin/Transaction/TransportExpense";
import ExpenseReports from "../pages/Admin/Transaction/ExpenseReports";
import Bills from "../pages/Admin/Transaction/Bills";
import AddBill from "../pages/Admin/Transaction/AddBill";
import BillDetail from "../pages/Admin/Transaction/BillDetail";

import Fees from "../pages/Admin/Fee/Fees";
import AddFee from "../pages/Admin/Fee/AddFee";
import StudentFee from "../pages/Admin/Fee/StudentFee";
import ClassFee from "../pages/Admin/Fee/ClassFee";
import FeeDetail from "../pages/Admin/Fee/FeeDetail";
import FeeHeads from "../pages/Admin/Fee/FeeHeads";
import AddFeeHead from "../pages/Admin/Fee/AddFeeHead";
import FeeHeadDetail from "../pages/Admin/Fee/FeeHeadDetail";

import ReportCards from "../pages/Admin/Academic/ReportCard/ReportCards";
import GenerateReportCard from "../pages/Admin/Academic/ReportCard/GenerateReportCard";
import ReportCardDetail from "../pages/Admin/Academic/ReportCard/ReportCardDetail";
import Formats from "../pages/Admin/Academic/ReportCard/Formats";
import AddFormat from "../pages/Admin/Academic/ReportCard/AddFormat";
import FormatDetail from "../pages/Admin/Academic/ReportCard/FormatDetail";

import Certificates from "../pages/Admin/Academic/Certificate/Certificates";
import UploadCertificate from "../pages/Admin/Academic/Certificate/UploadCertificate";
import GenerateCertificate from "../pages/Admin/Academic/Certificate/GenerateCertificate";

import AddCourse from "../pages/Admin/Academic/Course/AddCourse";
import Courses from "../pages/Admin/Academic/Course/Courses";
import CourseDetail from "../pages/Admin/Academic/Course/CourseDetail";
import CourseTopics from "../pages/Admin/Academic/Course/CourseTopics";
import TopicDetail from "../pages/Admin/Academic/Course/TopicDetail";
import QuestionDetail from "../pages/Admin/Academic/Course/QuestionDetail";
import BatchSchedule from "../pages/Admin/Academic/BatchSchedule";

import AssessmentDetail from "../pages/Admin/Academic/Submission/AssessmentDetail";
import SubmissionDetail from "../pages/Admin/Academic/Submission/SubmissionDetail";
import StudentSubmissions from "../pages/Admin/Academic/Submission/StudentSubmissions";
import TopicSubmissions from "../pages/Admin/Academic/Submission/TopicSubmissions";
import Leaderboards from "../pages/Admin/Academic/Leaderboards";

import Subjects from "../pages/Admin/Subject/Subjects";
import AddSubject from "../pages/Admin/Subject/AddSubject";
import SubjectDetail from "../pages/Admin/Subject/SubjectDetail";

import TimeTables from "../pages/Admin/TimeTable/TimeTables";
import TimeSlots from "../pages/Admin/TimeTable/TimeSlot/TimeSlots";
import AddSlot from "../pages/Admin/TimeTable/TimeSlot/AddSlot";
import SlotDetail from "../pages/Admin/TimeTable/TimeSlot/SlotDetail";
import AddTimeTableOne from "../pages/Admin/TimeTable/AddTimeTableOne";
import AddTimeTableTwo from "../pages/Admin/TimeTable/AddTimeTableTwo";
import UpdateTimeTableOne from "../pages/Admin/TimeTable/UpdateTimeTableOne";
import UpdateTimeTableTwo from "../pages/Admin/TimeTable/UpdateTimeTableTwo";
import DayTimeTable from "../pages/Admin/TimeTable/DayTimeTable";
import TeacherTimeTable from "../pages/Admin/TimeTable/TeacherTimeTable";
import ClassTimeTable from "../pages/Admin/TimeTable/ClassTimeTable";
import Substitutes from "../pages/Admin/TimeTable/Substitute/Substitutes";
import SubHistory from "../pages/Admin/TimeTable/Substitute/SubHistory";

import ItemDetail from "../pages/Admin/Inventory/Item/ItemDetail";
import ItemLedger from "../pages/Admin/Inventory/Item/ItemLedger";
import ItemReports from "../pages/Admin/Inventory/Item/ItemReports";
import Items from "../pages/Admin/Inventory/Item/Items";
import AddItem from "../pages/Admin/Inventory/Item/AddItem";
import AddCombinedItem from "../pages/Admin/Inventory/Item/AddCombinedItem";
import CombinedItems from "../pages/Admin/Inventory/Item/CombinedItems";
import CombinedItemDetails from "../pages/Admin/Inventory/Item/CombinedItemDetails";
import AddStore from "../pages/Admin/Inventory/Store/AddStore";
import StoreDetail from "../pages/Admin/Inventory/Store/StoreDetail";
import Stores from "../pages/Admin/Inventory/Store/Stores";
import BuyItem from "../pages/Admin/Inventory/Transact/BuyItem";
import ItemTransactions from "../pages/Admin/Inventory/Transact/ItemTransactions";
import SellCombinedItem from "../pages/Admin/Inventory/Transact/SellICombinedItem";
import SellItem from "../pages/Admin/Inventory/Transact/SellItem";
import AddVendor from "../pages/Admin/Inventory/Vendor/AddVendor";
import VendorDetail from "../pages/Admin/Inventory/Vendor/VendorDetail";
import VendorLedger from "../pages/Admin/Inventory/Vendor/VendorLedger";
import VendorPayment from "../pages/Admin/Inventory/Vendor/VendorPayment";
import VendorReports from "../pages/Admin/Inventory/Vendor/VendorReports";
import Vendors from "../pages/Admin/Inventory/Vendor/Vendors";

import KeyStats from "../pages/Admin/Admission/School/KeyStats";
import Facilities from "../pages/Admin/Admission/School/Facilities";
import Gallery from "../pages/Admin/Admission/School/Gallery";
import ClassSetup from "../pages/Admin/Admission/School/ClassSetup";
import NewCourse from "../pages/Admin/Admission/School/NewCourse";
import UpdateCourse from "../pages/Admin/Admission/School/UpdateCourse";
import FormSetup from "../pages/Admin/Admission/School/FormSetup";
import ApplicationsBoard from "../pages/Admin/Admission/Application/ApplicationsBoard";
import Applications from "../pages/Admin/Admission/Application/Applications";
import AdmissionList from "../pages/Admin/Admission/Application/AdmissionList";
import ApplicationsHistory from "../pages/Admin/Admission/Application/ApplicationsHistory";
import ApplicationDetail from "../pages/Admin/Admission/Application/ApplicationDetail";
import Appointments from "../pages/Admin/Admission/Application/Appointments";
import AppointmentsHistory from "../pages/Admin/Admission/Application/AppointmentsHistory";
import AdmissionReports from "../pages/Admin/Admission/AdmissionReports";

import NewInquiry from "../pages/Admin/Admission/Enquiry/NewInquiry";
import Inquiries from "../pages/Admin/Admission/Enquiry/Inquiries";
import InquiriesBoard from "../pages/Admin/Admission/Enquiry/InquiriesBoard";
import EnquiryDetail from "../pages/Admin/Admission/Enquiry/EnquiryDetail";
import Inquiry from "../pages/Admin/Admission/Enquiry/Inquiry";
import InquiryChat from "../pages/Admin/Admission/Enquiry/InquiryChat";
import InquiryReports from "../pages/Admin/Admission/Enquiry/InquiryReports";

import AddMedia from "../pages/Admin/Whatsapp/AddMedia";
import Medias from "../pages/Admin/Whatsapp/Medias";
import MediaDetail from "../pages/Admin/Whatsapp/MediaDetail";
import AddTemplate from "../pages/Admin/Whatsapp/AddTemplate";
import Templates from "../pages/Admin/Whatsapp/Templates";
import TemplateDetail from "../pages/Admin/Whatsapp/TemplateDetail";
import StartCampaign from "../pages/Admin/Whatsapp/StartCampaign";
import Campaigns from "../pages/Admin/Whatsapp/Campaigns";
import CampaignReports from "../pages/Admin/Whatsapp/CampaignReports";
import NewMessage from "../pages/Admin/Whatsapp/NewMessage";
import Messages from "../pages/Admin/Whatsapp/Messages";
import MessageDetail from "../pages/Admin/Whatsapp/MessageDetail";

import MyTasks from "../pages/Employee/Task/MyTasks";
import DailyReports from "../pages/Employee/Task/DailyReports";
import MyChats from "../pages/Employee/Chat/MyChats";
import AddChat from "../pages/Employee/Chat/AddChat";
import ViewChat from "../pages/Employee/Chat/ViewChat";

import Profile from "../pages/User/Profile";
import UpdatePersonal from "../pages/User/UpdatePersonal";
import UpdatePassword from "../pages/User/UpdatePassword";
import UserNotifications from "../pages/User/UserNotifications";
import MyAttendance from "../pages/Employee/Attendance/MyAttendance";
import MyLeaves from "../pages/Employee/Attendance/MyLeaves";
import AddLeave from "../pages/Employee/Attendance/AddLeave";

import Terms from "../pages/Policies/TermsAndConditions";
import Privacy from "../pages/Policies/PrivacyPolicy";
import ContactUs from "../pages/General/ContactUs";
import DeleteAccount from "../pages/General/DeleteAccount";

import { calendarNumber, chatbubbleEllipses, clipboard, home, newspaper } from "ionicons/icons";
import { useTranslation } from "react-i18next";
import { useAuth } from './AuthContext';

const EmployeeTabs: React.FC = () => {
  const {t} = useTranslation();
  const {authInfo} = useAuth()!;
  return (
    <IonTabs>
      <IonRouterOutlet>
        <Switch>
          <Route path="/app/home" exact={true} component={EmployeeHome} />
          <Route path="/app/news" exact={true} component={News} />
          <Route path="/app/news/:newsID" exact={true} component={NewsDetail} />

          <Route path="/app/student" exact={true} component={Student} />
          <Route path="/app/academics" exact={true} component={Academics} />
          <Route path="/app/transport" exact={true} component={Transport} />
          <Route path="/app/accounting" exact={true} component={Accounting} />
          <Route path="/app/admission" exact={true} component={Admission} />
          <Route path="/app/inventory" exact={true} component={Inventory} />
          <Route path="/app/crm" exact={true} component={Marketing} />

          <Route path="/app/classes" exact={true} component={ManageClasses} />
          <Route path="/app/teachers" exact={true} component={ManageTeachers} />
          <Route path="/app/drivers" exact={true} component={ManageDrivers} />

          <Route path="/app/teachers/manage" exact={true} component={Teachers} />
          <Route path="/app/teachers/add" exact={true} component={AddTeacher} />
          <Route path="/app/attendance/teacher/status" exact={true} component={TeacherStatus} />
          <Route path="/app/attendance/teacher/log/:employeeID?" exact={true} component={TeacherLog} />
          
          <Route path="/app/drivers/manage" exact={true} component={Drivers} />
          <Route path="/app/drivers/add" exact={true} component={AddDriver} />
          <Route path="/app/attendance/driver/status" exact={true} component={DriverStatus} />
          <Route path="/app/attendance/driver/log/:employeeID?" exact={true} component={DriverLog} />

          <Route path="/app/employee/:employeeID" exact={true} component={EmployeeDetail} />
          <Route path="/app/employee/profile/:employeeID" exact={true} component={BasicInfo} />
          <Route path="/app/employee/account/:employeeID" exact={true} component={AccountInfo} />
          <Route path="/app/employee/attendance/:employeeID" exact={true} component={EmployeeAttendance} />
          <Route path="/app/attend/staff/mark/:role?" exact={true} component={StaffAttend} />
          <Route path="/app/attend/staff/view/:role?" exact={true} component={AttendView} />
          <Route path="/app/attend/staff/leave/:role?" exact={true} component={StaffLeaves} />
          <Route path="/app/attend/staff/entryexit/:role?" exact={true} component={StaffEntryExit} />
          <Route path="/app/chat/:chatID" exact={true} component={ChatDetail} />

          <Route path="/app/planner/teacher" exact={true} component={TeacherPlanner} />
          <Route path="/app/teacher/submissions/student/:studentID?" exact={true} component={TeacherStudentSubmissions} />
          <Route path="/app/teacher/submissions/topic/:topicID?/:classID?" exact={true} component={TeacherTopicSubmissions} />
          <Route path="/app/subjects/teacher" exact={true} component={TeacherSubjects} />
          <Route path="/app/attendance" exact={true} component={Attendance} />
          <Route path="/app/attendance/status" exact={true} component={TeacherAttendStatus} />
          <Route path="/app/students/teacher/view" exact={true} component={TeacherStudents} />
          <Route path="/app/students/teacher/add" exact={true} component={TeacherAddStudent} />
          <Route path="/app/students/teacher/absent" exact={true} component={TeacherAbsentStudents} />

          <Route path="/app/classes/manage" exact={true} component={Classes} />
          <Route path="/app/class/add" exact={true} component={AddClass} />
          <Route path="/app/class/promote/:classID?" exact={true} component={PromoteClass} />
          <Route path="/app/class/:classID" exact={true} component={ClassDetail} />
          <Route path="/app/class/idcards/:classID" exact={true} component={ClassIdCards} />
          <Route path="/app/class/subjects/:classID" exact={true} component={ClassSubjects} />
          <Route path="/app/class/timetables/:classID" exact={true} component={ClassTimeTables} />

          <Route path="/app/students/:classID?" exact={true} component={Students} />
          <Route path="/app/student/add" exact={true} component={AddStudent} />
          <Route path="/app/student/withdrawn" exact={true} component={WithdrawnStudents} />
          <Route path="/app/student/graduated" exact={true} component={GraduatedStudents} />
          <Route path="/app/student/inactive" exact={true} component={InactiveStudents} />
          <Route path="/app/student/absent" exact={true} component={AbsentStudents} />
          <Route path="/app/student/:studentID" exact={true} component={StudentDetail} />
          <Route path="/app/student/location/:studentID" exact={true} component={StudentLocation} />
          <Route path="/app/student/attendance/:studentID" exact={true} component={StudentAttendance} />
          <Route path="/app/student/profile/:studentID" exact={true} component={StudentProfile} />
          <Route path="/app/student/ledger/:studentID" exact={true} component={StudentLedger} />
          <Route path="/app/student/parent/:parentID" exact={true} component={ParentProfile} />
          <Route path="/app/student/incidents/:studentID" exact={true} component={StudentIncidents} />
          <Route path="/app/student/guardians/:studentID" exact={true} component={StudentGuardians} />

          <Route path="/app/attend" exact={true} component={Attend} />
          <Route path="/app/attend/status" exact={true} component={AttendStatus} />
          <Route path="/app/attend/class/:classID?" exact={true} component={AttendClass} />
          <Route path="/app/attend/leave" exact={true} component={LeaveApplications} />
          <Route path="/app/entryexit" exact={true} component={EntryExit} />
          <Route path="/app/entry/log/:student?" exact={true} component={StudentLog} />
          <Route path="/app/attend/log" exact={true} component={InstituteLog} />

          <Route path="/app/scan/entry" exact={true} component={ScanEntry} />
          <Route path="/app/scan/exit" exact={true} component={ScanExit} />
          <Route path="/app/scan/present" exact={true} component={ScanPresent} />

          <Route path="/app/subjects" exact={true} component={Subjects} />
          <Route path="/app/subjects/add" exact={true} component={AddSubject} />
          <Route path="/app/subject/:subjectID" exact={true} component={SubjectDetail} />

          <Route path="/app/reportcard/view" exact={true} component={ReportCards} />
          <Route path="/app/reportcard/generate/:studentID?" exact={true} component={GenerateReportCard} />
          <Route path="/app/reportcard/:reportcardID" exact={true} component={ReportCardDetail} />
          <Route path="/app/reportcard/formats" exact={true} component={Formats} />
          <Route path="/app/reportcard/formats/add" exact={true} component={AddFormat} />
          <Route path="/app/reportcard/format/:formatID" exact={true} component={FormatDetail} />

          <Route path="/app/certificates" exact={true} component={Certificates} />
          <Route path="/app/certificate/upload/:studentID?" exact={true} component={UploadCertificate} />
          <Route path="/app/certificate/generate/:studentID?" exact={true} component={GenerateCertificate} />

          <Route path="/app/courses/add" exact={true} component={AddCourse} />
          <Route path="/app/courses/view" exact={true} component={Courses} />
          <Route path="/app/course/:courseID" exact={true} component={CourseDetail} />
          <Route path="/app/courses/topics/:courseID?/:chapterID?" exact={true} component={CourseTopics} />
          <Route path="/app/courses/topic/:topicID" exact={true} component={TopicDetail} />
          <Route path="/app/courses/topic/:topicID/:questionID" exact={true} component={QuestionDetail} />
          <Route path="/app/batch/schedule/:classID?" exact={true} component={BatchSchedule} />

          <Route path="/app/submissions/student/:studentID?" exact={true} component={StudentSubmissions} />
          <Route path="/app/submissions/topic/:topicID?" exact={true} component={TopicSubmissions} />
          <Route path="/app/submission/:submissionID" exact={true} component={SubmissionDetail} />
          <Route path="/app/assessment/:evaluationID" exact={true} component={AssessmentDetail} />
          <Route path="/app/leaderboards" exact={true} component={Leaderboards} />

          <Route path="/app/timeslots" exact={true} component={TimeSlots} />
          <Route path="/app/timeslots/add" exact={true} component={AddSlot} />
          <Route path="/app/timeslot/:timeslotID" exact={true} component={SlotDetail} />
          <Route path="/app/timetables" exact={true} component={TimeTables} />
          <Route path="/app/timetables/day" exact={true} component={DayTimeTable} />
          <Route path="/app/timetables/teacher" exact={true} component={TeacherTimeTable} />
          <Route path="/app/timetables/add/class/:classID" exact={true} component={AddTimeTableOne} />
          <Route path="/app/timetables/add" exact={true} component={AddTimeTableOne} />
          <Route path="/app/timetables/add/subjects" exact={true} component={AddTimeTableTwo} />
          <Route path="/app/timetable/:timetableID" exact={true} component={ClassTimeTable} />
          <Route path="/app/timetable/update/:timetableID" exact={true} component={UpdateTimeTableOne} />
          <Route path="/app/timetable/update/:timetableID/subjects" exact={true} component={UpdateTimeTableTwo} />
          <Route path="/app/substitutes" exact={true} component={Substitutes} />
          <Route path="/app/substitutes/history" exact={true} component={SubHistory} />

          <Route path="/app/circulars/:classID?" exact={true} component={Circulars} />
          <Route path="/app/circular/add" exact={true} component={AddCircular} />
          <Route path="/app/circular/:circularID" exact={true} component={CircularDetail} />
          <Route path="/app/inquiries/new" exact={true} component={AddInquiry} />
          <Route path="/app/inquiries/pending" exact={true} component={PendingInquiries} />
          <Route path="/app/inquiries/processed" exact={true} component={ProcessedInquiries} />
          <Route path="/app/inquiries/student/:studentID?" exact={true} component={StudentInquiries} />
          <Route path="/app/inquiry/:inquiryID" exact={true} component={InquiryDetail} />

          <Route path="/app/config/institute/location" exact={true} component={InstituteLocation} />
          <Route path="/app/routes" exact={true} component={Routes} />
          <Route path="/app/routes/add" exact={true} component={AddRoute} />
          <Route path="/app/route/:routeID" exact={true} component={RouteDetail} />
          <Route path="/app/mapstudents/:routeID/:studentID" exact={true} component={RouteStudent} />
          <Route path="/app/trips/completed" exact={true} component={CompletedTrips} />
          <Route path="/app/trip/detail/:tripID" exact={true} component={CompletedTrip} />
          <Route path="/app/tracking" exact={true} component={OngoingTrips} />
          <Route path="/app/trip/ongoing/:tripID" exact={true} component={OngoingTrip} />
          <Route path="/app/trips/reports" exact={true} component={TripsReports} />
          <Route path="/app/trips/alerts" exact={true} component={TripsAlerts} />
          <Route path="/app/vehicles" exact={true} component={Vehicles} />
          <Route path="/app/vehicles/add" exact={true} component={AddVehicle} />
          <Route path="/app/vehicle/:vehicleID" exact={true} component={VehicleDetail} />
          <Route path="/app/vehicle/expenses/:vehicleID" exact={true} component={VehicleExpenses} />
       
          <Route path="/app/transactions" exact={true} component={Transactions} />
          <Route path="/app/reports" exact={true} component={Reports} />

          <Route path="/app/dues/add/:studentID?" exact={true} component={AddDues} />
          <Route path="/app/dues/addMany" exact={true} component={AddManyDues} />
          <Route path="/app/invoices/scheduled" exact={true} component={ScheduledInvoices} />   
          <Route path="/app/invoices/uncleared" exact={true} component={UnclearedInvoices} />   
          <Route path="/app/invoices/cleared" exact={true} component={ClearedInvoices} />
          <Route path="/app/invoices/student/:studentID?" exact={true} component={StudentInvoices} />
          <Route path="/app/invoice/:invoiceID" exact={true} component={InvoiceDetail} />

          <Route path="/app/dues/view" exact={true} component={ViewDues} />   
          <Route path="/app/dues/reports" exact={true} component={DuesReports} />   
          <Route path="/app/collection/:studentID?" exact={true} component={CollectFee} />
          <Route path="/app/concession/give/:studentID?" exact={true} component={GiveConcession} />
          <Route path="/app/latefees/charge/:studentID?" exact={true} component={ChargeLateFees} />
          <Route path="/app/collections/receipts" exact={true} component={CollectReceipts} />   
          <Route path="/app/collections/reports" exact={true} component={CollectReports} />   
          <Route path="/app/concessions" exact={true} component={Concessions} />
          <Route path="/app/receipt/:receiptID" exact={true} component={ReceiptDetail} />
          
          <Route path="/app/transactions/transport/:vehicleID?" exact={true} component={TransportExpense} />
          <Route path="/app/expense/reports" exact={true} component={ExpenseReports} />  
          <Route path="/app/bills/list/:tag?" exact={true} component={Bills} />
          <Route path="/app/bills/add/:uri?" exact={true} component={AddBill} />
          <Route path="/app/bill/:billID" exact={true} component={BillDetail} />
          
          <Route path="/app/fees" exact={true} component={Fees} />
          <Route path="/app/fees/add" exact={true} component={AddFee} />
          <Route path="/app/fees/student/:studentID?" exact={true} component={StudentFee} />
          <Route path="/app/fees/class/:classID?" exact={true} component={ClassFee} />
          <Route path="/app/fee/:feeID" exact={true} component={FeeDetail} />
          <Route path="/app/feeheads" exact={true} component={FeeHeads} />
          <Route path="/app/feeheads/add" exact={true} component={AddFeeHead} />
          <Route path="/app/feehead/:feeHeadID" exact={true} component={FeeHeadDetail} />

          <Route path="/app/inventory/stores/add" exact={true} component={AddStore} />
          <Route path="/app/inventory/stores" exact={true} component={Stores} />
          <Route path="/app/store/:storeID" exact={true} component={StoreDetail} />
          <Route path="/app/inventory/item/add/:store?" exact={true} component={AddItem} />
          <Route path="/app/inventory/items/:storeID?" exact={true} component={Items} />
          <Route path="/app/item/:itemID" exact={true} component={ItemDetail} />
          <Route path="/app/item/ledger/:itemID" exact={true} component={ItemLedger} />
          <Route path="/app/inventory/combos" exact={true} component={CombinedItems} />
          <Route path="/app/inventory/combos/add" exact={true} component={AddCombinedItem} />   
          <Route path="/app/combo/:comboID" exact={true} component={CombinedItemDetails} />
          <Route path="/app/inventory/reports" exact={true} component={ItemReports} />
          <Route path="/app/vendors/add" exact={true} component={AddVendor} />
          <Route path="/app/vendors" exact={true} component={Vendors} />
          <Route path="/app/vendor/:vendorID" exact={true} component={VendorDetail} />
          <Route path="/app/vendor/ledger/:vendorID" exact={true} component={VendorLedger} />
          <Route path="/app/vendors/payment/:vendorID?" exact={true} component={VendorPayment} />
          <Route path="/app/vendors/reports" exact={true} component={VendorReports} />   
          <Route path="/app/inventory/transact/buy/:itemID?" exact={true} component={BuyItem} />
          <Route path="/app/inventory/transact/sell/:itemID?" exact={true} component={SellItem} />
          <Route path="/app/inventory/combos/sell/:comboID?" exact={true} component={SellCombinedItem} />
          <Route path="/app/inventory/transactions" exact={true} component={ItemTransactions} />   

          <Route path="/app/admission/inquiries/new/:mode?" exact={true} component={NewInquiry} />
          <Route path="/app/admission/inquiries/list" exact={true} component={Inquiries} />
          <Route path="/app/admission/inquiries/board" exact={true} component={InquiriesBoard} />
          <Route path="/app/admission/inquiry/:inquiryID" exact={true} component={Inquiry} />
          <Route path="/app/admission/inquiry/:inquiryID/details" exact={true} component={EnquiryDetail} />
          <Route path="/app/admission/inquiry/:inquiryID/chat" exact={true} component={InquiryChat} />
          <Route path="/app/admission/inquiries/reports" exact={true} component={InquiryReports} />

          <Route path="/app/admission/school/stats" exact={true} component={KeyStats} />
          <Route path="/app/admission/school/facilities" exact={true} component={Facilities} />
          <Route path="/app/admission/school/gallery" exact={true} component={Gallery} />
          <Route path="/app/admission/class" exact={true} component={ClassSetup} />
          <Route path="/app/admission/course" exact={true} component={NewCourse} />
          <Route path="/app/admission/course/:courseID" exact={true} component={UpdateCourse} />
          <Route path="/app/admission/form" exact={true} component={FormSetup} />   

          <Route path="/app/admission/applications/board" exact={true} component={ApplicationsBoard} />
          <Route path="/app/admission/applications" exact={true} component={Applications} />
          <Route path="/app/admission/applications/history" exact={true} component={ApplicationsHistory} />
          <Route path="/app/application/:applicationID" exact={true} component={ApplicationDetail} />
          <Route path="/app/admission/appointments" exact={true} component={Appointments} />
          <Route path="/app/admission/appointments/history" exact={true} component={AppointmentsHistory} />
          <Route path="/app/admission/list" exact={true} component={AdmissionList} />
          <Route path="/app/admission/reports" exact={true} component={AdmissionReports} />

          <Route path="/app/whatsapp/medias/add" exact={true} component={AddMedia} />
          <Route path="/app/whatsapp/medias" exact={true} component={Medias} />
          <Route path="/app/whatsapp/media/:mediaID" exact={true} component={MediaDetail} />
          <Route path="/app/whatsapp/templates/add" exact={true} component={AddTemplate} />
          <Route path="/app/whatsapp/templates" exact={true} component={Templates} />
          <Route path="/app/whatsapp/template/:templateID" exact={true} component={TemplateDetail} />
          <Route path="/app/whatsapp/campaigns" exact={true} component={Campaigns} />
          <Route path="/app/whatsapp/campaigns/start" exact={true} component={StartCampaign} />
          <Route path="/app/whatsapp/campaign/:campaignID" exact={true} component={CampaignReports} />
          <Route path="/app/whatsapp/messages" exact={true} component={Messages} />
          <Route path="/app/whatsapp/messages/new" exact={true} component={NewMessage} />
          <Route path="/app/whatsapp/message/:messageID" exact={true} component={MessageDetail} />

          <Route path="/app/user/tasks" exact={true} component={MyTasks} />
          <Route path="/app/user/task/:chatID" exact={true} component={ViewChat} />
          <Route path="/app/user/reports" exact={true} component={DailyReports} />
          <Route path="/app/user/chats" exact={true} component={MyChats} />
          <Route path="/app/user/chat" exact={true} component={AddChat} />
          <Route path="/app/user/chat/:chatID" exact={true} component={ViewChat} />

          <Route path="/app/user/attendance" exact={true} component={MyAttendance} />
          <Route path="/app/user/leaves" exact={true} component={MyLeaves} />
          <Route path="/app/user/leaves/add" exact={true} component={AddLeave} />
          <Route path="/app/user/notification" exact={true} component={UserNotifications} />
          <Route path="/app/user/profile" exact={true} component={Profile} />
          <Route path="/app/user/profile/personal" exact={true} component={UpdatePersonal} />
          <Route path="/app/user/profile/password" exact={true} component={UpdatePassword} />
          <Route path="/app/policies/terms" exact={true} component={Terms} />
          <Route path="/app/policies/privacy" exact={true} component={Privacy} />
          <Route path="/app/support/contact" exact={true} component={ContactUs} />
          <Route path="/app/support/delete" exact={true} component={DeleteAccount} />

          <Redirect exact={true} path="/app" to={`/app/home`} />
          <Redirect from="*" to="/app"/>

        </Switch>
      </IonRouterOutlet>
      <IonTabBar slot="bottom" className="tab-bar">
        <IonTabButton tab="home" href="/app/home" className="tab-button" target="_blank">
          <IonIcon icon={home} aria-hidden="true" className="tab-icon" />
          <IonLabel className="tab-label">{t("home")}</IonLabel>
        </IonTabButton>

        {(authInfo.institute.task) ? (
          <IonTabButton tab="reports" href="/app/user/reports" className="tab-button" target="_blank">
            <IonIcon icon={clipboard} aria-hidden="true" className="tab-icon" />
            <IonLabel className="tab-label">{t("daily_reports")}</IonLabel>
          </IonTabButton>
        ) : ( 
          <IonTabButton tab="news" href="/app/news" className="tab-button" target="_blank">
            <IonIcon icon={newspaper} aria-hidden="true" className="tab-icon" />
            <IonLabel className="tab-label">{t("circulars")}</IonLabel>
          </IonTabButton>
        )}
        {((authInfo.user.roles.includes("Academic")) || (authInfo.user.roles.includes("Teacher"))) ? (
          <IonTabButton tab="chat" href="/app/inquiries/pending" className="tab-button" target="_blank">
            <IonIcon icon={chatbubbleEllipses} aria-hidden="true" className="tab-icon" />
            <IonLabel className="tab-label">{t("parent_inquiries")}</IonLabel>
          </IonTabButton>
        ) : (
          <IonTabButton tab="chat" href="/app/user/chats" className="tab-button" target="_blank">
            <IonIcon icon={chatbubbleEllipses} aria-hidden="true" className="tab-icon" />
            <IonLabel className="tab-label">{t("my_chats")}</IonLabel>
          </IonTabButton>
        )}
        <IonTabButton tab="attendance" href="/app/user/attendance" className="tab-button" target="_blank">
          <IonIcon icon={calendarNumber} aria-hidden="true" className="tab-icon" />
          <IonLabel className="tab-label">{t("my_attendance")}</IonLabel>
        </IonTabButton>
      </IonTabBar>
    </IonTabs>
  );
};

export default EmployeeTabs;
