import {
    IonAlert,
    IonBackButton,
    IonBadge,
    IonButton,
    IonButtons,
    IonCard,
    IonCardContent,
    IonCardHeader,
    IonCardSubtitle,
    IonCol,
    IonContent,
    IonGrid,
    IonHeader,
    IonIcon,
    IonInput,
    IonItem,
    IonLabel,
    IonList,
    IonLoading,
    IonMenuButton,
    IonModal,
    IonPage,
    IonPopover,
    IonRefresher,
    IonRefresherContent,
    IonRow,
    IonSelect,
    IonSelectOption,
    IonText,
    IonTextarea,
    IonTitle,
    IonToolbar,
    isPlatform,
    RefresherEventDetail
} from '@ionic/react';
import { alertCircleOutline, analytics, busOutline, camera, card, cash, chatboxEllipses, chevronForwardOutline, close, construct, informationCircle, newspaper, receipt, trash } from 'ionicons/icons';
import axios from 'axios';
import { Capacitor } from '@capacitor/core';
import { FilePicker } from '@capawesome/capacitor-file-picker';
import React, { useEffect, useState } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import QRCode from 'react-qr-code';
import { useTranslation } from "react-i18next";
import { useAuth } from '../../../components/AuthContext';
import ThemeToggle from '../../../components/ThemeToggle';
import LanguageToggle from '../../../components/LanguageToggle';

interface ParamTypes {
    studentID: string
}

interface Inquiry {
    _id: string,
    title: string,
    type: string,
    status: string,
    createdAt: Date
}

interface Trip {
    _id: string,
    name: string,
    type: string,
    scheduledAt: Date,
    finishedAt: Date,
    startedAt: Date
}

interface Route {
    _id: string,
    name: string,
    type: string
}

interface Due {
    _id: string,
    remarks: string,
    amount: number,
    dueDate: Date,
    createdAt: Date
}

interface Transaction {
    _id: string,
    mode: string,
    amount: number,
    remarks: string,
    dated: Date
}

interface Tran {
    _id: string,
    amount: number,
    invoiceDate: Date,
    dueDate: Date,
    dueComponents: Component[],
    paidAt?: Date
}
interface Component {
    amount: number,
    head?: FeeHead,
    frequency?: string,
    duration?: string,
    remarks?: string,
    isChecked?: boolean
}
interface FeeHead {
    headID: string,
    name: string
}

interface Session {
    _id: string,
    name: string,
    isActive: boolean
}

interface ClassInterface {
    _id: string,
    name: string,
    year: string,
    isActive: boolean
}

interface Parent {
    _id: string,
    name: string,
    isActive: boolean
}

interface ClassHistory {
    timestamp: Date,
    adminInfo: AdminInterface,
    classInfo: Update
}

interface AdminInterface {
    adminID: string,
    adminName: string
}
interface Update {
    classID: string,
    className: string,
    sessionID: string,
    sessionName: string
}

const StudentDetail: React.FC = () => {
    const {authInfo} = useAuth()!;
    const history = useHistory();
    const {t} = useTranslation();
    const {studentID} = useParams < ParamTypes > ();
    const [showLoading, setShowLoading] = useState < boolean > (false);
    const [showAlert, setShowAlert] = useState < boolean > (false);
    const [showAlert1, setShowAlert1] = useState < boolean > (false);
    const [iserror, setIserror] = useState < boolean > (false);
    const [message, setMessage] = useState < string > ("");
    const [ispass, setIspass] = useState < boolean > (false);
    const [pass, setPass] = useState < string > ("");
    const [name, setName] = useState < string > ("");
    const [instituteID, setInstituteID] = useState < string > ("");
    const [classID, setClassID] = useState < string > ("");
    const [className, setClassName] = useState < string > ("");
    const [classSession, setClassSession] = useState < string > ("");
    const [parentID, setParentID] = useState < string > ("");
    const [due, setDue] = useState < number > (0);
    const [transactions, setTransactions] = useState < Transaction[] > ([]);
    const [trans, setTrans] = useState < Tran[] > ([]);
    const [inquiries, setInquiries] = useState < Inquiry[] > ([]);
    const [inRoutes, setInRoutes] = useState < Route[] > ([]);
    const [outRoutes, setOutRoutes] = useState < Route[] > ([]);
    const [selectedRoute, setSelectedRoute] = useState < string > ("");
    const [completedTrips, setCompletedTrips] = useState < Trip[] > ([]);
    const [fileName, setFileName] = useState < string > ("");
    const [uploadFile, setUploadFile] = useState < File | null > (null);
    const [note, setNote] = useState < string > ("");
    const [title, setTitle] = useState < string > ("");
    const [refreshed, setRefreshed] = useState < number > (0);
    const [showModal, setShowModal] = useState < boolean > (false);
    const [transport, setTransport] = useState < boolean > (false);
    const [comm, setComm] = useState < boolean > (false);
    const [payment, setPayment] = useState < boolean > (false);
    const [sessions, setSessions] = useState < Session[] > ([]);
    const [classes, setClasses] = useState < ClassInterface[] > ([]);
    const [selectedSession, setSelectedSession] = useState < string > ("");
    const [selectedClass, setSelectedClass] = useState < string > ("");
    const [pop, setPop] = useState < boolean > (false);
    const [pop1, setPop1] = useState < boolean > (false);
    const [newPass, setNewPass] = useState < string > ("");
    const [email, setEmail] = useState < string > ("");
    const [phone, setPhone] = useState < string > ("");
    const [createdAt, setCreatedAt] = useState < Date > ();
    const [parent, setParent] = useState < Parent > ();
    const [showAlert2, setShowAlert2] = useState < boolean > (false);
    const [imageURL, setImageURL] = useState < string > ("");
    const [pop2, setPop2] = useState < boolean > (false);
    const [isOpen, setIsOpen] = useState < boolean > (false);
    const [classHistory, setClassHistory] = useState < ClassHistory[] > ([]);

    const api = axios.create({
        baseURL: process.env.REACT_APP_API_URL,
        timeout: parseInt(process.env.REACT_APP_API_TO!),
        headers: {
            'Authorization': 'Bearer ' + authInfo.token
        }
    });

    const doRefresh = (event: CustomEvent<RefresherEventDetail>) => {
        setRefreshed(Math.random());
        event.detail.complete();
    };
  
    const formatDateTime = (value: Date) => {
        const valueDate = new Date(value);
        return valueDate.toLocaleDateString('en-GB') + ' at ' + valueDate.toLocaleTimeString('en-US');
    };

    const formatDated = (value: Date) => {
        const valueDate = new Date(value);
        return valueDate.toLocaleDateString('en-GB');
    };

    const formatDate = (value: Date) => {
        const valueDate = new Date(value);
        return valueDate.toLocaleDateString('en-GB', { day: 'numeric', month: 'numeric', year: '2-digit' });
    };

    const handleNotify = () => {
  
        const notifyStudent = () => {
          return new Promise((resolve, reject) => {
              api.post(`/students/notification`, { studentID, 'title': 'Payment Due', 'text': `Rs. ${due} is overdue. Kindly clear your dues now.`, 'type': 'payment' }).then(res => {
                  return resolve(res.data); 
                }).catch(err => reject(err));
          });
        }
  
        setShowLoading(true);
        notifyStudent()
        .then(data => {
          setPass(t("parent_reminded"));
          setIspass(true);
        })
        .catch((error) => {
            setMessage(error.response?.data.message || t("something_went_wrong"));
            setIserror(true);
        })
        .finally(() => setShowLoading(false));
        
    }

    const handleMessage = () => {

        if (!note || note === "") {
            setMessage(t("notif_message_mandatory"));
            setIserror(true);
            return;
        }

        if (!title || title === "") {
            setMessage(t("notif_title_mandatory"));
            setIserror(true);
            return;
        }
  
        const notifyStudent = () => {
          return new Promise((resolve, reject) => {
              api.post(`/students/notification`, { studentID, 'title': title, 'text': note, 'type': 'general' }).then(res => {
                  return resolve(res.data);
                }).catch(err => reject(err));
          });
        }
  
        setShowLoading(true);
        notifyStudent()
        .then(data => {
          setPass(t("notif_sent"));
          setIspass(true);
        })
        .catch((error) => {
            setMessage(error.response?.data.message || t("something_went_wrong"));
            setIserror(true);
        })
        .finally(() => setShowLoading(false));
        
    }

    const convertFileSrc = (path: string) => {
        return Capacitor.convertFileSrc(path);
    };
      
    const fileToDataUri = (file: File) => new Promise<string>((resolve, reject) => {
        const reader = new FileReader();
        reader.onload = (event: any) => {
            resolve(event.target.result);
        };
        reader.readAsDataURL(file);
    });

    const pickImage = async () => {
         try {

            const result = await FilePicker.pickImages({limit: 1});

            if (result.files.length === 1)
            {
                const file = result.files[0];
                //for native
                if (file && file.path && file.name && file.mimeType) {
                    const webPath = convertFileSrc(file.path);
                    if ((file.mimeType === 'image/jpeg') || (file.mimeType === 'image/png'))
                    {
                        setImageURL(webPath);
                    }
                    else
                    {
                        setImageURL("");
                    }
                    const blob = await fetch(webPath).then((r) => r.blob());
                    const rawFile = new File([blob], file.name, {
                        type: file.mimeType,
                    });
                    setUploadFile(rawFile);
                }
                //for web
                else if (file && file.blob && file.name && file.mimeType) {
                    const rawFile = new File([file.blob], file.name, {
                        type: file.mimeType,
                    });
                    setUploadFile(rawFile);
                    if ((file.mimeType === 'image/jpeg') || (file.mimeType === 'image/png'))
                    {
                        const base64 = await fileToDataUri(rawFile);
                        setImageURL(base64);
                    }
                    else
                    {
                        setImageURL("");
                    }
                }
            }
            
        } 
        catch(error: any) {
 
            setMessage(t("no_files_selected"));  
            setIserror(true);
        }
    };

    const handlePhoto = () => {

        if (!uploadFile) {
            setMessage(t("logo_format"));
            setIserror(true);
            return;
        }

        const upStud = ( formData: FormData) => {
            return new Promise((resolve, reject) => {
              api.post('/students/updatePhoto', formData).then(res => {
                return resolve(res.data.student.name);
              }).catch(err => reject(err));
    
            });
        }

        let formData = new FormData();
        formData.append('image', uploadFile, uploadFile.name);
        formData.append('studentID', studentID);
  
        setShowLoading(true);
        upStud(formData)
        .then(data => {
        
            setPass(data+t("has_been_updated"));
            setIspass(true);
        })
        .catch((error) => {
            setMessage(error.response?.data.message || t("something_went_wrong"));
            setIserror(true);
        })
        .finally(() => setShowLoading(false));
        
    }

    const withdrawStudent = () => {

        const withdraw = () => {
            return new Promise((resolve, reject) => {
                api.post(`/students/withdraw/${studentID}`, {adminID: authInfo.user._id, adminName: authInfo.user.name}).then(res => {
                    return resolve(res.data.student.name);
                  }).catch(err => reject(err));
            });
        }
    
        setShowLoading(true);
        withdraw()
        .then(data => {
            setPass(data+t("has_been_withdrawn"));
            setIspass(true);
        })
        .catch((error) => {
            setMessage(error.response?.data.message || t("something_went_wrong"));
            setIserror(true);
        })
        .finally(() => setShowLoading(false));
    }

    const graduateStudent = () => {

        const withdraw = () => {
            return new Promise((resolve, reject) => {
                api.post(`/students/graduate/${studentID}`, {adminID: authInfo.user._id, adminName: authInfo.user.name}).then(res => {
                    return resolve(res.data.student.name);
                  }).catch(err => reject(err));
            });
        }
    
        setShowLoading(true);
        withdraw()
          .then(data => {
            setPass(data+t("has_been_graduated"));
            setIspass(true);
        })
        .catch((error) => {
            setMessage(error.response?.data.message || t("something_went_wrong"));
            setIserror(true);
        })
        .finally(() => setShowLoading(false));
    }

    const readmitStudent = () => {

        if (!selectedSession || selectedSession === "") {
            setMessage(t("select_session_first"));
            setIserror(true);
            return;
        }

        if (!selectedClass || selectedClass === "") {
            setMessage((authInfo.institute.type === "school") ? t("class_mandatory") : t("batch_mandatory"));
            setIserror(true);
            return;
        }

        const unwithdraw = () => {
            return new Promise((resolve, reject) => {
                api.post(`/students/unwithdraw/${studentID}/${selectedClass}`, {adminID: authInfo.user._id, adminName: authInfo.user.name}).then(res => {
                    return resolve(res.data.student.name);
                  }).catch(err => reject(err));
            });
          }
    
        setShowLoading(true);
        unwithdraw()
        .then(data => {
            setPass(data+t("has_been_readmitted"));
            setIspass(true);
        })
        .catch((error) => {
            setMessage(error.response?.data.message || t("something_went_wrong"));
            setIserror(true);
        })
        .finally(() => {
            setPop(false);
            setShowLoading(false)
        });
    }

    const switchStudentClass = () => {

        if (!selectedSession || selectedSession === "") {
            setMessage(t("select_session_first"));
            setIserror(true);
            return;
        }

        if (!selectedClass || selectedClass === "") {
            setMessage((authInfo.institute.type === "school") ? t("class_mandatory") : t("batch_mandatory"));
            setIserror(true);
            return;
        }

        const unwithdraw = () => {
            return new Promise((resolve, reject) => {
                api.post(`/students/switchClass/${studentID}/${selectedClass}`, {adminID: authInfo.user._id, adminName: authInfo.user.name}).then(res => {
                    return resolve(res.data.student.name);
                  }).catch(err => reject(err));
            });
        }
    
        setShowLoading(true);
        unwithdraw()
        .then(data => {
            setPass(data+t("has_been_moved"));
            setIspass(true);
        })
        .catch((error) => {
            setMessage(error.response?.data.message || t("something_went_wrong"));
            setIserror(true);
        })
        .finally(() => {
            setPop2(false);
            setShowLoading(false)
        });
    }

    
    const handleSession = (sessionID : string) => {

        const fetchUp = async () => {
            
            setShowLoading(true);
            try {
                const result = await api.get(`/classes/allInstitute/${instituteID}/${sessionID}`);
                setClasses(result.data);

            } catch (error: any) {
                setMessage(error.response?.data.message || t("something_went_wrong"));
                setIserror(true);
            } finally {
                setShowLoading(false);
            }
            
        };

        fetchUp();
        setSelectedSession(sessionID);
        setSelectedClass("");
    }

    const validateEmail = (email : string) => { 
        // eslint-disable-next-line no-control-regex
        const re = /^((?:[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*|"(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21\x23-\x5b\x5d-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])*")@(?:(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?|\[(?:(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.){3}(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?|[a-z0-9-]*[a-z0-9]:(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21-\x5a\x53-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])+)\]))$/;
        return re.test(String(email).toLowerCase());
    };

    const validatePhone = (phone : string) => { 
        // eslint-disable-next-line no-control-regex
        const re = /^[0-9]{10}$/;
        return re.test(phone);
    };

    const createAccount = () => {

        if (!newPass || newPass.length < 8) {
            setMessage(t("invalid_password"));
            setIserror(true);
            setShowLoading(false);
            return;
        }

        if (validateEmail(email) === false) {
            setMessage(t("invalid_email"));
            setIserror(true);
            return;
        }

        if (validatePhone(phone) === false) {
            setMessage(t("invalid_mobile"));
            setIserror(true);
            return;
        }

        const unwithdraw = () => {
            return new Promise((resolve, reject) => {
                api.post(`/students/registerParent`, {studentID, newPass, email, phone}).then(res => {
                    return resolve(res.data);
                  }).catch(err => reject(err));
            });
        }
    
        setShowLoading(true);
        unwithdraw()
        .then((data: any) => {

            if (data.student)
            {
                setPass(data.student.name+t("account_created"));
                setIspass(true);
            }
            if (data.parent)
            {
                setParent(data.parent);
                setShowAlert2(true);
            }
            
        })
        .catch((error) => {
            setMessage(error.response?.data.message || t("something_went_wrong"));
            setIserror(true);
        })
        .finally(() => {
            setPop1(false);
            setShowLoading(false)
        });
    }

    const handleSecondary = () => {
    
        const addStudent = ( parentID: string ) => {
            return new Promise((resolve, reject) => {
                api.get(`/students/registerSibling/${studentID}/${parentID}`).then(res => {
                    return resolve(res.data.student.name);
                }).catch(err => reject(err));
            });
        }
       
        setShowLoading(true);

        addStudent(parent!._id)
        .then((data) => {
    
            setPass(data+t("account_created"));
            setIspass(true);
            
        })
        .catch((error) => {
            setMessage(error.response?.data.message || t("something_went_wrong"));
            setIserror(true);
        })
        .finally(() => setShowLoading(false));
    }
    
    useEffect(() => {

        const fetchData = async () => {
            setShowLoading(true);
            try {

                setComm(authInfo.institute.communication);
                setPayment(authInfo.institute.payment);
                setTransport(authInfo.institute.transport);

                const result = await api.get('/students/' + studentID);
                setName(result.data.name);
                setInstituteID(result.data.instituteID);
                setClassID(result.data.classID);
                setClassName(result.data.classInfo.className);
                setClassSession(result.data.classInfo.session);
                setDue(result.data.due);
                setFileName(result.data.photo);
                setParentID(result.data.parentID);
                setCreatedAt(result.data.createdAt);

                setClassHistory(result.data.classHistory || []);

                if(result.data.parentID === "none")
                {
                    setEmail(result.data.parentInfo.email);
                    setPhone(result.data.parentInfo.phone);
                }

                if (authInfo.institute.transport && (authInfo.user.role === "Super" || authInfo.user.role === "Admin" || authInfo.user.role === "Transporter" || (authInfo.user.roles.includes("Transporter"))))
                {

                    const inRoutes = await api.get(`/routes/student/in/${studentID}`);
                    setInRoutes(inRoutes.data);
                    const outRoutes = await api.get(`/routes/student/out/${studentID}`);
                    setOutRoutes(outRoutes.data);
                    const completed = await api.get(`/trips/student/completed/recent/${studentID}`);
                    setCompletedTrips(completed.data);

                }

                if (authInfo.institute.payment && (authInfo.user.role === "Admin" || authInfo.user.role === "Super" || authInfo.user.role === "Accountant" || (authInfo.user.roles.includes("Accountant"))))
                {

                    //const dues = await api.get(`/dues/pending/${studentID}`);
                    //setTrans(dues.data);

                    const inv = await api.get(`/invoices/getFiveByStudent/${studentID}`);
                    setTrans(inv.data);

                    const transact = await api.get(`/transactions/getFiveByStudent/${studentID}`);
                    setTransactions(transact.data);

                }

                if (authInfo.user.role === "Admin" || authInfo.user.role === "Super" || authInfo.user.role === "Teacher" || authInfo.user.role === "Academic" || (authInfo.user.roles.includes("Academic")))
                {
                    const apps = await api.get(`/inquiries/getFiveByStudent/${studentID}`);
                    setInquiries(apps.data);
                }

                
                const resu = await api.get(`/sessions/getAllInstitute/${result.data.instituteID}`);
                setSessions(resu.data);

            } catch (error: any) {
                setMessage(error.response?.data.message || t("something_went_wrong"));
                setIserror(true);
            } finally {
                setShowLoading(false);
            }
        };

        fetchData();

    }, [authInfo, studentID, refreshed]);
    
    return (
        <IonPage>

            <IonHeader>
                <IonToolbar className="white-shade">
                    <IonButtons slot="start">
                        <IonMenuButton color="secondary"/>
                        <IonBackButton color="secondary" defaultHref="/app"/>
                    </IonButtons>
                    <IonTitle size={isPlatform('mobile') ? "small" : undefined} color="secondary">{t("manage_student")}</IonTitle>
                    {!isPlatform('mobile') && (
                    <IonButtons slot="end">
                        <ThemeToggle />
                        <LanguageToggle />
                   </IonButtons>
                    )}
                </IonToolbar>
            </IonHeader>

            <IonContent fullscreen={true} className="grey-shade">

                <IonHeader collapse="condense">
                    <IonToolbar>
                        <IonTitle size="large" color="secondary">{t("manage_student")}</IonTitle>
                    </IonToolbar>
                </IonHeader>

                <IonLoading isOpen={showLoading}
                    cssClass="first-loading"
                    spinner="circular"
                    message={t("please_wait")}
                />

                <IonAlert isOpen={iserror}
                    cssClass="first-alert"
                    onDidDismiss={
                        () => setIserror(false)
                    }
                    header={t("error")}
                    message={message}
                    buttons={
                        [`${t("close")}`]
                    }
                />

                <IonAlert isOpen={ispass}
                    cssClass="first-alert"
                    onDidDismiss={
                        () => {
                            setIspass(false);
                            setRefreshed(Math.random());
                        }
                    }
                    header={t("success")}
                    message={pass}
                    buttons={
                        [`${t("close")}`]
                    }
                />

                <IonAlert
                    isOpen={showAlert}
                    cssClass="first-alert"
                    onDidDismiss={() => setShowAlert(false)}
                    header={t("confirm")}
                    message={t("confirm_withdraw")}
                    buttons={[
                        {
                        text: t("no"),
                        role: 'cancel',
                        cssClass: 'secondary',
                        },
                        {
                        text: t("yes"),
                        handler: () => {
                            withdrawStudent();
                        }
                        }
                    ]}
                />

                <IonAlert
                    isOpen={showAlert1}
                    cssClass="first-alert"
                    onDidDismiss={() => setShowAlert1(false)}
                    header={t("confirm")}
                    message={t("confirm_graduate")}
                    buttons={[
                        {
                        text: t("no"),
                        role: 'cancel',
                        cssClass: 'secondary',
                        },
                        {
                        text: t("yes"),
                        handler: () => {
                            graduateStudent();
                        }
                        }
                    ]}
                />

                <IonAlert
                    cssClass="first-alert"
                    isOpen={showAlert2}
                    onDidDismiss={() => setShowAlert2(false)}
                    header={t("sibling")}
                    message={t("sibling_alert_1")+parent?.name+t("sibling_alert_2")}
                    buttons={[
                        {
                            text: `${t("no")}`,
                            role: 'cancel',   
                            cssClass: 'secondary',
                        },
                        {
                            text: `${t("yes")}`,
                            handler: () => {
                                handleSecondary();
                            }
                        }
                    ]}
                />

                <IonRefresher slot="fixed" onIonRefresh={doRefresh}>
                    <IonRefresherContent pullingIcon={isPlatform('ios') ? "lines" : "circular"}></IonRefresherContent>
                </IonRefresher>

                <IonModal isOpen={showModal}
                    onDidDismiss={
                        () => setShowModal(false)
                }>

                    <IonContent className="grey-shade">
                        <IonHeader>
                            <IonToolbar>
                                <IonButtons slot="end">
                                    <IonButton onClick={
                                        () => setShowModal(false)
                                    }>
                                        <IonIcon icon={close} size="large" />
                                    </IonButton>
                                </IonButtons>
                                <IonTitle color="secondary">{t("qr_code")}</IonTitle>
                            </IonToolbar>
                        </IonHeader>

                        <IonCard className="mt-60 receipt-shade">
                            <IonCardContent className='mt-48'>

                                <IonRow>
                                    <IonCol className="ion-text-center">
                                        <QRCode value={`https://prathmic.com/app/qr/student/${studentID}`} />
                                    </IonCol>
                                </IonRow>
                                <IonRow>
                                    <IonCol className="ion-text-center mt-30 mb-30">
                                        <IonText className="profile-text">{name}</IonText><br/>
                                        {(className !== "") && (<IonText className="profile-text">{className} ({classSession})</IonText>)}
                                    </IonCol>
                                </IonRow> 

                            </IonCardContent>
                        </IonCard>
                       
                    </IonContent>
                </IonModal>

                <IonModal
                    isOpen={isOpen}
                    onDidDismiss={() => {
                        setIsOpen(false);
                    }}
                >
                <IonHeader>
                    <IonToolbar>
                        <IonTitle>{(authInfo.institute.type === "school") ? t("class_history") : t("batch_history")}</IonTitle>
                        <IonButtons slot="end">
                            <IonButton onClick={() => setIsOpen(false)}>
                                <IonIcon icon={close} />
                            </IonButton>
                        </IonButtons>
                    </IonToolbar>
                </IonHeader>
              
                <IonContent className="grey-shade">
                    <IonGrid className="ion-no-padding">
                        <IonRow className="mt-10">
                            <IonCol>
                                <IonCard>
                                    <IonCardContent className="ion-no-padding">
                                        <IonRow className="table-title ion-padding">
                                            <IonCol size="4" className='table-heading'>
                                                {t("moved_to")}
                                            </IonCol>
                                            <IonCol sizeLg="4" size="0" className='table-heading ion-hide-lg-down'>
                                                {t("updated_by")}
                                            </IonCol>
                                            <IonCol sizeLg="4" size="8" className='table-heading ion-text-center'>
                                                {t("updated_at")}
                                            </IonCol>
                                            
                                        </IonRow>
                                        {(classHistory.slice(0).reverse().map((update, i) => 
                                        (
                                        <IonRow key={i} className='row-table-alt ion-padding-horizontal'>
                                            
                                                <IonCol size="4" className='table-field text-capitalize' >
                                                    
                                                    {`${t(update.classInfo.className)} (${t(update.classInfo.sessionName)})`}
                                                        
                                                </IonCol>
                                                
                                                <IonCol sizeLg="4" size="0" className='table-field ion-hide-lg-down text-capitalize'>
                                                    
                                                    {t(update.adminInfo.adminName)}
                                                        
                                                    
                                                </IonCol>
                                                <IonCol sizeLg="4" size="8" className='table-field'>
                                                    
                                                    {formatDate(update.timestamp)}
                                                    <IonText className='ion-hide-lg-up'> {t("marked_by")} {t(update.adminInfo.adminName)}</IonText>
                                                </IonCol>
                                        </IonRow>
                                        )
                                        ))}
                                        </IonCardContent>
                                    </IonCard>
                                </IonCol>
                            </IonRow>
                        </IonGrid>
                    </IonContent>
                </IonModal>

                <IonGrid className="ion-no-padding mb-60">

                    <IonRow className="mt-60">

                        <IonCol size="6" sizeLg="3">
                            <IonRow>
                                <IonCol className="ion-text-center" id="open-upload-input">
                                    <div className="vertical-container">
                                        <div className="vertical-center"> 
                                            {fileName && (fileName !== "") ?
                                                (<img src={`${process.env.REACT_APP_STATIC_S3}/images/${fileName}`} alt={t("profile_pic")} width="70" />)
                                                : (<img src={`assets/images/avatar.png`} alt={t("profile_pic")} width="70" />)
                                            }
                                        </div>
                                    </div>
                                </IonCol>
                            </IonRow>
                            {((authInfo.user.role === 'Admin') || (authInfo.user.role === 'Teacher') || (authInfo.user.role === 'Academic') || (authInfo.user.role === 'Super')
                            || (authInfo.user.roles.includes("Academic")) || (authInfo.user.roles.includes("Teacher")) ) && ( 
                            <IonPopover trigger="open-upload-input" size="cover">
                                    <IonRow>
                                        <IonCol>
                                            <IonCard>
                                                <IonCardContent className="ion-no-padding">
                                                <IonItem detail={true}
                                                    lines='none'
                                                    button={true}
                                                    color="primary"
                                                    onClick={pickImage}
                                                    >
                                                    <IonIcon
                                                        slot="start"
                                                        icon={camera}
                                                    />
                                                    <IonLabel>
                                                     {(uploadFile) ? t("change_photo") : t("upload_photo")}
                                                    </IonLabel>
                                                    
                                                </IonItem>
                                                {(uploadFile) && (
                                                <IonItem detail={true}
                                                        detailIcon={trash}
                                                        lines='none'
                                                        button={true}
                                                        onClick={() => {
                                                            setUploadFile(null);
                                                            setImageURL("");
                                                        }}
                                                    >
                                                    <IonBadge color="danger" slot="start">1</IonBadge>
                                                    <IonLabel>
                                                        {t("remove_files")}
                                                    </IonLabel>
                                                    
                                                </IonItem>
                                                )}
                                                </IonCardContent>
                                            </IonCard>
                                        </IonCol>
                                    </IonRow>
                                    {imageURL && (
                                    <IonRow>
                                        <IonCol>
                                            <IonCard className="ion-text-center">
                                                <IonCardHeader>
                                                    <IonCardSubtitle>{t("image_preview")}</IonCardSubtitle>
                                                </IonCardHeader>
                                                <IonCardContent>
                                                
                                                    <img src={imageURL} 
                                                        alt={t("image_preview")}
                                                        width="70" />

                                                </IonCardContent>        
                                            </IonCard>
                                        </IonCol>
                                    </IonRow>
                                    )}
                                    <IonRow>
                                        <IonCol className="ion-text-center">
                                            <IonButton onClick={handlePhoto}
                                                size="small"
                                                className="first-button"
                                                fill="clear">{t("update_photo")}</IonButton>

                                        </IonCol>
                                    </IonRow>
                            </IonPopover>
                            )}
                            <IonRow className='mt-30 ml-30'>
                                <IonCol>
                                    <p><span className="profile-text">{name}</span><br/>
                                    {(className !== "") && (<><span className='pt-05'>{className} ({classSession})</span></>)}
                                    <span className='pt-05'>{t("added_on")} {createdAt && formatDated(createdAt)}</span>
                                    </p>

                                </IonCol>
                            </IonRow>
                          
                        </IonCol>

                       {/* Second Column: Buttons */}
                        <IonCol size="6" sizeLg="3">
                            <IonRow className="ion-margin-horizontal">

                            {((authInfo.user.role === 'Admin') || (authInfo.user.role === 'Academic') || (authInfo.user.role === 'Super') || (authInfo.user.roles.includes("Academic")))
                                && (<>

                                <IonCol>
                                    <IonButton onClick={() => history.push(`/app/student/profile/${studentID}`)}
                                    expand="block" className="first-button" fill="clear">{t("basic_info")}</IonButton>
                                </IonCol>

                                <IonCol>
                                    <IonButton onClick={() => (parentID !== "none") ? history.push(`/app/student/parent/${parentID}`) : setPop1(true)}
                                    expand="block" className="first-button" fill="clear">
                                    {(parentID !== "none") ? t("parent_info") : t("create_acc")}
                                    </IonButton>
                                </IonCol>
                                
                                <IonCol>
                                    <IonButton onClick={() => history.push(`/app/student/guardians/${studentID}`)}
                                    expand="block"  className="first-button" fill="clear">{t("guardian_info")}</IonButton>
                                </IonCol>
                               
                                <IonCol>
                                    <IonButton onClick={() => (classID !== "withdrawn" && classID !== "graduated") && setPop2(true)}
                                    expand="block" className="first-button" fill="clear">{(authInfo.institute.type === "school") ? t("switch_class") : t("switch_batch")}</IonButton>
                                </IonCol>
                                {(classID !== "graduated" && classID !== "withdrawn") && (
                                    <>
                                    <IonCol>
                                        <IonButton onClick={() => setShowAlert(true)} expand="block"  className="fifth-button" fill="clear">{t("withdraw_student")}</IonButton>
                                    </IonCol>
                                    <IonCol>
                                        <IonButton onClick={() => setShowAlert1(true)} expand="block"  className="forth-button" fill="clear">{t("graduate_student")}</IonButton>
                                    </IonCol>
                                    </>
                                )}
                                {(classID === "withdrawn" || classID === "graduated") && (
                                    <IonCol>
                                        <IonButton onClick={() => setPop(true)} expand="block"  className="first-button" fill="clear">{t("readmit")}</IonButton>
                                    </IonCol>
                                )}
                                
                               
                            </>)}

                            {((authInfo.user.role === 'Admin') || (authInfo.user.role === 'Teacher') || (authInfo.user.role === 'Academic') 
                                || (authInfo.user.role === 'Super') || (authInfo.user.roles.includes("Academic")) 
                                || (authInfo.user.roles.includes("Teacher"))) && 
                                (<>
                                    <IonCol>
                                        <IonButton onClick={() => history.push(`/app/student/attendance/${studentID}`)}
                                        expand="block"  className="first-button" fill="clear">{t("attendance")}</IonButton>
                                    </IonCol>
                                    {authInfo.institute.medical && (
                                        <IonCol>
                                            <IonButton onClick={() => history.push(`/app/student/incidents/${studentID}`)}
                                            expand="block"  className="first-button" fill="clear">{t("medical_records")}</IonButton>
                                        </IonCol>
                                    )}
                                    {!authInfo.institute.timetable && (authInfo.user.role === 'Teacher') && (
                                        <IonCol>
                                            <IonButton onClick={() => history.push(`/app/teacher/submissions/student/${studentID}`)}
                                            expand="block"  className="first-button" fill="clear">{t("view_submissions")}</IonButton>
                                        </IonCol>
                                    )}
                                    {!authInfo.institute.timetable && !(authInfo.user.role === 'Teacher') && (
                                        <IonCol>
                                            <IonButton onClick={() => history.push(`/app/submissions/student/${studentID}`)}
                                            expand="block"  className="first-button" fill="clear">{t("view_submissions")}</IonButton>
                                        </IonCol>
                                    )}
                                    <IonCol>
                                        <IonButton onClick={() => setIsOpen(true)} expand="block" className="first-button" fill="clear">{(authInfo.institute.type === "school") ? t("class_history") : t("batch_history")}</IonButton>
                                    </IonCol>
                                </>)}

                               
                            {((authInfo.user.role === 'Admin') || (authInfo.user.role === 'Transporter') || (authInfo.user.role === 'Super') || (authInfo.user.roles.includes("Transporter")))
                             && (<>
                                <IonCol>
                                    <IonButton onClick={() => history.push(`/app/student/location/${studentID}`)}
                                        expand="block" className="first-button" fill="clear">{t("location")}</IonButton>
                                </IonCol>
                            </>)}
                                <IonCol>
                                    <IonButton onClick={() => setShowModal(true)} expand="block" className="first-button" fill="clear">{t("qr_code")}</IonButton>
                                </IonCol>
                            
                            </IonRow>
                        </IonCol>

                        {/* Third Column: Send notification */}
                        {((authInfo.user.role === 'Admin') || (authInfo.user.role === 'Teacher') || (authInfo.user.role === 'Academic') 
                        || (authInfo.user.role === 'Super') || (authInfo.user.roles.includes("Academic")) || (authInfo.user.roles.includes("Teacher")) ) && comm && ( 
                        <IonCol size="12" sizeLg="6"> 
                            <IonCard>
                                <IonCardHeader>
                                    <IonCardSubtitle className='info-subtitle'>{t("notification")}</IonCardSubtitle>
                                </IonCardHeader>
                                <IonCardContent className="ion-no-padding ion-margin-bottom">
                                    <IonRow>
                                        <IonCol>
                                            <IonCard>
                                                <IonCardContent className="ion-no-padding">
                                                    <IonItem color="light">
                                                        
                                                        <IonLabel position="stacked" className="input-label" color="secondary">
                                                            {t("notif_title")}*
                                                        </IonLabel>
                                                            <IonInput type="text"
                                                                className="input-field"
                                                                placeholder={t("notif_title")}
                                                                value={title}
                                                                onIonInput={
                                                                    (e) => setTitle(e.detail.value!)
                                                            }></IonInput>
                                                    </IonItem>
                                                </IonCardContent>
                                            </IonCard>
                                        </IonCol>
                                    </IonRow>
                                    <IonRow>
                                        <IonCol>
                                            <IonCard>
                                                <IonCardContent className="ion-no-padding">
                                                    <IonItem color="light">
                                                        
                                                        <IonLabel position="stacked" className="input-label" color="secondary">
                                                            {t("notif_message")}*
                                                        </IonLabel>
                                                            <IonTextarea rows={1} value={note} className="input-field" placeholder={t("notif_message")}
                                                                onIonInput={(e) => setNote(e.detail.value!)}
                                                            />
                                                    </IonItem>
                                                </IonCardContent>
                                            </IonCard>
                                        </IonCol>
                                    </IonRow>
                                   
                                    <IonRow className="ion-margin-top">
                                        <IonCol className="ion-text-center">
                                            <IonButton onClick={() => handleMessage()}
                                                color='secondary'>{t("send_notif")}</IonButton>

                                        </IonCol>
                                    </IonRow>
                                          
                                </IonCardContent>
                            </IonCard>
                        </IonCol>
                        
                        )}
                    </IonRow>

                    <IonPopover isOpen={pop2} size='auto' onIonPopoverDidDismiss={() => setPop2(false)}>
                        <IonGrid className="ion-no-padding ion-no-margin">
                            <IonRow>
                                <IonCol className='ion-padding'>
                                    <IonCard>
                                        <IonCardContent className="ion-no-padding">
                                            <IonItem>
                                                <IonLabel position="stacked" className="input-label" color="secondary">
                                                {t("class_session")}</IonLabel>
                                                <IonSelect value={selectedSession}
                                                        cancelText={t("cancel")}
                                                        okText={t("ok")}
                                                        placeholder={t("class_session")}
                                                        onIonChange={
                                                            (e) => e.detail.value && handleSession(e.detail.value)
                                                    }>

                                                        {sessions.map((session) => 
                                                            (<IonSelectOption key={session._id} value={session._id}>{session.name}</IonSelectOption>)
                                                        )}

                                                </IonSelect>
                                            </IonItem>
                                            {selectedSession && (
                                                      <IonItem>
                                                        <IonLabel position="stacked" className="input-label" color="secondary">
                                                        {(authInfo.institute.type === "school") ? t("class") : t("batch")}</IonLabel>
                                                            <IonSelect value={selectedClass}
                                                                    cancelText={t("cancel")}
                                                                    okText={t("ok")}
                                                                    placeholder={(authInfo.institute.type === "school") ? t("select_class") : t("select_batch")}
                                                                    onIonChange={
                                                                        (e) => setSelectedClass(e.detail.value)
                                                                }>
                                                                        
                                                                    {classes.map((classy) => (
                                                                        (classy.isActive) &&
                                                                        (<IonSelectOption key={classy._id} value={`${classy._id}`}>{classy.name}</IonSelectOption>)
                                                                    ))}
                                                            </IonSelect>
                                                        </IonItem>
                                            )}

                                        
                                        </IonCardContent>
                                    </IonCard>
                                </IonCol>
                            </IonRow>
                            <IonRow>
                                <IonCol className="ion-padding-horizontal ion-padding-bottom">
                                    <IonButton onClick={switchStudentClass}
                                        expand="block"
                                        className="first-button"
                                        fill="clear" >{(authInfo.institute.type === "school") ? t("switch_class") : t("switch_batch")}</IonButton>

                                </IonCol>
                            </IonRow>
                        </IonGrid>
                    </IonPopover>

                    <IonPopover isOpen={pop} size='auto' onIonPopoverDidDismiss={() => setPop(false)}>
                        <IonGrid className="ion-no-padding ion-no-margin">
                            <IonRow>
                                <IonCol className='ion-padding'>
                                    <IonCard>
                                        <IonCardContent className="ion-no-padding">
                                            <IonItem>
                                                <IonLabel position="stacked" className="input-label" color="secondary">
                                                {t("class_session")}</IonLabel>
                                                <IonSelect value={selectedSession}
                                                        cancelText={t("cancel")}
                                                        okText={t("ok")}
                                                        placeholder={t("class_session")}
                                                        onIonChange={
                                                            (e) => e.detail.value && handleSession(e.detail.value)
                                                    }>

                                                        {sessions.map((session) => 
                                                            (<IonSelectOption key={session._id} value={session._id}>{session.name}</IonSelectOption>)
                                                        )}

                                                </IonSelect>
                                            </IonItem>
                                            {selectedSession && (
                                                      <IonItem>
                                                        <IonLabel position="stacked" className="input-label" color="secondary">
                                                        {(authInfo.institute.type === "school") ? t("class") : t("batch")}</IonLabel>
                                                            <IonSelect value={selectedClass}
                                                                    cancelText={t("cancel")}
                                                                    okText={t("ok")}
                                                                    placeholder={(authInfo.institute.type === "school") ? t("select_class") : t("select_batch")}
                                                                    onIonChange={
                                                                        (e) => setSelectedClass(e.detail.value)
                                                                }>
                                                                        
                                                                    {classes.map((classy) => (
                                                                        (classy.isActive) &&
                                                                        (<IonSelectOption key={classy._id} value={`${classy._id}`}>{classy.name}</IonSelectOption>)
                                                                    ))}
                                                            </IonSelect>
                                                        </IonItem>
                                            )}

                                        
                                        </IonCardContent>
                                    </IonCard>
                                </IonCol>
                            </IonRow>
                            <IonRow>
                                <IonCol className="ion-padding-horizontal ion-padding-bottom">
                                    <IonButton onClick={readmitStudent}
                                        expand="block"
                                        className="first-button"
                                        fill="clear" >{t("readmit")}</IonButton>

                                </IonCol>
                            </IonRow>
                        </IonGrid>
                    </IonPopover>

                    <IonPopover isOpen={pop1} size='auto' onIonPopoverDidDismiss={() => setPop1(false)}>
                        <IonGrid className="ion-no-padding ion-no-margin">
                            <IonRow>
                                <IonCol className='ion-padding'>
                                    <IonCard>
                                        <IonCardContent className="ion-no-padding">
                                            <IonItem>
                                                
                                                <IonLabel position="stacked" className="input-label" color="secondary">
                                                    {t("parent_phone")}*
                                                </IonLabel>
                                                    <IonInput type="text"
                                                        className="input-field"
                                                        placeholder={t("parent_phone")}
                                                        value={phone}
                                                        onIonInput={
                                                            (e) => setPhone(e.detail.value!)
                                                    }></IonInput>
                                            </IonItem>
                                            <IonItem>
                                                
                                                <IonLabel position="stacked" className="input-label" color="secondary">
                                                    {t("parent_email")}*
                                                </IonLabel>
                                                    <IonInput type="text"
                                                        className="input-field"
                                                        placeholder={t("parent_email")}
                                                        value={email}
                                                        onIonInput={
                                                            (e) => setEmail(e.detail.value!)
                                                    }></IonInput>
                                            </IonItem>
                                            <IonItem>
                                                
                                                <IonLabel position="stacked" className="input-label" color="secondary">
                                                    {t("set_pass")}*
                                                </IonLabel>
                                                    <IonInput type="text"
                                                        className="input-field"
                                                        placeholder={t("set_pass")}
                                                        value={newPass}
                                                        onIonInput={
                                                            (e) => setNewPass(e.detail.value !)
                                                    }></IonInput>
                                            </IonItem>
                                        </IonCardContent>
                                    </IonCard>
                                </IonCol>
                            </IonRow>
                            <IonRow>
                                <IonCol className="ion-padding-horizontal ion-padding-bottom">
                                    <IonButton onClick={createAccount}
                                        expand="block"
                                        className="first-button"
                                        fill="clear" >{t("create_acc")}</IonButton>

                                </IonCol>
                            </IonRow>
                        </IonGrid>
                    </IonPopover>

                    <IonRow>
                    {((authInfo.user.role === 'Admin') || (authInfo.user.role === 'Teacher') || (authInfo.user.role === 'Academic') 
                        || (authInfo.user.role === 'Super') || (authInfo.user.roles.includes("Academic")) || (authInfo.user.roles.includes("Teacher")) ) && comm && ( 
                    
                        <IonCol size="12" sizeLg="6">
                            <IonCard>
                                <IonCardHeader>
                                     <IonRow>
                                        <IonCol size="6">
                                            <IonCardSubtitle className='info-subtitle pt-08'>{t("recent_inquiries")}</IonCardSubtitle>
                                        </IonCol>
                                        
                                        <IonCol size="6" className='ion-text-right'>
                                            <IonButton color="secondary" size="small" className='pr-16' onClick={() => history.push(`/app/inquiries/student/${studentID}`)}>{t("view_all")}</IonButton>
                                        </IonCol>
                                       
                                    </IonRow>
                                 </IonCardHeader>
                                <IonCardContent className="ion-no-padding ion-margin-bottom">
                                {(inquiries.length > 0) ? (
                                <>
                                    <IonRow className="ion-padding-vertical">
                                        <IonCol>
                                            <IonList className="ion-no-padding">
                                                {(inquiries.map((inquiry) => {
                                                    return (
                                                        <IonItem detail={true}
                                                            detailIcon={chatboxEllipses}
                                                            lines='full'
                                                            button={true}
                                                            key={inquiry._id}
                                                            onClick={
                                                                () => history.push(`/app/inquiry/${inquiry._id}`)
                                                            }>
                                                            <IonIcon slot="start"
                                                                icon={newspaper}
                                                                color={(inquiry.status === 'pending') ? 'danger' : 'success'}/>
                                                            <IonLabel className="list-label">
                                                                <h3>{inquiry.title}<br/>{formatDated(inquiry.createdAt)}</h3>
                                                            </IonLabel>
                                                        </IonItem>
                                                    )
                                                }))
                                                } 
                                            </IonList>
                                        </IonCol>
                                    </IonRow>
                                </>
                                ) : (
                                    <IonRow className="ion-padding-vertical">
                                        <IonCol>
                                            <IonItem lines="none" className='red-card ion-margin'>
                                                <IonIcon icon={alertCircleOutline}
                                                slot="start"
                                                color="danger" />
                                                <IonLabel className="list-title">
                                                        <h3>{t("no_inquiries")}</h3></IonLabel>

                                            </IonItem>
                                        </IonCol>
                                    </IonRow>
                                )}

                                </IonCardContent>
                            </IonCard>
                        </IonCol>  
                    )}


                    {((authInfo.user.role === 'Admin') || (authInfo.user.role === 'Super') || (authInfo.user.role === 'Transporter')
                    || (authInfo.user.roles.includes("Transporter")) ) && transport && ( 
                        <>
                        <IonCol size="12" sizeLg="6">
                            <IonCard>
                                <IonCardHeader>
                                    <IonCardSubtitle className='info-subtitle'>{t("student_routes")}</IonCardSubtitle>
                                </IonCardHeader>
                                <IonCardContent className="ion-no-padding ion-margin-bottom">

                                    {(inRoutes.length > 0) ? (
                                    <>

                                    <IonRow className="ion-margin-top">
                                        <IonCol>
                                        <IonList className='ion-no-padding'>
                                            {(inRoutes.map((inRoute) => {
                                                    return (
                                                        <IonItem detail={true}
                                                            detailIcon={construct}
                                                            lines='full'
                                                            button={true}
                                                            key={inRoute._id}
                                                            onClick={
                                                                () => history.push(`/app/route/${inRoute._id}`)
                                                            }>
                                                            <IonIcon slot="start"
                                                                icon={analytics}
                                                                />
                                                            <IonLabel className='list-label'>
                                                            <h3>{inRoute.name} ({inRoute.type})</h3>
                                                            </IonLabel>
                                                        </IonItem>
                                                    )
                                                }))
                                                } </IonList>
                                        </IonCol>
                                    </IonRow>
                                    </>
                                    ) : (
                                    <IonRow className="ion-padding-vertical">
                                        <IonCol>
                                            <IonItem lines="none" className='red-card ion-margin'>
                                                <IonIcon icon={alertCircleOutline}
                                                slot="start"
                                                color="danger" />
                                                <IonLabel className="list-title">
                                                        <h3>{t("no_routes")}</h3></IonLabel>

                                            </IonItem>
                                        </IonCol>
                                    </IonRow>
                                    )}
                    
                                    {(outRoutes.length > 0) && (classID !== "withdrawn") && (classID !== "graduated") && (
                                    <>
                                    <IonRow className="mt-30">
                                        <IonCol>
                                            <IonTitle className='list-title'>
                                                {t("add_student_to_route")}
                                            </IonTitle>
                                        </IonCol>
                                    </IonRow>
                                    <IonRow className="ion-padding-top">
                                        <IonCol>

                                            <IonItem 
                                                className='alt-item'
                                                detail={false}
                                                lines='full'
                                                button={false}>
                                                    <IonLabel position="fixed" color="secondary"> {t("select_route")}</IonLabel>
                                                    <IonSelect value={selectedRoute}
                                                                        placeholder={t("select_route")}
                                                                        onIonChange={
                                                                            (e) => e.detail.value && setSelectedRoute(e.detail.value)
                                                                    }>
                                                                    { outRoutes.map((outRoute) => {
                                                                        return (<IonSelectOption key={outRoute._id} value={outRoute._id}>{outRoute.name} ({outRoute.type})</IonSelectOption>);
                                                                        })
                                                                    }
                                                    </IonSelect>
                                            </IonItem>
  
                                        </IonCol>
                                    </IonRow>
                                    <IonRow className="ion-margin-top">
                                        <IonCol className="ion-text-center">
                                            <IonButton onClick={() => selectedRoute && history.push(`/app/mapstudents/${selectedRoute}/${studentID}`)}
                                                color='secondary'>{t("add_to_route")}</IonButton>

                                        </IonCol>
                                    </IonRow>
                                    </>
                                    )}
                                </IonCardContent>
                            </IonCard>
                        </IonCol>
                        <IonCol size="12" sizeLg="6">
                            <IonCard>
                                <IonCardHeader>
                                    <IonCardSubtitle className='info-subtitle'>{t("recent_trips")}</IonCardSubtitle>
                                </IonCardHeader>
                                <IonCardContent className="ion-no-padding ion-margin-bottom">

                                    {(completedTrips.length > 0) ? (completedTrips.map((completedTrip) => {
                                    return (
                                        <IonRow key={completedTrip._id} className="ion-no-padding ion-no-margin">
                                            <IonCol className="ion-no-padding ion-no-margin">
                                                <IonCard  className={(completedTrip.type === 'drop') ? "action-card-b" : "action-card-c"}
                                                onClick={() => history.push(`/app/trip/detail/${completedTrip._id}`)}>
                                                    <IonCardContent>
                                                    <IonGrid className="ion-no-padding">
                                                            <IonRow>
                                                                <IonCol size="2">
                                                                    <IonIcon className='second-icon'
                                                                        icon={busOutline}
                                                                        color={completedTrip.type === 'drop' ? 'secondary' : 'primary'} />
                                                                </IonCol>
                                                                <IonCol size="9">
                                                                    <IonText className='action-text'>{completedTrip.name} ({completedTrip.type})<br/>
                                                                    {t("finished")}: {formatDateTime(completedTrip.finishedAt)}
                                                                    </IonText>
                                                                </IonCol>
                                                                <IonCol size="1">
                                                                    <IonIcon className='second-icon'
                                                                        
                                                                        icon={chevronForwardOutline}
                                                                        color='dark'/>
                                                                </IonCol>
                                                            </IonRow>
                                                    
                                                        </IonGrid>
                                                    </IonCardContent>
                                                </IonCard>
                                            </IonCol>
                                        </IonRow>
                                        )})) : (
                                        <IonRow className="ion-padding-vertical">
                                            <IonCol>
                                                <IonItem lines="none" className='red-card ion-margin'>
                                                    <IonIcon icon={alertCircleOutline}
                                                    slot="start"
                                                    color="danger" />
                                                    <IonLabel className="list-title">
                                                            <h3>{t("no_completed_trips")}</h3></IonLabel>

                                                </IonItem>
                                            </IonCol>
                                        </IonRow>
                                        )}
                                </IonCardContent>
                            </IonCard>
                        </IonCol>
                        </>
                    )}

                    {((authInfo.user.role === 'Admin') || (authInfo.user.role === 'Super') || (authInfo.user.role === 'Accountant')
                    || (authInfo.user.roles.includes("Accountant")) ) && payment && ( 
                        <>
                        <IonCol size="12" sizeLg="6">
                            <IonCard>
                                <IonCardContent className="ion-no-padding ion-margin-bottom">
                                    <IonRow className="ion-margin-top">
                                        <IonCol size="6">
                                            <IonCardSubtitle className='info-subtitle pl-16'>
                                            {t("student_dues")}
                                            </IonCardSubtitle>
                                        </IonCol>
                                        <IonCol size="6" className='ion-text-right'>
                                            <IonButton color="dark" size="small" className='pr-16' onClick={() => history.push(`/app/student/ledger/${studentID}`)}>{t("view_ledger")}</IonButton>
                                        </IonCol>
                                    </IonRow>
                                    <IonRow>
                                        <IonCol>
                                            <IonCard className='action-card-b'>
                                                <IonCardHeader className="ion-text-center">
                                                    <IonCardSubtitle color='danger'>{t("overdue_amount")}</IonCardSubtitle>
                                                </IonCardHeader>
                                                <IonCardContent className="ion-text-center overdue">
                                                    <IonGrid>
                                                        <IonRow>
                                                            <IonCol>
                                                                <h1>{t("rs")} {due}</h1>
                                                            </IonCol>
                                                        </IonRow>
                                                        <IonRow className="ion-padding-top">
                                                            <IonCol className="ion-padding-horizontal">
                                                                <IonButton onClick={handleNotify}
                                                                    expand="block" color="dark">{t("send_reminder")}</IonButton>

                                                            </IonCol>
                                                        </IonRow>
                                                    </IonGrid>
                                                </IonCardContent>
                                            </IonCard>
                                        </IonCol>
                                    </IonRow>
                                </IonCardContent>
                            </IonCard>
                        </IonCol>
                        <IonCol size="12" sizeLg="6">
                            <IonCard>
                                <IonCardContent className="ion-no-padding ion-margin-bottom">
                                    <IonRow>
                                        <IonCol>
                                            <IonRow className="mt-30">
                                                <IonCol size="6">
                                                    <IonCardSubtitle className='info-subtitle pl-16'>
                                                        {t("recent_invoices")}
                                                    </IonCardSubtitle>
                                                </IonCol>
                                                {(classID !== "withdrawn") && (classID !== "graduated") && (
                                                <IonCol size="6" className='ion-text-right'>
                                                    <IonButton color="danger" size="small" className='pr-16' onClick={() => history.push(`/app/dues/add/${studentID}`)}>{t("generate_invoice")}</IonButton>
                                                </IonCol>
                                                )}
                                            </IonRow>
                                            {(trans.length > 0) ? (
                                                <IonRow>
                                                    <IonCol>
                                                        {(trans.map((tran, i) => {
                                                            return (
                                                                <IonCard className='border-radius-10' key={tran._id}>
                                                                    <IonCardContent className="ion-no-padding ion-no-margin">
                                                                        <IonGrid className="ion-no-padding ion-no-margin">
                                                                            <IonRow className="tran-pad tran-row-1">
                                                                                <IonCol size="11">
                                                                                
                                                                                    <IonText className='tran-label-1'>{t("invoice_date")}<br/></IonText>
                                                                                    <IonText className='tran-text'>
                                                                                        {formatDated(tran.invoiceDate)}
                                                                                        </IonText>
                                                                                

                                                                                </IonCol>
                                                                                <IonCol size="1" className='mt-10'>
                                                                                        <IonIcon className='tran-icon-2'
                                                                                            icon={informationCircle}
                                                                                            color='dark'
                                                                                            onClick={() => history.push(`/app/invoice/${tran._id}`)}
                                                                                        />
                                                                                </IonCol>
                                                                            </IonRow>
                                                                            <IonRow className='ion-padding border-top'>
                                                                                <IonCol size="2">
                                                                                    <IonIcon className='tran-icon'
                                                                                        icon={receipt}
                                                                                        color={(tran.paidAt !== undefined) ? 'success' : ((new Date(tran.dueDate)) < (new Date(Date.now()))) ? 'danger' : 'secondary'} 
                                                                                        />
                                                                                        <IonText className='tran-mode'><br/> {(tran.paidAt !== undefined) ? t("cleared") : ((new Date(tran.dueDate)) < (new Date(Date.now()))) ? t("past_due") : ((new Date(tran.invoiceDate)) <= (new Date(Date.now()))) ? t("uncleared") : t("scheduled")}</IonText>
                                                                                    </IonCol>
                                                                                    <IonCol size="10" className='ion-padding-horizontal'>
                                                                                        <IonText className='action-text'> 
                                                                                        {t("rs")} {Math.abs(tran.amount)}<br/> 
                                                                                        </IonText>
                                                                                        <IonText className='tran-rem'> 
                                                                                            {t("due_on")} {formatDated(tran.dueDate)}
                                                                                        
                                                                                        </IonText>
                                                                                    </IonCol>
                                                                                
                                                                            </IonRow>
                                                                        
                                                                        
                                                                        </IonGrid>
                                                                    </IonCardContent>
                                                                </IonCard>
                                                            )
                                                                })) 
                                                            } 
                                                        </IonCol>
                                                    </IonRow>
                                                    ) : (
                                                    <IonRow className="ion-padding-vertical">
                                                        <IonCol>
                                                            <IonItem lines="none" className='red-card ion-margin'>
                                                                <IonIcon icon={alertCircleOutline}
                                                                slot="start"
                                                                color="danger" />
                                                                <IonLabel className="list-title">
                                                                        <h3>{t("no_invoices")}</h3></IonLabel>

                                                            </IonItem>
                                                        </IonCol>
                                                    </IonRow>
                                                    )}

                                        </IonCol>
                                    </IonRow>
                                    
                                </IonCardContent>
                            </IonCard>
                        </IonCol>
                        <IonCol size="12" sizeLg="6">
                            <IonCard>
                                <IonCardHeader>
                                    <IonRow>
                                        <IonCol size="6">
                                            <IonCardSubtitle className='info-subtitle pt-08'>{t("recent_receipts")}</IonCardSubtitle>
                                        </IonCol>
                                        {(classID !== "withdrawn") && (classID !== "graduated") && (
                                        <IonCol size="6" className='ion-text-right'>
                                            <IonButton color="success" size="small" className='pr-16' onClick={() => history.push(`/app/collection/${studentID}`)}>{t("collect_fees")}</IonButton>
                                        </IonCol>
                                        )}
                                    </IonRow>
                                </IonCardHeader>
                              
                                <IonCardContent className="ion-no-padding ion-margin-bottom">
                                {(transactions.length > 0) ? (
                                <IonRow>
                                    <IonCol>
                                        {(transactions.map((tran, i) => {
                                                    return (
                                                        <IonCard className='border-radius-10' key={tran._id}>
                                                            <IonCardContent className="ion-no-padding ion-no-margin">
                                                                <IonGrid className="ion-no-padding ion-no-margin">
                                                                    <IonRow className="tran-pad tran-row">
                                                                        <IonCol size="11">
                                                                        
                                                                            <IonText className='tran-label'>{t("transaction_date")}<br/></IonText>
                                                                            <IonText className='tran-text'>
                                                                                {formatDated(tran.dated)}
                                                                                </IonText>
                                                                        

                                                                        </IonCol>
                                                                        <IonCol size="1" className='mt-10'>
                                                                            {(tran.amount > 0) && (
                                                                                <IonIcon className='tran-icon-2'
                                                                                    icon={receipt}
                                                                                    color='dark'
                                                                                    onClick={() => history.push(`/app/receipt/${tran._id}`)}
                                                                                />
                                                                            )}
                                                                        </IonCol>
                                                                    </IonRow>
                                                                    <IonRow className='ion-padding border-top'>
                                                                        <IonCol size="2">
                                                                            <IonIcon className='tran-icon'
                                                                                icon={(tran.mode === 'cash') ? cash : card}
                                                                                color={(tran.amount > 0) ? 'success' : 'danger'} 
                                                                                />
                                                                                <IonText className='tran-mode'><br/> {tran.mode} </IonText>
                                                                            </IonCol>
                                                                            <IonCol size="10" className='ion-padding-horizontal'>
                                                                                <IonText className='action-text'> 
                                                                                Rs. {Math.abs(tran.amount)}<br/> 
                                                                                </IonText>
                                                                                <IonText className='tran-rem'> 
                                                                                    {tran.remarks}<br/>
                                                                                
                                                                                </IonText>
                                                                            </IonCol>
                                                                        
                                                                    </IonRow>
                                                                
                                                                
                                                                </IonGrid>
                                                            </IonCardContent>
                                                        </IonCard>
                                                    )
                                                })) 
                                            } 
                                        </IonCol>
                                    </IonRow>
                                    ) : (
                                    <IonRow className="ion-padding-vertical">
                                        <IonCol>
                                            <IonItem lines="none" className='red-card ion-margin'>
                                                <IonIcon icon={alertCircleOutline}
                                                slot="start"
                                                color="danger" />
                                                <IonLabel className="list-title">
                                                        <h3>{t("no_receipts")}</h3></IonLabel>

                                            </IonItem>
                                        </IonCol>
                                    </IonRow>
                                    )}
                                </IonCardContent>
                            </IonCard>
                        </IonCol>
                        </>
                    )}
                    </IonRow>

                </IonGrid>

            </IonContent>
        </IonPage>
    );
};

export default StudentDetail;
