import {
    IonAlert,
    IonBackButton,
    IonBadge,
    IonButton,
    IonButtons,
    IonCard,
    IonCardContent,
    IonCardHeader,
    IonCardSubtitle,
    IonCol,
    IonContent,
    IonGrid,
    IonHeader,
    IonIcon,
    IonInput,
    IonItem,
    IonLabel,
    IonList,
    IonLoading,
    IonMenuButton,
    IonPage,
    IonPopover,
    IonRow,
    IonTextarea,
    IonTitle,
    IonToolbar,
    isPlatform,
} from '@ionic/react';
import { alertCircleOutline, camera, chatboxEllipses, chatbubbleEllipses, construct, create, documentText, trash } from 'ionicons/icons';
import axios from 'axios';
import { Capacitor } from '@capacitor/core';
import { FilePicker } from '@capawesome/capacitor-file-picker';
import React, { useEffect, useState } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import { useTranslation } from "react-i18next";
import { useAuth } from '../../../components/AuthContext';
import ThemeToggle from '../../../components/ThemeToggle';
import LanguageToggle from '../../../components/LanguageToggle';

interface ParamTypes {
    employeeID: string
}

interface Inquiry {
    _id: string,
    title: string,
    type: string,
    status: string,
    createdAt: Date
}

const EmployeeDetail: React.FC = () => {
    const {authInfo} = useAuth()!;
    const {t} = useTranslation();
    const history = useHistory();
    const {employeeID} = useParams < ParamTypes > ();
    const [showLoading, setShowLoading] = useState < boolean > (false);
    const [iserror, setIserror] = useState < boolean > (false);
    const [message, setMessage] = useState < string > ("");
    const [ispass, setIspass] = useState < boolean > (false);
    const [pass, setPass] = useState < string > ("");
    const [name, setName] = useState < string > ("");
    const [designation, setDesignation] = useState < string > ("");
    const [departmentName, setDepartmentName] = useState < string > ("");
    const [userID, setUserID] = useState < string > ("");
    const [fileName, setFileName] = useState < string > ("");
    const [uploadFile, setUploadFile] = useState < File | null > (null);
    const [note, setNote] = useState < string > ("");
    const [title, setTitle] = useState < string > ("");
    const [inquiries, setInquiries] = useState < Inquiry[] > ([]);
    const [createdAt, setCreatedAt] = useState < Date > ();
    const [imageURL, setImageURL] = useState < string > ("");
    const [refreshed, setRefreshed] = useState < number > (0);

    const api = axios.create({
        baseURL: process.env.REACT_APP_API_URL,
        timeout: parseInt(process.env.REACT_APP_API_TO!),
        headers: {
            'Authorization': 'Bearer ' + authInfo.token
        }
    });

    const formatDated = (value: Date) => {
        const valueDate = new Date(value);
        return valueDate.toLocaleDateString('en-GB');
    };

    const handleMessage = () => {

        if (!note || note === "") {
            setMessage(t("notif_message_mandatory"));
            setIserror(true);
            return;
        }

        if (!title || title === "") {
            setMessage(t("notif_title_mandatory"));
            setIserror(true);
            return;
        }
  
        const notifyEmployee = () => {
            return new Promise((resolve, reject) => {
                api.post(`/employees/notification`, { userID, title, 'text': note, 'type': 'general' }).then(res => {
                    return resolve(res.data);
                }).catch(err => reject(err));
            });
        }
  
        setShowLoading(true);
        notifyEmployee()
        .then(data => {
          setPass(t("notif_sent"));
          setIspass(true);
        })
        .catch((error) => {
            setMessage(error.response?.data.message || t("something_went_wrong"));
            setIserror(true);
        })
        .finally(() => setShowLoading(false));
        
    }

    const convertFileSrc = (path: string) => {
        return Capacitor.convertFileSrc(path);
    };
      
    const fileToDataUri = (file: File) => new Promise<string>((resolve, reject) => {
        const reader = new FileReader();
        reader.onload = (event: any) => {
            resolve(event.target.result);
        };
        reader.readAsDataURL(file);
    });

    const pickImage = async () => {
         try {

            const result = await FilePicker.pickImages({limit: 1});

            if (result.files.length === 1)
            {
                const file = result.files[0];
                //for native
                if (file && file.path && file.name && file.mimeType) {
                    const webPath = convertFileSrc(file.path);
                    if ((file.mimeType === 'image/jpeg') || (file.mimeType === 'image/png'))
                    {
                        setImageURL(webPath);
                    }
                    else
                    {
                        setImageURL("");
                    }
                    const blob = await fetch(webPath).then((r) => r.blob());
                    const rawFile = new File([blob], file.name, {
                        type: file.mimeType,
                    });
                    setUploadFile(rawFile);
                }
                //for web
                else if (file && file.blob && file.name && file.mimeType) {
                    const rawFile = new File([file.blob], file.name, {
                        type: file.mimeType,
                    });
                    setUploadFile(rawFile);
                    if ((file.mimeType === 'image/jpeg') || (file.mimeType === 'image/png'))
                    {
                        const base64 = await fileToDataUri(rawFile);
                        setImageURL(base64);
                    }
                    else
                    {
                        setImageURL("");
                    }
                }
            }
            
        } 
        catch(error: any) {
 
            setMessage(t("no_files_selected"));  
            setIserror(true);
        }
    };

    const handlePhoto = () => {

        if (!uploadFile) {
            setMessage(t("logo_format"));
            setIserror(true);
            return;
        }

        const upStud = ( formData: FormData) => {
            return new Promise((resolve, reject) => {
              api.post('/employees/updatePhoto', formData).then(res => {
                return resolve(res.data.employee.userInfo.name);
              }).catch(err => reject(err));
    
            });
          }

      let formData = new FormData();
      formData.append('image', uploadFile, 'image');
      formData.append('employeeID', employeeID);
  
        setShowLoading(true);
        upStud(formData)
        .then(data => {
            setPass(data+t("has_been_updated"));
            setIspass(true);
        })
        .catch((error) => {
            setMessage(error.response?.data.message || t("something_went_wrong"));
            setIserror(true);
        })
        .finally(() => setShowLoading(false));
        
    }
    
    useEffect(() => {

        const fetchData = async () => {
            setShowLoading(true);
            try {

                const result = await api.get('/employees/' + employeeID);
                setFileName(result.data.photo);
                setUserID(result.data.userInfo.userID);
                
                if (result.data.userInfo.userID !== "none")
                {
                    const apps = await api.get(`/staffChats/getFiveByEmployee/${employeeID}`);
                    setInquiries(apps.data);
                }

                setName(result.data.userInfo.name);
                
                if (typeof result.data.designation === "string") {
                    setDesignation(result.data.designation);
                }

                setDepartmentName(result.data.departmentInfo.name);
                setCreatedAt(result.data.createdAt);

            } catch (error: any) {
                setMessage(error.response?.data.message || t("something_went_wrong"));
                setIserror(true);
            } finally {
                setShowLoading(false);
            }
        };

        fetchData();

    }, [authInfo, employeeID, refreshed]);
    
    return (
        <IonPage>

            <IonHeader>
                <IonToolbar className="white-shade">
                    <IonButtons slot="start">
                        <IonMenuButton color="secondary"/>
                        <IonBackButton color="secondary" defaultHref="/app"/>
                    </IonButtons>
                    <IonTitle size={isPlatform('mobile') ? "small" : undefined} color="secondary">{t("manage_employee")}</IonTitle>
                    {!isPlatform('mobile') && (
                    <IonButtons slot="end">
                        <ThemeToggle />
                        <LanguageToggle />
                   </IonButtons>
                    )}
                </IonToolbar>
            </IonHeader>


            <IonContent fullscreen={true} className="grey-shade">

                <IonHeader collapse="condense">
                    <IonToolbar>
                        <IonTitle size="large" color="secondary">{t("manage_employee")}</IonTitle>
                    </IonToolbar>
                </IonHeader>

                <IonLoading isOpen={showLoading}
                    cssClass="first-loading"
                    spinner="circular"
                    message={t("please_wait")}
                />

                <IonAlert isOpen={iserror}
                    cssClass="first-alert"
                    onDidDismiss={() => setIserror(false)}
                    header={t("error")}
                    message={message}
                    buttons={
                        [`${t("close")}`]
                    }
                />

                <IonAlert isOpen={ispass}
                    cssClass="first-alert"
                    onDidDismiss={
                        () => {
                            setIspass(false);
                            setRefreshed(Math.random());
                        }
                    }
                    header={t("success")}
                    message={pass}
                    buttons={
                        [`${t("close")}`]
                    }
                />

                <IonGrid className="ion-no-padding mb-60">

                    <IonRow className="mt-60 mb-60">
                        <IonCol size="6" sizeLg="3">
                            <IonRow className="mt-30">
                                <IonCol className="ion-text-center" id="open-upload-input">
                                    <div className="vertical-container">
                                        <div className="vertical-center"> 
                                        {fileName && (fileName !== "") ?
                                            (<img src={`${process.env.REACT_APP_STATIC_S3}/images/${fileName}`} alt={t("profile_pic")} width="70"  />)
                                            : (<img src={`assets/images/avatar.png`} alt={t("profile_pic")} width="70" />)
                                        }
                                        </div>
                                    </div>
                                </IonCol>
                            </IonRow>
                           
                            <IonPopover trigger="open-upload-input" size="cover">
                                <IonRow>
                                    <IonCol>
                                        <IonCard>
                                            <IonCardContent className="ion-no-padding">
                                                <IonItem detail={true}
                                                    lines='none'
                                                    button={true}
                                                    color="primary"
                                                    onClick={pickImage}
                                                    >
                                                    <IonIcon
                                                        slot="start"
                                                        icon={camera}
                                                    />
                                                    <IonLabel>
                                                     {(uploadFile) ? t("change_photo") : t("upload_photo")}
                                                    </IonLabel>
                                                    
                                                </IonItem>
                                                {(uploadFile) && (
                                                <IonItem detail={true}
                                                        detailIcon={trash}
                                                        lines='none'
                                                        button={true}
                                                        onClick={() => {
                                                            setUploadFile(null);
                                                            setImageURL("");
                                                        }}
                                                    >
                                                    <IonBadge color="danger" slot="start">1</IonBadge>
                                                    <IonLabel>
                                                        {t("remove_files")}
                                                    </IonLabel>
                                                    
                                                </IonItem>
                                                )}
                                            </IonCardContent>
                                        </IonCard>
                                    </IonCol>
                                </IonRow>
                                {imageURL && (
                                <IonRow>
                                    <IonCol>
                                        <IonCard className="ion-text-center">
                                            <IonCardHeader>
                                                <IonCardSubtitle>{t("image_preview")}</IonCardSubtitle>
                                            </IonCardHeader>
                                            <IonCardContent>
                                            
                                                <img src={imageURL} 
                                                    alt={t("image_preview")}
                                                    width="70" />

                                            </IonCardContent>        
                                        </IonCard>
                                    </IonCol>
                                </IonRow>
                                )}
                                <IonRow>
                                    <IonCol className="ion-text-center">
                                        <IonButton onClick={handlePhoto}
                                            size="small"
                                            color="secondary">{t("update_photo")}</IonButton>

                                    </IonCol>
                                </IonRow>
                            </IonPopover>

                            <IonRow className='mt-30 ml-30'>
                                <IonCol>
                                    <p>
                                        <span className="profile-text">{name}</span><br/>
                                        <span className='pt-05'>{designation !== "" && `${designation} (`} {departmentName} {designation !== "" && `)`} </span>
                                        <span className='pt-05'>{t("added_on")} {createdAt && formatDated(createdAt)}</span>
                                    </p>

                                </IonCol>
                            </IonRow>
                        </IonCol>
                        <IonCol size="6" sizeLg="3">
                            <IonRow className="ion-margin-horizontal">
                                <IonCol>
                                    <IonButton onClick={ () => history.push(`/app/employee/profile/${employeeID}`)}
                                        expand="block" className="first-button" fill="clear">{t("basic_info")}</IonButton>
                                </IonCol>
                                <IonCol>
                                    <IonButton onClick={ () => history.push(`/app/employee/account/${employeeID}`)}
                                        expand="block" className="first-button" fill="clear">{t("employee_account")}</IonButton>
                                </IonCol>
                                <IonCol>
                                    <IonButton onClick={ () => history.push(`/app/employee/attendance/${employeeID}`)}
                                        expand="block" className="second-button" fill="clear">{t("attendance")}</IonButton>
                                </IonCol>
                                {authInfo.institute.task && (authInfo.user.role === "Admin" || authInfo.user.role === "Super") && (
                                <>
                                    <IonCol>
                                        <IonButton onClick={ () => history.push(`/app/reports/employee/${employeeID}`)}
                                            expand="block" className="first-button" fill="clear">{t("daily_reports")}</IonButton>
                                    </IonCol>
                                    <IonCol>
                                        <IonButton onClick={ () => history.push(`/app/tasks/employee/${employeeID}`)}
                                            expand="block" className="first-button" fill="clear">{t("view_tasks")}</IonButton>
                                    </IonCol>
                                    
                                </>
                                )}
                            </IonRow>
                        </IonCol>
                    {(userID !== "none") && (
                        <>
                         <IonCol size="12" sizeLg="6">
                             <IonCard>
                                 <IonCardHeader>
                                     <IonCardSubtitle className='info-subtitle'>{t("notification")}</IonCardSubtitle>
                                 </IonCardHeader>
                                 <IonCardContent className="ion-no-padding ion-margin-bottom">
                                     <IonRow>
                                         <IonCol>
                                             <IonCard>
                                                 <IonCardContent className="ion-no-padding">
                                                     <IonItem color="light">
                                                         
                                                         <IonLabel position="stacked" className="input-label" color="secondary">
                                                             {t("notif_title")}*
                                                         </IonLabel>
                                                             <IonInput type="text"
                                                                 className="input-field"
                                                                 placeholder={t("notif_title")}
                                                                 value={title}
                                                                 onIonInput={
                                                                     (e) => setTitle(e.detail.value!)
                                                             }></IonInput>
                                                     </IonItem>
                                                 </IonCardContent>
                                             </IonCard>
                                         </IonCol>
                                     </IonRow>
                                     <IonRow>
                                         <IonCol>
                                             <IonCard>
                                                 <IonCardContent className="ion-no-padding">
                                                     <IonItem color="light">
                                                         
                                                         <IonLabel position="stacked" className="input-label" color="secondary">
                                                             {t("notif_message")}*
                                                         </IonLabel>
                                                            <IonTextarea rows={1} value={note} className="input-field" placeholder={t("notif_message")}
                                                                onIonInput={(e) => setNote(e.detail.value!)}
                                                            />
                                                     </IonItem>
                                                 </IonCardContent>
                                             </IonCard>
                                         </IonCol>
                                     </IonRow>
                                    
                                     <IonRow className="ion-margin-top">
                                         <IonCol className="ion-text-center">
                                             <IonButton onClick={() => handleMessage()}
                                                 color='secondary'>{t("send_notif")}</IonButton>
 
                                         </IonCol>
                                     </IonRow>
                                           
                                 </IonCardContent>
                             </IonCard>
                         </IonCol>
                         <IonCol size="12" sizeLg="6">
                             <IonCard>
                                 <IonCardHeader>
                                     <IonRow>
                                        <IonCol size="6">
                                            <IonCardSubtitle className='info-subtitle pt-08'>{t("recent_inquiries")}</IonCardSubtitle>
                                        </IonCol>
                                        {(authInfo.user.role === "Admin" || authInfo.user.role === "Super") && (
                                        <IonCol size="6" className='ion-text-right'>
                                            <IonButton color="secondary" size="small" className='pr-16' onClick={() => history.push(`/app/chats/employee/${employeeID}`)}>{t("view_all")}</IonButton>
                                        </IonCol>
                                        )}
                                    </IonRow>
                                 </IonCardHeader>
                                 <IonCardContent className="ion-no-padding ion-margin-bottom">
                                 {(inquiries.length > 0) ? (
                                 <>
                                     <IonRow className="ion-padding-vertical">
                                         <IonCol>
                                             <IonList className="ion-no-padding">
                                                {(inquiries.map((inquiry) => {
                                                     return (
                                                         <IonItem detail={true}
                                                            detailIcon={chatboxEllipses}
                                                             lines='full'
                                                             button={true}
                                                             key={inquiry._id}
                                                             onClick={
                                                                 () => history.push(`/app/chat/${inquiry._id}`)
                                                             }>
                                                             <IonIcon slot="start"
                                                                 icon={(inquiry.type === "task") ? create : (inquiry.type === "leave") ? documentText : chatbubbleEllipses}
                                                                 color={(inquiry.type === "task") ? 'primary' : (inquiry.type === "leave") ? 'tertiary' : (inquiry.status === 'closed') ? 'danger' : 'success'}/>
                                                             <IonLabel className="list-label">
                                                                 <h3>{inquiry.title}<br/>{formatDated(inquiry.createdAt)}</h3>
                                                             </IonLabel>
                                                         </IonItem>
                                                     )
                                                 }))
                                                 } 
                                             </IonList>
                                         </IonCol>
                                     </IonRow>
                                 </>
                                 ) : (
                                     <IonRow className="ion-padding-vertical">
                                         <IonCol>
                                             <IonItem lines="none" className='red-card ion-margin'>
                                                 <IonIcon icon={alertCircleOutline}
                                                 slot="start"
                                                 color="danger" />
                                                 <IonLabel className="list-title">
                                                         <h3>{t("no_inquiries")}</h3></IonLabel>
 
                                             </IonItem>
                                         </IonCol>
                                     </IonRow>
                                 )}
 
                                 </IonCardContent>
                             </IonCard>
                         </IonCol>  
                     </>
                    )}
                    </IonRow>
                </IonGrid>
            </IonContent>
        </IonPage>
    );
};

export default EmployeeDetail;