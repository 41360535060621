import {
    IonAlert,
    IonBackButton,
    IonBadge,
    IonButton,
    IonButtons,
    IonCard,
    IonCardContent,
    IonCheckbox,
    IonCol,
    IonContent,
    IonFooter,
    IonGrid,
    IonHeader,
    IonInput,
    IonItem,
    IonLabel,
    IonList,
    IonLoading,
    IonMenuButton,
    IonPage,
    IonRow,
    IonSegment,
    IonSegmentButton,
    IonSelect,
    IonSelectOption,
    IonTitle,
    IonToggle,
    IonToolbar,
    isPlatform,
} from '@ionic/react';
import axios from 'axios';
import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { useTranslation } from "react-i18next";
import { useAuth } from '../../../components/AuthContext';
import ThemeToggle from '../../../components/ThemeToggle';
import LanguageToggle from '../../../components/LanguageToggle';

interface ParamTypes {
    employeeID: string
}

interface Session {
    _id: string,
    name: string,
    isActive: boolean
}
interface ClassInterface {
    _id: string,
    sessionID: string,
    name: string,
    year: string,
    isActive: boolean,
    isChecked: boolean
}

const AccountInfo: React.FC = () => {
    const {register, authInfo} = useAuth()!;
    const {t} = useTranslation();
    const {employeeID} = useParams < ParamTypes > ();
    const [active, setActive] = useState < string > ("");
    const [showLoading, setShowLoading] = useState < boolean > (false);
    const [iserror, setIserror] = useState < boolean > (false);
    const [message, setMessage] = useState < string > ("");
    const [ispass, setIspass] = useState < boolean > (false);
    const [pass, setPass] = useState < string > ("");
    const [name, setName] = useState < string > ("");
    const [phone, setPhone] = useState < string > ("");
    const [email, setEmail] = useState < string > ("");
    const [userID, setUserID] = useState < string > ("");
    const [role, setRole] = useState < string > ("");
    const [sessions, setSessions] = useState < Session[] > ([]);
    const [classes, setClasses] = useState < ClassInterface[] > ([]);
    const [classi, setClassi] = useState < string > ("none");
    const [session, setSession] = useState < string > ("");
    const [additional, setAdditional] = useState < boolean > (false);
    const [addSession, setAddSession] = useState < string > ("");
    const [addClasses, setAddClasses] = useState < ClassInterface[] > ([]);
    const [account, setAccount] = useState < boolean > (false);
    const [license, setLicense] = useState < string > ("");
    const [newPass, setNewPass] = useState < string > ("");
    const [roles, setRoles] = useState < string[]> ([]);
    const [transport, setTransport] = useState < boolean > (false);
    const [payment, setPayment] = useState < boolean > (false);
    const [admn, setAdmn] = useState < boolean > (false);
    const [crm, setCrm] = useState < boolean > (false);
    const [invent, setInvent] = useState < boolean > (false);
    const [acad, setAcad] = useState < boolean > (false);
    const [instituteID, setInstituteID] = useState < string > ("");

    const api = axios.create({
        baseURL: process.env.REACT_APP_API_URL,
        timeout: parseInt(process.env.REACT_APP_API_TO!),
        headers: {
            'Authorization': 'Bearer ' + authInfo.token
        }
    });

    const validatePhone = (phone : string) => { 
        // eslint-disable-next-line no-control-regex
        const re = /^[0-9]{10}$/;
        return re.test(phone);
    }

    const validateEmail = (email : string) => { 
        // eslint-disable-next-line no-control-regex
        const re = /^((?:[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*|"(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21\x23-\x5b\x5d-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])*")@(?:(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?|\[(?:(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.){3}(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?|[a-z0-9-]*[a-z0-9]:(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21-\x5a\x53-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])+)\]))$/;
        return re.test(String(email).toLowerCase());
    }

    const handleSection = (section: string) => {

        const toggleEmployee = (isActive: string) => {
            return new Promise((resolve, reject) => {
    
                api.put(`/employees/${employeeID}`, { 'isActive': section }).then(res => {
                    if((userID !== "") && (userID !== "none"))
                    {
                        api.put('/users/'+userID, { 'isActive': section }).then(resu => {
                            return resolve(resu.data.name);
                        }).catch(err => reject(err));   
                    }
                    else
                    {
                        return resolve(res.data.userInfo.name);
                    }
                    
                    
                  }).catch(err => reject(err));
    
            });
        }

        if ((active !== "") && (section !== active))
        {
            setShowLoading(true);
            toggleEmployee(section)
            .then(data => {

                setActive(section);
                setPass(data+t("has_been_updated"));
                setIspass(true);
            })
            .catch((error) => {
                setMessage(error.response?.data.message || t("something_went_wrong"));
                setIserror(true);
            })
            .finally(() => setShowLoading(false));
        }
    }

    const handleSession = (sessionID : string) => {

        const fetchUp = async () => {
            
            setShowLoading(true);
            try {
                const classy = await api.get(`/classes/allInstitute/${authInfo.user.instituteID}/${sessionID}`);
                let classic: Array < ClassInterface > = [];
                for (let i = 0; i < classy.data.length; i++) {
                    classic.push({'_id': classy.data[i]._id, 'sessionID': classy.data[i].sessionID, 'name': classy.data[i].name, 'year': classy.data[i].year, 'isActive': classy.data[i].isActive, 'isChecked' : false}); 
                }
                setClasses(classic);

                if (!addClasses.some(e => e.sessionID === sessionID)) {
                    let classicx: Array < ClassInterface > = [...addClasses, ...classic];
                    setAddClasses(classicx);
                }

            } catch (error: any) {
                setMessage(error.response?.data.message || t("something_went_wrong"));
                setIserror(true);
            } finally {
                setShowLoading(false);
            }
            
        };

        fetchUp();
        setSession(sessionID);
    }

    const handleClassi = (value: string) => {

        if (value !== 'none')
        {
            let classic: Array < ClassInterface > = [...addClasses];
            const i = classic.findIndex(val => val._id === value);
            if (i > -1) {
                classic[i] = {'_id': addClasses[i]._id, 'sessionID': addClasses[i].sessionID, 'name': addClasses[i].name, 'year': addClasses[i].year, 'isActive': addClasses[i].isActive, 'isChecked' : true};    
                
              }
            setAddClasses(classic);
        }
        else
        {
            setAdditional(false);
        }
        setClassi(value);
    }

    const handleAddSession = (sessionID : string) => {

        const fetchUp = async () => {
            
            setShowLoading(true);
            try {
                if (!addClasses.some(e => e.sessionID === sessionID)) {
                    const classy = await api.get(`/classes/allInstitute/${authInfo.user.instituteID}/${sessionID}`);
                    let classic: Array < ClassInterface > = [...addClasses];
                    for (let i = 0; i < classy.data.length; i++) {
                        classic.push({'_id': classy.data[i]._id, 'sessionID': classy.data[i].sessionID, 'name': classy.data[i].name, 'year': classy.data[i].year, 'isActive': classy.data[i].isActive, 'isChecked' : false}); 
                    }
                    setAddClasses(classic);
                }

            } catch (error: any) {
                setMessage(error.response?.data.message || t("something_went_wrong"));
                setIserror(true);
            } finally {
                setShowLoading(false);
            }
            
        };

        fetchUp();
        setAddSession(sessionID);
    }

    const handleClassChecked = (value: boolean, i : number) => {

        let classic: Array < ClassInterface > = [...addClasses];
        classic[i] = {'_id': addClasses[i]._id, 'sessionID': addClasses[i].sessionID, 'name': addClasses[i].name, 'year': addClasses[i].year, 'isActive': addClasses[i].isActive, 'isChecked' : value};    
        setAddClasses(classic);
    }


    const handleChecked = (value: string) => {

        let rolled = [...roles];

        if(rolled.includes(value))
        {
            const index = rolled.indexOf(value);
            if (index > -1) { // only splice array when item is found
                rolled.splice(index, 1); // 2nd parameter means remove one item only
            }
        }
        else
        {
            rolled.push(value);
            if (value === "Teacher") {
                const index = rolled.indexOf("Academic");
                if (index > -1) { // only splice array when item is found
                    rolled.splice(index, 1); // 2nd parameter means remove one item only
                }
            }
            if (value === "Academic") {
                const index = rolled.indexOf("Teacher");
                if (index > -1) { // only splice array when item is found
                    rolled.splice(index, 1); // 2nd parameter means remove one item only
                }
            }
        }

        setRoles(rolled);
    }

    const handleReset = () => {
  
        const upUser = () => {
            return new Promise((resolve, reject) => {
              api.get('/users/reset/'+userID).then(res => {
                return resolve(res.data.name);
              }).catch(err => reject(err));
            });
        }
      
        setShowLoading(true);
        upUser()
          .then(data => {
    
              setPass(data+t("password_reset"));
              setIspass(true);
            
          })
          .catch((error) => {
            setMessage(error.response?.data.message || t("something_went_wrong"));
            setIserror(true);
        })
        .finally(() => setShowLoading(false));
        
    }

    const handleSubmit = async () => {

        if (!name || name === "") {
            setMessage(t("name_mandatory"));
            setIserror(true);
            return;
        }

        if (validateEmail(email) === false) {
            setMessage(t("invalid_email"));
            setIserror(true);
            return;
        }

        if (validatePhone(phone) === false) {
            setMessage(t("invalid_mobile"));
            setIserror(true);
            return;
        }

        if (account)
        {
            if (!role || role === "") {
                setMessage(t("role_mandatory"));
                setIserror(true);
                return;
            }
    
            if (role === "Driver")
            {
                if (!license || license === "") {
                    setMessage(t("license_mandatory"));
                    setIserror(true);
                    return;
                }
            }

            if (userID === "none")
            {
        
                if (!newPass || newPass.length < 8) {
                    setMessage(t("invalid_password"));
                    setIserror(true);
                    setShowLoading(false);
                    return;
                }
            }

            if (role === "Employee")
            {
                if (roles.length < 2) {
                    setMessage(t("select_multiple_roles"));
                    setIserror(true);
                    return;
                }
            }
        }

        let roleModel = "none";
        let licenseNo = "none";

        if (account)
        {
            roleModel = role;
            if (role === "Driver")
            {
                licenseNo = license;
            }
        }
        
        let newUserID = userID;

        setShowLoading(true);

        if ((userID === "none") && account)
        {
            try {
                const user = await register(email, newPass, name, phone, role, instituteID, roles);
                newUserID = user;
            } catch (error: any) {
                setMessage(error.response?.data.message || t("something_went_wrong"));
                setIserror(true);
                setShowLoading(false);
            }
        }
  
        const upEmployee = (classID: string, role: string) => {
            return new Promise((resolve, reject) => {
              api.put('/employees/'+employeeID, { 'userID': newUserID, name, role, phone, email, licenseNo, classID, additional, classes: addClasses }).then(res => {
        
                if((userID !== "") && (userID !== "none"))
                    {
                        api.put('/users/'+userID, { name, phone, role, email, roles }).then(resu => {
                            return resolve(resu.data.name);
                        }).catch(err => reject(err));   
                    }
                    else
                    {
                        return resolve(res.data.userInfo.name);
                    }
                
              }).catch(err => reject(err));
    
            });
        }
      
        if (!account || (account && newUserID !== "none"))
        {
            upEmployee(classi, roleModel)
            .then(data => {
        
                setPass(data+t("has_been_updated"));
                setIspass(true);
                
            })
            .catch((error) => {
                setMessage(error.response?.data.message || t("something_went_wrong"));
                setIserror(true);
            })
            .finally(() => setShowLoading(false));
        }
    }
    
    useEffect(() => {

        const fetchData = async () => {
            setShowLoading(true);
            try {
                const result = await api.get('/employees/' + employeeID);
                setInstituteID(result.data.instituteID);
                setUserID(result.data.userInfo.userID);

                const res = await api.get(`/sessions/getAllInstitute/${result.data.instituteID}`);
                setSessions(res.data);

                setTransport(authInfo.institute.transport);
                setPayment(authInfo.institute.payment);
                setAdmn(authInfo.institute.admission);
                setCrm(authInfo.institute.whatsapp);
                setInvent(authInfo.institute.inventory);
                setAcad(authInfo.institute.academics);
                
                if (result.data.userInfo.userID !== "none")
                {
                    setAccount(true);
                    setRole(result.data.userInfo.role);

                    if (result.data.userInfo.role === "Driver")
                    {
                        setLicense(result.data.licenseNo);
                    }
                    if (result.data.userInfo.role === "Employee")
                    {
                        const userr = await api.get(`/users/${result.data.userInfo.userID}`);
                        setRoles(userr.data.roles);
                        if (userr.data.roles.includes("Teacher"))
                        {
                            if(result.data.classID !== "none")
                            {
                                const classx = await api.get(`/classes/${result.data.classID}`);
                                setSession(classx.data.sessionID);
                                
                                const classy = await api.get(`/classes/allInstitute/${result.data.instituteID}/${classx.data.sessionID}`);
                                setClasses(classy.data);
                                setClassi(result.data.classID);

                                if (result.data.classes.length > 1)
                                {
                                    setAdditional(true);
                                    setAddSession(classx.data.sessionID);

                                    let theClasses: Array < ClassInterface > = [];
                                    for (let i = 0; i < classy.data.length ; i++) {
                                        if (result.data.classes.indexOf(classy.data[i]._id) > -1) {
                                            theClasses.push({'_id': classy.data[i]._id, 'sessionID': classy.data[i].sessionID, 'name': classy.data[i].name, 'year': classy.data[i].year, 'isActive': classy.data[i].isActive, 'isChecked' : true});    
                                        }
                                        else 
                                        {
                                            theClasses.push({'_id': classy.data[i]._id, 'sessionID': classy.data[i].sessionID, 'name': classy.data[i].name, 'year': classy.data[i].year, 'isActive': classy.data[i].isActive, 'isChecked' : false}); 
                                        }       
                                    }

                                    const classes = await api.get(`/classes/getByTeacher/${result.data.userInfo.userID}`);

                                    for (let i = 0; i < classes.data.length ; i++) {
                                        if (!theClasses.some(e => e.sessionID === classes.data[i].sessionID)) {
                                            const classyy = await api.get(`/classes/allInstitute/${authInfo.user.instituteID}/${classes.data[i].sessionID}`);
                                            for (let i = 0; i < classyy.data.length; i++) {
                                                if (result.data.classes.indexOf(classyy.data[i]._id) > -1) {
                                                    theClasses.push({'_id': classyy.data[i]._id, 'sessionID': classyy.data[i].sessionID, 'name': classyy.data[i].name, 'year': classyy.data[i].year, 'isActive': classyy.data[i].isActive, 'isChecked' : true});    
                                                }
                                                else 
                                                {
                                                    theClasses.push({'_id': classyy.data[i]._id, 'sessionID': classyy.data[i].sessionID, 'name': classyy.data[i].name, 'year': classyy.data[i].year, 'isActive': classyy.data[i].isActive, 'isChecked' : false}); 
                                                }       
                                            }
                                        }

                                    }
                                    setAddClasses(theClasses);
                                }
        
                            }  
                        }
                    }
                    if (result.data.userInfo.role === "Teacher")
                    {
                        if(result.data.classID !== "none")
                        {
                                const classx = await api.get(`/classes/${result.data.classID}`);
                                setSession(classx.data.sessionID);
                                
                                const classy = await api.get(`/classes/allInstitute/${result.data.instituteID}/${classx.data.sessionID}`);
                                setClasses(classy.data);
                                setClassi(result.data.classID);

                                if (result.data.classes.length > 1)
                                {
                                    setAdditional(true);
                                    setAddSession(classx.data.sessionID);

                                    let theClasses: Array < ClassInterface > = [];
                                    for (let i = 0; i < classy.data.length ; i++) {
                                        if (result.data.classes.indexOf(classy.data[i]._id) > -1) {
                                            theClasses.push({'_id': classy.data[i]._id, 'sessionID': classy.data[i].sessionID, 'name': classy.data[i].name, 'year': classy.data[i].year, 'isActive': classy.data[i].isActive, 'isChecked' : true});    
                                        }
                                        else 
                                        {
                                            theClasses.push({'_id': classy.data[i]._id, 'sessionID': classy.data[i].sessionID, 'name': classy.data[i].name, 'year': classy.data[i].year, 'isActive': classy.data[i].isActive, 'isChecked' : false}); 
                                        }       
                                    }

                                    const classes = await api.get(`/classes/getByTeacher/${result.data.userInfo.userID}`);

                                    for (let i = 0; i < classes.data.length ; i++) {
                                        if (!theClasses.some(e => e.sessionID === classes.data[i].sessionID)) {
                                            const classyy = await api.get(`/classes/allInstitute/${authInfo.user.instituteID}/${classes.data[i].sessionID}`);
                                            for (let i = 0; i < classyy.data.length; i++) {
                                                if (result.data.classes.indexOf(classyy.data[i]._id) > -1) {
                                                    theClasses.push({'_id': classyy.data[i]._id, 'sessionID': classyy.data[i].sessionID, 'name': classyy.data[i].name, 'year': classyy.data[i].year, 'isActive': classyy.data[i].isActive, 'isChecked' : true});    
                                                }
                                                else 
                                                {
                                                    theClasses.push({'_id': classyy.data[i]._id, 'sessionID': classyy.data[i].sessionID, 'name': classyy.data[i].name, 'year': classyy.data[i].year, 'isActive': classyy.data[i].isActive, 'isChecked' : false}); 
                                                }       
                                            }
                                        }

                                    }
                                    setAddClasses(theClasses);
                                }
                        }  
                    }  
                }

                setName(result.data.userInfo.name);
                setPhone(result.data.userInfo.phone);
                setEmail(result.data.userInfo.email);

                if (result.data.isActive)
                    setActive("enable");
                else
                    setActive("disable");

            } catch (error: any) {
                setMessage(error.response?.data.message || t("something_went_wrong"));
                setIserror(true);
            } finally {
                setShowLoading(false);
            }
        };

        fetchData();

    }, [authInfo, employeeID]);
    
    return (
        <IonPage>

            <IonHeader>
                <IonToolbar className="white-shade">
                    <IonButtons slot="start">
                        <IonMenuButton color="secondary"/>
                        <IonBackButton color="secondary" defaultHref={`/app/employee/${employeeID}`} />
                    </IonButtons>
                    <IonTitle size={isPlatform('mobile') ? "small" : undefined} color="secondary">{t("parent_info")}</IonTitle>
                    {!isPlatform('mobile') && (
                    <IonButtons slot="end">
                        <ThemeToggle />
                        <LanguageToggle />
                   </IonButtons>
                    )}
                </IonToolbar>
            </IonHeader>

            <IonContent fullscreen={true} className="grey-shade">

                <IonHeader collapse="condense">
                    <IonToolbar>
                        <IonTitle size="large" color="secondary">{t("parent_info")}</IonTitle>
                    </IonToolbar>
                </IonHeader>

                <IonLoading isOpen={showLoading}
                    cssClass="first-loading"
                    spinner="circular"
                    message={t("please_wait")}
                />

                <IonAlert isOpen={iserror}
                    cssClass="first-alert"
                    onDidDismiss={
                        () => setIserror(false)
                    }
                    header={t("error")}
                    message={message}
                    buttons={
                        [`${t("close")}`]
                    }
                />
                
                 <IonAlert isOpen={ispass}
                    cssClass="first-alert"
                    onDidDismiss={
                        () => setIspass(false)
                    }
                    header={t("success")}
                    message={pass}
                    buttons={
                        [`${t("close")}`]
                    }
                />

                <IonGrid className="ion-no-padding mb-60">
                    <IonRow className="mt-30">
                        <IonCol offsetXl="4" offsetMd="2" offset="0" sizeXl="4" sizeMd="8" size="12">
                            <IonTitle className='border-bottom-sec ion-padding-bottom'>
                            {t("employee_account")}
                            </IonTitle>
                        </IonCol>
                    </IonRow>
                    <IonRow className="mt-10">
                        <IonCol offsetXl="4" offsetMd="2" offset="0" sizeXl="4" sizeMd="8" size="12">
                            <IonCard className='border-radius-10 active-group'>
                                <IonCardContent>
                                    <IonGrid>
                                        <IonRow>
                                            <IonCol size="8">
                                                
                                                {t("current_status")}
                                            
                                            </IonCol>
                                            <IonCol size="4" className='ion-text-right ion-padding-horizontal'>
                                            {(active === 'enable') ? ( <IonBadge color="success" className='ion-padding-horizontal'>{t("active")}</IonBadge>) : 
                                            ( <IonBadge color="danger" className='ion-padding-horizontal'>{t("inactive")}</IonBadge>)}
                                            </IonCol>
                                        </IonRow>
                                        <IonRow className="ion-padding-top ion-padding-horizontal">
                                            <IonCol>
                                                <IonSegment value={active} onIonChange={e => handleSection( e.detail.value!.toString() )} mode="ios" className="isactive-segment">
                                                    <IonSegmentButton value="enable">
                                                        <IonLabel>{t("make_active")}</IonLabel>
                                                    </IonSegmentButton>
                                                    <IonSegmentButton value="disable">
                                                        <IonLabel>{t("make_inactive")}</IonLabel>
                                                    </IonSegmentButton>
                                                </IonSegment>
                                            </IonCol>
                                        </IonRow>
                                    </IonGrid>
                                </IonCardContent>
                            </IonCard>
                        </IonCol>
                    </IonRow>
                    {(userID !== "none") && (
                    <IonRow>
                        <IonCol offsetXl="4" offsetMd="2" offset="0" sizeXl="4" sizeMd="8" size="12" className="ion-padding-horizontal ion-text-center">
                            <IonButton color='dark' expand="block" onClick={handleReset}
                            >{t("reset_password")}</IonButton>

                        </IonCol>
                    </IonRow>
                    )}
                    <IonRow className='mt-30'>
                        <IonCol offsetXl="4" offsetMd="2" offset="0" sizeXl="4" sizeMd="8" size="12" >
                            <IonCard>
                                <IonCardContent className="ion-no-padding">
                                    <IonItem>
                                        
                                        <IonLabel position="stacked" className="input-label" color="secondary">
                                            {t("employee_name")}*
                                        </IonLabel>
                                            <IonInput type="text"
                                                className="input-field"
                                                placeholder={t("employee_name")}
                                                value={name}
                                                onIonInput={
                                                    (e) => setName(e.detail.value!)
                                            }></IonInput>
                                    </IonItem>
                                </IonCardContent>
                            </IonCard>
                        </IonCol>
                    </IonRow>
                    <IonRow>
                        <IonCol offsetXl="4" offsetMd="2" offset="0" sizeXl="4" sizeMd="8" size="12">
                            <IonCard>
                                <IonCardContent className="ion-no-padding">
                                    <IonItem>
                                        
                                        <IonLabel position="stacked" className="input-label" color="secondary">
                                            {t("employee_phone")}*
                                        </IonLabel>
                                            <IonInput type="text"
                                                className="input-field"
                                                placeholder={t("employee_phone")}
                                                value={phone}
                                                onIonInput={
                                                    (e) => setPhone(e.detail.value!)
                                            }></IonInput>
                                    </IonItem>
                                </IonCardContent>
                            </IonCard>
                        </IonCol>
                    </IonRow>
                    <IonRow>
                        <IonCol offsetXl="4" offsetMd="2" offset="0" sizeXl="4" sizeMd="8" size="12">
                            <IonCard>
                                <IonCardContent className="ion-no-padding">
                                    <IonItem>
                                        
                                        <IonLabel position="stacked" className="input-label" color="secondary">
                                            {t("employee_email")}*
                                        </IonLabel>
                                            <IonInput type="text"
                                                className="input-field"
                                                placeholder={t("employee_email")}
                                                value={email}
                                                onIonInput={
                                                    (e) => setEmail(e.detail.value!)
                                            }></IonInput>
                                    </IonItem>
                                </IonCardContent>
                            </IonCard>
                        </IonCol>
                    </IonRow>
                    {(userID === "none") && (
                    <IonRow className="ion-margin-top">
                        <IonCol offsetXl="4" offsetMd="2" offset="0" sizeXl="4" sizeMd="8" size="12">
                            <IonCard>
                                <IonCardContent className="ion-no-padding">
                                    <IonItem detail={false}
                                        lines='full'
                                        button={false}
                                        color="primary"
                                        >
                                        <IonLabel className="input-label">{t("create_account")}</IonLabel>
                                        <IonToggle color="light" checked={account} onIonChange={e => setAccount(e.detail.checked)} />
                                    </IonItem>
                                </IonCardContent>
                            </IonCard>
                        </IonCol>
                    </IonRow>
                    )}
                    {account && (
                    <>
                        {((authInfo.user.role === "Admin") || (authInfo.user.role === "Super")) && 
                        (<>
                            <IonRow className="ion-margin-top">
                                <IonCol offsetXl="4" offsetMd="2" offset="0" sizeXl="4" sizeMd="8" size="12">
                                    <IonCard>
                                        <IonCardContent className="ion-no-padding">
                                            <IonItem detail={false}
                                                    lines='full'
                                                    button={false}>
                                                    <IonLabel position="stacked" className="input-label" color="secondary">
                                                        {t("employee_role")}*
                                                    </IonLabel>
                                                    <IonSelect value={role}
                                                        className="input-field"
                                                        cancelText={t("cancel")}
                                                        okText={t("ok")}
                                                        placeholder={t("employee_role")}
                                                        onIonChange={
                                                            (e) => setRole(e.detail.value)
                                                        }>

                                                        <IonSelectOption value="Teacher">{t("teacher")}</IonSelectOption> 
                                                        {acad && (
                                                        <IonSelectOption value="Academic">{t("academic_incharge")}</IonSelectOption> 
                                                        )}
                                                        {transport && (<>
                                                        <IonSelectOption value="Driver">{t("driver")}</IonSelectOption> 
                                                        <IonSelectOption value="Transporter">{t("transport_incharge")}</IonSelectOption> 
                                                        </>)}
                                                        {payment && (
                                                        <IonSelectOption value="Accountant">{t("accountant")}</IonSelectOption>
                                                        )}
                                                        {(admn || crm) && (
                                                        <IonSelectOption value="AdmissionHead">{t("admission_head")}</IonSelectOption> 
                                                        )}
                                                        {invent && (
                                                        <IonSelectOption value="Inventory">{t("inventory_manager")}</IonSelectOption> 
                                                        )}
                                                        <IonSelectOption value="Employee">{t("multiple_roles")}</IonSelectOption>
                                
                                                    </IonSelect>
                                                </IonItem>
                                        </IonCardContent>
                                    </IonCard>
                                </IonCol>
                            </IonRow>
                            {(role === "Employee") && (                      
                            <IonRow>
                                <IonCol offsetXl="4" offsetMd="2" offset="0" sizeXl="4" sizeMd="8" size="12">
                                    <IonCard>
                                        <IonCardContent className="ion-no-padding">
                                            <IonList className="ion-no-padding">
                                               
                                                    <IonItem 
                                                        detail={false}
                                                        lines='full'
                                                        button={false}>
                                                        <IonCheckbox slot="start" value="Teacher" checked={roles.includes("Teacher")} 
                                                            onClick={e => handleChecked("Teacher")}/>
                                                        <IonLabel>{t("teacher")}</IonLabel>
                                                    </IonItem>
                                                    {acad && (
                                                    <IonItem 
                                                        detail={false}
                                                        lines='full'
                                                        button={false}>
                                                        <IonCheckbox slot="start" value="Academic" checked={roles.includes("Academic")} 
                                                            onClick={e => handleChecked("Academic")}/>
                                                        <IonLabel>{t("academic_incharge")}</IonLabel>
                                                    </IonItem>
                                                    )}
                                                    {transport && (
                                                    <IonItem 
                                                        detail={false}
                                                        lines='full'
                                                        button={false}>
                                                        <IonCheckbox slot="start" value="Transporter" checked={roles.includes("Transporter")} 
                                                            onClick={e => handleChecked("Transporter")}/>
                                                        <IonLabel>{t("transport_incharge")}</IonLabel>
                                                    </IonItem>
                                                    )}
                                                    {payment && (
                                                    <IonItem 
                                                        detail={false}
                                                        lines='full'
                                                        button={false}>
                                                        <IonCheckbox slot="start" value="Accountant" checked={roles.includes("Accountant")} 
                                                            onClick={e => handleChecked("Accountant")}/>
                                                        <IonLabel>{t("accountant")}</IonLabel>
                                                    </IonItem>
                                                    )}
                                                    {(admn || crm) && (
                                                    <IonItem 
                                                        detail={false}
                                                        lines='full'
                                                        button={false}>
                                                        <IonCheckbox slot="start" value="AdmissionHead" checked={roles.includes("AdmissionHead")} 
                                                            onClick={e => handleChecked("AdmissionHead")}/>
                                                        <IonLabel>{t("admission_head")}</IonLabel>
                                                    </IonItem>
                                                    )}
                                                    {invent && (
                                                    <IonItem 
                                                        detail={false}
                                                        lines='full'
                                                        button={false}>
                                                        <IonCheckbox slot="start" value="Inventory" checked={roles.includes("Inventory")} 
                                                            onClick={e => handleChecked("Inventory")}/>
                                                        <IonLabel>{t("inventory_manager")}</IonLabel>
                                                    </IonItem>
                                                    )}
                                                   
                                            </IonList>
                                        </IonCardContent>
                                    </IonCard>
                                </IonCol>
                            </IonRow>
                            )} 
                        </>)}
                        {(userID === "none") && (
                            <IonRow>
                                <IonCol offsetXl="4" offsetMd="2" offset="0" sizeXl="4" sizeMd="8" size="12">
                                    <IonCard>
                                        <IonCardContent className="ion-no-padding">
                                            <IonItem>
                                                
                                                <IonLabel position="stacked" className="input-label" color="secondary">
                                                    {t("set_pass")}*
                                                </IonLabel>
                                                    <IonInput type="text"
                                                        className="input-field"
                                                        placeholder={t("set_pass")}
                                                        value={newPass}
                                                        onIonInput={
                                                            (e) => setNewPass(e.detail.value !)
                                                    }></IonInput>
                                            </IonItem>
                                        </IonCardContent>
                                    </IonCard>
                                </IonCol>
                            </IonRow>
                        )}
                       
                           
                        {(role === "Driver") && (
                        <>
                            <IonRow className="mt-30">
                                <IonCol offsetXl="4" offsetMd="2" offset="0" sizeXl="4" sizeMd="8" size="12">
                                        <IonTitle className='border-bottom-sec ion-padding-bottom'>
                                            {t("driver_details")}
                                        </IonTitle>
                                </IonCol>
                            </IonRow>
                            <IonRow className="ion-margin-top">
                                <IonCol offsetXl="4" offsetMd="2" offset="0" sizeXl="4" sizeMd="8" size="12">
                                    <IonCard>
                                        <IonCardContent className="ion-no-padding">
                                            <IonItem>
                                                
                                                <IonLabel position="stacked" className="input-label" color="secondary">
                                                    {t("driving_license")}
                                                </IonLabel>
                                                    <IonInput type="text"
                                                        className="input-field"
                                                        placeholder={t("driving_license")}
                                                        value={license}
                                                        onIonInput={
                                                            (e) => setLicense(e.detail.value!)
                                                    }></IonInput>
                                            </IonItem>
                                        </IonCardContent>
                                    </IonCard>
                                </IonCol>
                            </IonRow>
                        </>
                        )}
                        {((role === "Teacher") || ((role === "Employee") && (roles.includes("Teacher")))) && (
                        <>
                            <IonRow className="mt-30">
                                <IonCol offsetXl="4" offsetMd="2" offset="0" sizeXl="4" sizeMd="8" size="12">
                                    <IonTitle className='border-bottom-sec ion-padding-bottom'>
                                        {(authInfo.institute.type === "school") ? t("teacher_class") : t("teacher_batch")}
                                    </IonTitle>
                                </IonCol>
                            </IonRow>
                            <IonRow className="ion-margin-top">
                                <IonCol offsetXl="4" offsetMd="2" offset="0" sizeXl="4" sizeMd="8" size="12">
                                    <IonRow>
                                        <IonCol size="6">
                                            <IonCard>
                                                <IonCardContent className="ion-no-padding">
                                                    <IonItem>
                                                        
                                                        <IonLabel position="stacked" className="input-label" color="secondary">
                                                            {t("class_session")}*
                                                        </IonLabel>
                                                            <IonSelect value={session}
                                                                    className="input-field"
                                                                    placeholder={t("class_session")}
                                                                    cancelText={t("cancel")}
                                                                    okText={t("ok")}
                                                                    onIonChange={
                                                                        (e) => handleSession(e.detail.value)
                                                                }>

                                                                    {sessions.length > 0 && sessions.map((session) => (
                                                                                (session.isActive) && 
                                                                                (<IonSelectOption key={session._id} value={session._id}>{session.name}</IonSelectOption>)
                                                                            ))}

                                                                    
                                                            </IonSelect>
                                                    </IonItem>
                                                </IonCardContent>
                                            </IonCard>
                                        </IonCol>
                                        <IonCol size="6">
                                            <IonCard>
                                                <IonCardContent className="ion-no-padding">
                                                    <IonItem>
                                                        
                                                        <IonLabel position="stacked" className="input-label" color="secondary">
                                                        {(authInfo.institute.type === "school") ?  t("select_main_class") : t("select_main_batch")}*
                                                        </IonLabel>
                                                            <IonSelect value={classi}
                                                                    className="input-field"
                                                                    placeholder={(authInfo.institute.type === "school") ? t("select_main_class") : t("select_main_batch")}
                                                                    cancelText={t("cancel")}
                                                                    okText={t("ok")}
                                                                    onIonChange={
                                                                        (e) => handleClassi(e.detail.value)
                                                                }>
                                                                <IonSelectOption value="none">{t("none")}</IonSelectOption>
                                                                    {session && classes.map((classy) => 
                                                                    (classy.isActive) &&
                                                                    (<IonSelectOption key={classy._id} value={`${classy._id}`}>{classy.name}</IonSelectOption>)
                                                                    )}
                                                            </IonSelect>
                                                    </IonItem>
                                                </IonCardContent>
                                            </IonCard>
                                        </IonCol>
                                    </IonRow>
                                </IonCol>
                                
                            </IonRow>

                            {classi && (classi !== "none") && 
                            (   
                            <IonRow>
                                <IonCol offsetXl="4" offsetMd="2" offset="0" sizeXl="4" sizeMd="8" size="12">
                                    <IonCard>
                                        <IonCardContent className="ion-no-padding">
                                            <IonItem detail={false}
                                                lines='full'
                                                button={false}
                                                color="primary"
                                                >
                                                <IonLabel className="input-label">{(authInfo.institute.type === "school") ? t("assign_additional_classes") : t("assign_additional_batches")}</IonLabel>
                                                <IonToggle color="light" checked={additional} onIonChange={e => setAdditional(e.detail.checked)} />
                                            </IonItem>
                                        </IonCardContent>
                                    </IonCard>
                                </IonCol>
                            </IonRow>
                            )}

                            {additional && (
                             <>
                                <IonRow>
                                    <IonCol offsetXl="4" offsetMd="2" offset="0" sizeXl="4" sizeMd="8" size="12">
                                        <IonCard>
                                            <IonCardContent className="ion-no-padding">
                                                <IonItem>
                                                    
                                                    <IonLabel position="stacked" className="input-label" color="secondary">
                                                        {t("class_session")}*
                                                    </IonLabel>
                                                        <IonSelect value={addSession}
                                                                className="input-field"
                                                                placeholder={t("class_session")}
                                                                cancelText={t("cancel")}
                                                                okText={t("ok")}
                                                                onIonChange={
                                                                    (e) => handleAddSession(e.detail.value)
                                                            }>
    
                                                                {sessions.length > 0 && sessions.map((session) => (
                                                                    (session.isActive) && 
                                                                    (<IonSelectOption key={session._id} value={session._id}>{session.name}</IonSelectOption>)
                                                                ))}
    
                                                                
                                                        </IonSelect>
                                                </IonItem>
                                            </IonCardContent>
                                        </IonCard>
                                    </IonCol>
                                </IonRow>
                                {(addSession !== "") && (
                                <IonRow>
                                    <IonCol offsetXl="4" offsetMd="2" offset="0" sizeXl="4" sizeMd="8" size="12">
                                        <IonCard>
                                            <IonCardContent className="ion-no-padding">
                                                <IonList>

                                                {(addSession !== "") && addClasses.map((classii, i) => (
                                            
                                                    (classii.isActive && classii.sessionID === addSession) &&
                                                    (<IonItem key={classii._id}
                                                        detail={false}
                                                        lines='full'
                                                        button={false}>
                                                        <IonCheckbox slot="start" value={classii._id} checked={classii.isChecked} disabled={classii._id === classi}
                                                            onIonChange={e => handleClassChecked(e.detail.checked, i)}/>
                                                        <IonLabel>{classii.name}</IonLabel>
                                                    </IonItem>)
                                                ))}
                                            </IonList>
                                            </IonCardContent>
                                        </IonCard>
                                    </IonCol>
                                </IonRow>
                                )}
                            </>
                            )}
                        </>
                        )}
                        
                    </>
                    )}
                </IonGrid>
            </IonContent>
            <IonFooter className='ion-padding footer-shade'>
                <IonGrid>
                    <IonRow>
                        <IonCol className="ion-text-center">
                            <IonButton onClick={handleSubmit}
                                className="first-button"
                                fill="clear">{t("update_employee")}</IonButton>

                        </IonCol>
                    </IonRow>
                </IonGrid>
            </IonFooter>
        </IonPage>
    );
};

export default AccountInfo;
